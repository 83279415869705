import React, { useState } from 'react'
import { loginWithEmail, validateActiveUser } from '../../../services/auth.service'
import { DEFAULT_AUTH_ERROR_MESSAGE, firebaseAuthErrors } from '../../../consts/modules/firebase.consts'
import InputTextForm from '../../../components/molecules/InputTextForm.molecule'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'
import InputPasswordForm from '../../../components/molecules/InputPasswordForm.molecule'
import { isNotEmpty, validateFieldValue, checkAllFields, fieldsDoesNotHaveErrors, isAnEmail } from '../../../utils/validations.utils'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import { InputCheckboxForm } from '../../../components/molecules/InputCheckboxForm.molecule'
import { LoginFieldNames } from '../../../consts/modules/loginForm.consts'
import { useMessage } from '../../../hooks/useMessage.hook'
import { Message } from '../../../components/atoms/Message.atom'
import { Link } from 'react-router-dom'
import { useSession } from '../../../hooks/auth/useSession.hook'

const { EMAIL: EMAIL_FIELD, PASSWORD: PASSWORD_FIELD } = LoginFieldNames

export function LoginForm () {
  const [rememberme, setRememberme] = useState(true)
  const { ref, setErrorMessage } = useMessage()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const initialFieldsState = {
    [EMAIL_FIELD]: { errors: [], value: null, typeValidations: [isNotEmpty, isAnEmail], validationOptions: {} },
    [PASSWORD_FIELD]: { errors: [], value: null, typeValidations: [isNotEmpty], validationOptions: {} }
  }
  const [validations, setValidations] = useState(initialFieldsState)
  const { loading: loadingAuth } = useSession()

  const handleSubmit = (e) => {
    e.preventDefault()
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) return
    setLoading(true)
    loginWithEmail(email?.toString(), password?.toString(), rememberme)
      .then(async (uid) => {
        await validateActiveUser(uid)
        console.log('Bienvenido 👀')
      })
      .catch(({ code }) => {
        const message = firebaseAuthErrors[code] || DEFAULT_AUTH_ERROR_MESSAGE
        setErrorMessage({ summary: message })
      })
      .finally(() => setLoading(false))
  }

  const handleOnChange = ({ target }) => {
    const { value, name } = target
    if (name === EMAIL_FIELD) setEmail(value)
    if (name === PASSWORD_FIELD) setPassword(value)
    setValidations(validateFieldValue(validations, name, value))
  }

  return (
    <>
      <Message ref={ref} />
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <FieldContainer className="w-full">
            <InputTextForm
              type="email"
              label="Correo electrónico"
              name="email"
              placeholder="correo@sisu.mx"
              value={email}
              onChange={handleOnChange}
              error={validations.email.errors}
            />
          </FieldContainer>
          <FieldContainer className="w-full">
            <InputPasswordForm
              label="Contraseña"
              name="password"
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={handleOnChange}
              error={validations.password.errors}
            />
          </FieldContainer>
        </FormContainer>

        <div className="flex align-items-center justify-content-between mb-4">
          <InputCheckboxForm
            label="Recuérdame"
            name="rememberme"
            checked={rememberme}
            onChange={(e) => setRememberme(e.checked)}
          />

          <Link to="/recuperar-contrasena" className="font-medium no-underline ml-2 text-blue-aika text-right cursor-pointer">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>

        <PrimaryButton rounded={false} label="Iniciar sesión" btnType="block" icon={`pi pi-${!loading && !loadingAuth ? 'check' : 'spinner pi-spin'}`}/>
      </form>
    </>
  )
}
