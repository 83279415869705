import React from 'react'
import { TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../../consts/modules/graphics.consts'
import ColorFigure from './ColorFigure'

const BasicTooltipFact = ({
  className = '',
  data,
  color,
  name,
  value,
  tooltipUnit,
  colorType = TYPE_OF_TOOLTIP_COLOR_VARIANTS.square,
  valueFormatter = (value) => value ? value.toFixed(0) : '',
  nameFormatter = (name) => name,
  colorFigure = true
}) => {
  return (
    <div className={className}>
      {colorFigure && <ColorFigure color={color} colorType={colorType}/>}
      <span className={`tooltip-name ${colorFigure ? 'ml-3' : ''}`}>{nameFormatter(name, data)}</span>
      <span className='tooltip-value'>{valueFormatter(value, data)}{tooltipUnit}</span>
    </div>
  )
}

export default BasicTooltipFact
