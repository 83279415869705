import React from 'react'
import PropTypes from 'prop-types'

const GridItem = ({ children, col = 12, lg, md, sm, xl, px = 2, isCard, className = '' }) => {
  const isColParam = (size, numSize) => {
    return numSize ? `${size}:col-${numSize}` : ''
  }

  return (
    <div className={`col col-${col}${isColParam(' lg', lg)}${isColParam(' md', md)}${isColParam(' sm', sm)}${isColParam(' xl', xl)} px-${px}${isCard ? ' card-grid-item' : ''} ${className}`}>
      {children}
    </div>
  )
}

GridItem.propTypes = {
  col: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xl: PropTypes.number,
  isCard: PropTypes.bool
}

export default GridItem
