import React from 'react'
import { Card as PrimeCard } from 'primereact/card'

/**
 * @param {import("primereact/card").CardProps} props
 * @returns
 */
const Card = (props) => {
  return (
    <PrimeCard {...props}/>
  )
}

export default Card
