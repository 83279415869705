export const sortParentsWithStickyChildren = (props) => {
  let { data, field, order } = props
  field = field.split('.')[1] || field
  const sorted = [...data].sort((a, b) => {
    const valueA = a.data[field]
    const valueB = b.data[field]
    if (order === 1) {
      return valueA - valueB
    } else {
      return valueB - valueA
    }
  })
  const sortedParentsWithStickyChildren = sorted
    .filter(node => !node.parentKey)
    .map(parent => {
      const children = sorted.filter(node => node.parentKey === parent.key)
      return [parent, ...children]
    }).flat()
  return sortedParentsWithStickyChildren
}
