import React, { useEffect, useRef, useState } from 'react'
import SectionHeading from '../../../../components/layouts/containers/SectionHeading.container'
import TabMenuContentContainer from '../../../../components/layouts/containers/TabMenuContentContainer.container'
import DropdownProjectFilterInplace from '../../../../components/compounds/DropdownProjectFilterInplace.compound'
import DropdownModuleFilter from '../../../../components/compounds/DropdownModuleFilter.compound'
import {
  getTeamMemberActiveProjects,
  getFavoriteActiveProjects,
  getProjectData
} from '../../../../services/project.service'
import { getProjectCategories } from '../../../../services/category.service'
import { getProjectModules } from '../../../../services/module.service'
import useSessionContext from '../../../../hooks/contextConsumers/useSessionContext.hook'
import InplaceTimeControlled from '../../../../components/molecules/InplaceTimeControlled.molecule'
import Button from '../../../../components/atoms/Button.atom'
import Tooltip from '../../../../components/atoms/Tooltip.atom'
import {
  createDayTasks
} from '../../../../services/task.service'
import {
  validateFieldValue,
  checkAllFields,
  fieldsDoesNotHaveErrors,
  removeFieldTypeValidations,
  resetField
} from '../../../../utils/validations.utils'
import {
  CREATE_TASK_INITIAL_FIELD_STATE
} from '../../../../consts/modules/timeRegister.consts'
import TimeDifferenceModal from './components/TimeDifferenceModal'
import CommentModal from './components/CommentModal'
import { DateTime } from 'luxon'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'
import useToast from '../../../../hooks/useToast.hook'
import InputText from '../../../../components/atoms/InputText.atom'
import DropdownCategoryFilterInplace from '../../../../components/compounds/DropdownCategoryFilterInplace.compound'
import { KEYBOARD_ACTIONS } from '../../../../consts/keyBoard.consts'

const TaskCreation = ({
  refreshProjects,
  dropdownProjectFilterRef,
  isEditingProjectDropdown,
  setIsEditingProjectDropdown
}) => {
  const {
    setRefresh,
    isDayEditable,
    isDayFinished,
    lastWorkDay,
    selectedDate,
    setCanSaveChanges
  } = useTimeRegisterContext()

  const { setErrorMessage } = useToast()
  const [activeProjects, setActiveProjects] = useState([])

  const [selectedProject, setSelectedProject] = useState(1)
  const [clientOfProject, setClientOfProject] = useState(null)
  const [categories, setCategories] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [modules, setModules] = useState([])
  const [loadingModules, setLoadingModules] = useState(false)
  const [selectedModule, setSelectedModule] = useState(null)
  const [comment, setComment] = useState(undefined)
  const [timeDifferenceComment, setTimeDifferenceComment] = useState('')
  const [openTimeDifferenceModal, setOpenTimeDifferenceModal] = useState(false)

  const [taskName, setTaskName] = useState('')
  const [sisuTime, setSisuTime] = useState('')
  const [billedTime, setBilledTime] = useState('')
  const [hasChangedBilledTime, setHasChangedBilledTime] = useState(false)

  const [isEditingCategoryDropdown, setIsEditingCategoryDropdown] = useState(false)
  const [isEditingModuleDropdown, setIsEditingModuleDropdown] = useState(false)
  const [isEditingSisuTime, setIsEditingSisuTime] = useState(false)
  const [isEditingBilledTime, setIsEditingBilledTime] = useState(false)

  const [validations, setValidations] = useState({ ...removeFieldTypeValidations(CREATE_TASK_INITIAL_FIELD_STATE, 'moduleId') })
  const [submitting, setSubmitting] = useState(false)

  const dropdownCategoryFilterRef = useRef(null)
  const dropdownModuleFilterRef = useRef(null)
  const buttonSubmitRef = useRef(null)
  const inputTaskNameRef = useRef(null)

  const { user } = useSessionContext()

  useEffect(() => {
    if (!isDayEditable && isDayFinished) {
      setSelectedProject(1)
      setSelectedCategory(null)
      setSelectedModule(null)
      setTaskName('')
      setSisuTime('')
      setBilledTime('')
      setComment('')
      setTimeDifferenceComment('')
      setValidations({ ...CREATE_TASK_INITIAL_FIELD_STATE })
    }
  }, [isDayEditable, isDayFinished])

  useEffect(() => {
    if (!sisuTime && typeof sisuTime !== 'number') return
    if (!hasChangedBilledTime) {
      setBilledTime(sisuTime)
      setValidations(validateFieldValue(validations, 'billedTime', sisuTime))
    } else if (hasChangedBilledTime && billedTime === sisuTime) {
      setHasChangedBilledTime(false)
      setBilledTime(sisuTime)
      setValidations(validateFieldValue(validations, 'billedTime', sisuTime))
    }
  }, [sisuTime])
  useEffect(() => {
    gettingActiveProjectsData()
  }, [refreshProjects])

  useEffect(() => {
    if (!selectedProject.value) return
    setSelectedCategory(null)
    resetField(validations, 'categoryId')
    setSelectedModule(null)
    resetField(validations, 'moduleId')
    getClientData()
    gettingCategoriesData()
    gettingModulesData()
  }, [selectedProject])

  const gettingActiveProjectsData = async () => {
    const responseFavoriteProjects = await getFavoriteActiveProjects(user.teamMemberID)
    if (responseFavoriteProjects.result && responseFavoriteProjects.result.length > 1) {
      setActiveProjects(responseFavoriteProjects.result)
    } else {
      const responseActiveProjects = await getTeamMemberActiveProjects(user.teamMemberID)
      setActiveProjects(responseActiveProjects.result)
    }
  }

  const getClientData = async () => {
    const projectData = await getProjectData(selectedProject.value)
    if (projectData.success) {
      setClientOfProject(projectData.result.clientID)
      if (projectData.result.clientID === 1) {
        setHasChangedBilledTime(false)
        setBilledTime(sisuTime)
      }
    }
  }

  const gettingCategoriesData = async () => {
    setLoadingCategories(true)
    try {
      const responseCategories = await getProjectCategories(selectedProject.value)
      setCategories(responseCategories.result)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCategories(false)
    }
  }

  const gettingModulesData = async () => {
    setLoadingModules(true)
    try {
      const responseModules = await getProjectModules(selectedProject.value)
      setModules(responseModules.result)
      if (!responseModules?.result?.length) {
        setValidations({
          ...validations,
          moduleId: {
            errors: [],
            value: null,
            typeValidations: [],
            validationOptions: {}
          }
        })
      } else {
        setValidations({ ...CREATE_TASK_INITIAL_FIELD_STATE })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingModules(false)
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) {
      setSubmitting(false)
      return
    }
    if (sisuTime !== billedTime) {
      setOpenTimeDifferenceModal(true)
      setSubmitting(false)
      return
    }
    try {
      await handleSaveTask()
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveTask = async () => {
    const categoryId = selectedCategory?.categoryID || 0
    const moduleId = selectedModule?.value || 0
    const teamMemberId = user.teamMemberID
    const specialCategoryId = selectedCategory?.specialCategoryID || null
    const projectId = selectedProject.value
    const taskDate = DateTime.fromJSDate(selectedDate).toSeconds()
    console.log('Fecha asignada de creacion (timestamp):', taskDate, selectedDate, selectedDate.toISOString())
    console.log('XDDD', comment)
    const response = await createDayTasks({
      taskDayData: {
        taskName,
        sisuTime,
        billedTime,
        date: taskDate,
        comment: comment === '' ? null : comment,
        timeDifferenceComment: timeDifferenceComment === '' ? null : timeDifferenceComment,
        categoryId,
        moduleId: moduleId || null,
        teamMemberId,
        specialCategoryId,
        projectId
      },
      date: taskDate
    })
    if (response.success) {
      setCanSaveChanges(true)
      setRefresh()
      resetFormState()
      setTimeout(() => {
        const syntheticEvent = new Event('click', { bubbles: true, cancelable: true })
        const taskNameComponent = document.querySelector('.p-inplace.p-inplace-closable > .p-inplace-display')

        if (taskNameComponent) {
          taskNameComponent.dispatchEvent(syntheticEvent)
        }
      }, 200)
    } else {
      setErrorMessage({
        message: 'No se pudo crear la tarea.'
      })
    }
  }

  const resetFormState = () => {
    setTaskName('')
    setSisuTime('')
    setBilledTime('')
    setComment(undefined)
    setTimeDifferenceComment('')
    setValidations(prev => {
      const newValidations = { ...prev }
      Object.keys(newValidations).forEach(key => {
        newValidations[key].errors = []
        newValidations[key].value = null
      })
      return newValidations
    })
    setSelectedProject(1)
    setSelectedCategory(null)
    setSelectedModule(null)
    setModules([])
    setCategories([])

    setIsEditingProjectDropdown(false)
    setIsEditingCategoryDropdown(false)
    setIsEditingModuleDropdown(false)
    setIsEditingSisuTime(false)
    setIsEditingBilledTime(false)
    setHasChangedBilledTime(false)

    setOpenTimeDifferenceModal(false)

    setSubmitting(false)
  }

  let BLOCK_TASK_FORM = false
  if (
    DateTime.fromJSDate(selectedDate) < DateTime.local().startOf('week') && !lastWorkDay?.isEditable
  ) {
    BLOCK_TASK_FORM = true
  }

  return (
    <React.Fragment key={isDayFinished || isDayEditable}>
      {
        Object.keys(validations).some((key) => validations[key].errors?.length) &&
        <Tooltip target=".pi-exclamation-triangle" />
      }
      <TabMenuContentContainer className={`task-creation-wrapper ${(BLOCK_TASK_FORM) ? 'pointer-events-none' : ''}`}>
        <SectionHeading className='p-0 w-full'>
          <DropdownProjectFilterInplace
            value={selectedProject?.value}
            onChange={(e) => {
              if (e.originalEvent.type === 'click') {
                setIsEditingProjectDropdown(false)
              }
              setSelectedProject(e)
              setValidations(validateFieldValue(validations, 'projectId', e.value))
            }}
            options={activeProjects}
            error={validations.projectId.errors?.length}
            filter
            isEditingProjectDropdown={isEditingProjectDropdown}
            setIsEditingProjectDropdown={setIsEditingProjectDropdown}
            dropdownRef={dropdownProjectFilterRef}
            dropdownInputProps={(inputProps) => ({
              autoFocus: true,
              className: 'w-full',
              onKeyDown: (e) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  checkAllFields(validations, setValidations)
                  if (fieldsDoesNotHaveErrors(validations)) {
                    handleSubmit()
                    return
                  }
                }

                if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                  if (e.key === KEYBOARD_ACTIONS.TAB) {
                    e.preventDefault()
                  }
                  setIsEditingProjectDropdown(false)
                  inputTaskNameRef.current?.focus()
                }

                // Let the original Dropdown keyboard handler process arrow keys
                inputProps.filterInputKeyDown(e)
              }
            })}
          />
          <div className="w-full mx-4 relative task-creation-name">
            {/* error icon */}
            <p className={`absolute top-0 left-0 pointer-events-none m-0 p-inputtext warning-icon-next w-full ${validations.name.errors.length ? 'opacity-100' : 'opacity-0'}`}>
              <span className='opacity-0 pointer-events-none'>
                ¿Qué hiciste este día
              </span>
              <i className="pi pi-exclamation-triangle mx-1"
                data-pr-tooltip="Este es un campo requerido"
                data-pr-position="bottom"
                style={{ color: '#FC3D39' }}
              />
            </p>
            <InputText
              className="w-full flex-1"
              value={taskName}
              ref={inputTaskNameRef}
              onChange={(e) => {
                setTaskName(e.target.value)
                setValidations(validateFieldValue(validations, 'name', e.target.value))
              }}
              onBlur={() => {
                setTaskName(taskName.trim())
              }}
              onKeyDown={(e) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  checkAllFields(validations, setValidations)
                  if (fieldsDoesNotHaveErrors(validations)) {
                    handleSubmit()
                    return
                  }
                }

                if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                  if (e.key === KEYBOARD_ACTIONS.TAB) {
                    e.preventDefault()
                  }
                  setIsEditingCategoryDropdown(true)
                  // setTimeouts are a workaround for an "onclick outside close dropdown menu" not working otherwise bug
                  setTimeout(() => {
                    const syntheticEvent = new Event('click', { bubbles: true, cancelable: true })
                    const acceptButton = document.querySelector('.accept-project-button')

                    if (acceptButton) {
                      acceptButton.dispatchEvent(syntheticEvent)
                    }

                    setTimeout(() => {
                      dropdownCategoryFilterRef.current?.show()
                    }, 15)
                  })
                }
              }}
              placeholder={validations.name.errors ? '¿Qué hiciste este día?' : 'Nombre de tu tarea'}
              error={validations.name.errors?.length}
            />
          </div>
          <div className='task-creation-details'>
            <SectionHeading className='new-task-form pb-0 flex gap-3'>
              <DropdownCategoryFilterInplace
                // key={submitting}
                value={selectedCategory?.specialCategoryID || selectedCategory?.categoryID}
                onChange={(e) => {
                  const optionValue = categories?.[0]?.specialCategoryID ? 'specialCategoryID' : 'categoryID'
                  const obj = categories?.find(o => o?.[optionValue] === e?.value)
                  if (e.originalEvent.type === 'click') {
                    setIsEditingCategoryDropdown(false)
                  }
                  setSelectedCategory(obj)
                  setValidations(validateFieldValue(validations, 'categoryId', obj?.categoryID))
                }}
                options={categories}
                error={validations.categoryId.errors?.length}
                dropdownRef={dropdownCategoryFilterRef}
                isEditingCategoryDropdown={isEditingCategoryDropdown}
                setIsEditingCategoryDropdown={setIsEditingCategoryDropdown}
                loading={loadingCategories}
                dropdownInputProps={(inputProps) => ({
                  autoFocus: true,
                  onKeyDown: (e) => {
                    if (e.key === KEYBOARD_ACTIONS.ENTER) {
                      checkAllFields(validations, setValidations)
                      if (fieldsDoesNotHaveErrors(validations)) {
                        return handleSubmit()
                      }
                    }

                    if (e.key === KEYBOARD_ACTIONS.TAB) {
                      e.preventDefault()
                    }
                    if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                      setIsEditingCategoryDropdown(false)
                      if (modules?.length) {
                        setIsEditingModuleDropdown(true)
                        setTimeout(() => {
                          dropdownModuleFilterRef?.current?.show()
                        }, 15)
                      } else {
                        setIsEditingSisuTime(true)
                      }
                    }

                    inputProps.filterInputKeyDown(e)
                  }
                })}
              />
              <DropdownModuleFilter
                value={selectedModule?.value}
                disabled={!modules?.length}
                onChange={(e) => {
                  if (e.originalEvent.type === 'click') {
                    setIsEditingModuleDropdown(false)
                  }
                  setSelectedModule(e)
                  setValidations(validateFieldValue(validations, 'moduleId', e.value))
                }}
                options={modules}
                error={validations.moduleId.errors?.length}
                dropdownRef={dropdownModuleFilterRef}
                isEditingModuleDropdown={isEditingModuleDropdown}
                setIsEditingModuleDropdown={setIsEditingModuleDropdown}
                onPressEnter={() => {
                  setIsEditingModuleDropdown(false)
                  setIsEditingSisuTime(true)
                }}
                loading={loadingModules}
                formValidations={validations}
                setFormValidations={setValidations}
                handleFormSubmit={handleSubmit}
              />
              <InplaceTimeControlled
                value={sisuTime}
                onChange={(value) => {
                  setSisuTime(value)
                  setValidations(validateFieldValue(validations, 'sisuTime', value))
                }}
                inputNumberProps={{
                  min: 0,
                  max: 24,
                  onKeyDown: (e) => {
                    if (e.key === KEYBOARD_ACTIONS.ENTER) {
                      checkAllFields(validations, setValidations)
                      if (fieldsDoesNotHaveErrors(validations)) {
                        handleSubmit()
                        return
                      }
                    }
                    if (e.key === KEYBOARD_ACTIONS.TAB) {
                      e.preventDefault()
                    }
                    if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                      setIsEditingSisuTime(false)
                      if (clientOfProject !== 1) {
                        setIsEditingBilledTime(true)
                      } else {
                        const syntheticEvent = new Event('click', { bubbles: true, cancelable: true })
                        const button = document.querySelector('.comment-button')

                        if (button) {
                          button.dispatchEvent(syntheticEvent)
                        }
                      }
                    }
                  }
                }}
                inplaceDisplayProps={{
                  className: 'p-0'
                }}
                onBlur={() => {
                  setIsEditingSisuTime(false)
                }}
                onToggle={(prev) => {
                  setIsEditingSisuTime(prev.value)
                }}
                active={isEditingSisuTime}
                error={validations.sisuTime?.errors?.length}
              />
              {clientOfProject !== 1
                ? (
                  <InplaceTimeControlled
                    value={billedTime}
                    onChange={(value) => {
                      setHasChangedBilledTime(true)
                      setBilledTime(value)
                      setValidations(validateFieldValue(validations, 'billedTime', value))
                    }}
                    iconClass='pi pi-dollar'
                    inplaceDisplayProps={{
                      className: 'p-0'
                    }}
                    inputNumberProps={{
                      onKeyDown: (e) => {
                        if (e.key === KEYBOARD_ACTIONS.ENTER) {
                          checkAllFields(validations, setValidations)
                          if (fieldsDoesNotHaveErrors(validations)) {
                            handleSubmit()
                            return
                          }
                        }
                        if (e.key === KEYBOARD_ACTIONS.TAB) {
                          e.preventDefault()
                        }
                        if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                          const syntheticEvent = new Event('click', { bubbles: true, cancelable: true })
                          const button = document.querySelector('.comment-button')

                          if (button) {
                            button.dispatchEvent(syntheticEvent)
                          }
                        }
                      }
                    }}
                    error={validations.billedTime.errors?.length}
                    active={isEditingBilledTime}
                    onToggle={(prev) => {
                      setIsEditingBilledTime(prev.value)
                    }}
                    onBlur={() => {
                      setIsEditingBilledTime(false)
                    }}
                  />
                )
                : null
              }
              <CommentModal
                formReset={setRefresh}
                currentValue={comment}
                onSubmit={(comment) => setComment(comment)}
                inputProps={{
                  onKeyDown: (e, currentValue) => {
                    if (e.key === KEYBOARD_ACTIONS.ENTER) {
                      checkAllFields(validations, setValidations)
                      if (fieldsDoesNotHaveErrors(validations)) {
                        setComment(currentValue)
                      }
                      e.preventDefault()
                    }
                    if (e.key === KEYBOARD_ACTIONS.TAB) {
                      e.preventDefault()
                    }
                    if (e.key === KEYBOARD_ACTIONS.ENTER || e.key === KEYBOARD_ACTIONS.TAB) {
                      document.querySelector('.comment-button')?.click()
                      buttonSubmitRef.current?.focus()
                    }
                  }
                }}
              />
              <Button
                ref={buttonSubmitRef}
                icon={`pi ${submitting ? 'pi-spin pi-spinner' : 'pi-plus'}`}
                className='p-button-success border-circle flex-shrink-0'
                onClick={handleSubmit}
                style={{ background: `${!BLOCK_TASK_FORM ? 'var(--clr-success)' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #CDD4DE'}`, border: 'none' }}
                disabled={BLOCK_TASK_FORM}
              />
            </SectionHeading>
          </div>
        </SectionHeading>
        <TimeDifferenceModal
          visible={openTimeDifferenceModal}
          setVisible={setOpenTimeDifferenceModal}
          service={handleSaveTask}
          value={timeDifferenceComment}
          postSubmit={() => setOpenTimeDifferenceModal(false)}
          onChange={(e) => {
            setTimeDifferenceComment(e.target.value)
            setValidations(validateFieldValue(validations, 'timeDifferenceComment', e.target.value))
          }}
          timeDifferenceComment={timeDifferenceComment}
        />
      </TabMenuContentContainer>
    </React.Fragment>
  )
}

export default TaskCreation
