import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ModuleTemplate from './ModuleTemplate'

const DraggableItem = ({
  item,
  orderMode,
  onDelete,
  onEdit,
  moveItem,
  onSelect,
  isSelected,
  blockDrag = false,
  visibleModalWarn,
  itemType = 'ITEM',
  columnTitle
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: itemType,
    item: { name: item.name, originalIndex: item.order, active: item.active },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  const [, drop] = useDrop({
    accept: itemType,
    hover: (draggedItem) => {
      if (draggedItem.name !== item.name) {
        const fromColumn = draggedItem.active ? 'Activos' : 'Inactivos'
        if (orderMode === 1 || blockDrag || columnTitle !== fromColumn) {
          return
        }
        moveItem(draggedItem.name, item.name, columnTitle)
        draggedItem.order = item.order
      }
    }
  })

  return (
    <li
      key={item.name}
      ref={(node) => drop(node)}
      style={{
        opacity: isDragging ? 0.5 : 1
      }}
      className={`p-picklist-item ${isSelected ? 'p-highlight' : ''}`}
      onClick={() => {
        onSelect(item)
      }}
    >
      <ModuleTemplate
        ref={(node) => drag(node)}
        key={item.name}
        orderMode={orderMode}
        module={item}
        onDelete={onDelete}
        onEdit={onEdit}
        visibleModalWarn={visibleModalWarn}
        columnTitle={columnTitle}
      />
    </li>
  )
}

export default DraggableItem
