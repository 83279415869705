import React from 'react'
// import Home from '../../views/home/Home.view'
import TimeRegister from '../../views/timeRegister/TimeRegister.view'
import { AppRoutes } from '../../consts/appRoutes.consts'
import Home from '../../views/home/Home.view'
import GeneralReport from '../../views/generalReport/GeneralReport.view'
import IndividualReport from '../../views/individualReport/IndividualReport.view'
import Settings from '../../views/settings/Settings.view'
// import TaskChangeLog from '../../views/taskChangelog/TaskChangeLog.view'
// import { Navigate } from 'react-router-dom'

export const TEAM_MEMBER_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />
  },
  {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister />
  },
  {
    path: AppRoutes.reporteGeneral.index,
    element: <GeneralReport/>
  },
  {
    path: AppRoutes.reporteGeneral.horasSemana,
    element: <GeneralReport/>
  },
  {
    path: AppRoutes.reporteIndividual.index,
    element: <IndividualReport/>
  },
  {
    path: AppRoutes.reporteIndividual.redirect,
    element: <IndividualReport/>
  },
  {
    path: AppRoutes.configuracion.index,
    element: <Settings/>
  }
  // {
  //   path: AppRoutes.historialCambios.index,
  //   element: <TaskChangeLog/>
  // },
  // {
  //   path: AppRoutes.historialCambios.redirect,
  //   element: <TaskChangeLog/>
  // }
]
