import React from 'react'
import { ConfigProvider, Select } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import MyDatePicker from '../atoms/DatePicker.atom'
import { REPORTS_PERIODS_ENG } from '../../consts/date.consts'

const { Option } = Select

/**
 * @type {import('./SwitchableDatePicker.compound')['default']}
 */
const SwitchableDatePicker = (props) => {
  let format

  switch (props.period) {
  case REPORTS_PERIODS_ENG.DIARIO:
    format = 'DD/MM/YYYY'
    break
  case REPORTS_PERIODS_ENG.SEMANAL:
    // eslint-disable-next-line
    format = `'Semana' wo'/'YYYY`
    break
  case REPORTS_PERIODS_ENG.MENSUAL:
    format = 'MMM/YYYY'
    break
  case REPORTS_PERIODS_ENG.ANUAL:
    format = 'YYYY'
    break
  }

  return (
    <ConfigProvider locale={esES}>
      <Select value={props.period} onChange={props.setPeriod} {...props.selectProps}>
        {!props.hasNoDaily && <Option value='day'>Diario</Option>}
        <Option value='week'>Semanal</Option>
        <Option value='month'>Mensual</Option>
        {props.hasYearly && <Option value='year'>Anual</Option>}
      </Select>
      <MyDatePicker
        picker={props.period}
        value={props.date}
        onChange={props.setDate}
        format={format}

        {...props.datePickerProps}
      />
    </ConfigProvider>
  )
}
export default SwitchableDatePicker
