import React from 'react'
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect'

/**
 * @param {import("primereact/multiselect").MultiSelectProps} props
 */
const MultiSelect = (props) => {
  return (
    <PrimeMultiSelect {...props}/>
  )
}

export default MultiSelect
