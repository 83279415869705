import React from 'react'

const TabviewControllersContainer = ({ order = '4', className, children }) => {
  return (
    <div className={`mr-0 ml-1 flex flex-1 justify-content-end align-items-center pb-2 gap-3 ${className}`} style={{ order }}>
      {children}
    </div>
  )
}

export default TabviewControllersContainer
