import { API_BUDGET } from '../consts/apiRoutes.consts'
import SERVICE_CONSTS from '../consts/services.consts'
import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const getBudgetMovementsByClientID = async (clientID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_BUDGET}/getBudgetMovementsByClientID`, {
      clientID
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const createBudgetMovementToClientID = async (clientID, movementData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_BUDGET}/createBudgetMovementToClientID`, {
      clientID,
      movementData
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getGeneralBudgetSummaryByClientID = async (clientID) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_BUDGET}/getGeneralBudgetSummaryByClientID`, {
      clientID
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
