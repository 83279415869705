import React, { createContext, useMemo, useState } from 'react'
import { MODALS_ACCESSIBLE } from '../consts/filtersMenu.consts'

export const FiltersMenuModalsContext = createContext()

export const FiltersMenuModalsContextProvider = ({ children }) => {
  const { DEFAULT_STATES, modalsAccessible } = useMemo(() => {
    return MODALS_ACCESSIBLE.reduce((acc, stateLabel) => {
      acc.DEFAULT_STATES[stateLabel] = false
      acc.modalsAccessible[stateLabel] = stateLabel
      return acc
    }, { DEFAULT_STATES: {}, modalsAccessible: {} })
  }, [])
  const [filtersMenuModalsState, setFiltersMenuModalsState] = useState({ ...DEFAULT_STATES })

  const updateFiltersMenuModalsState = (modalStateToUpdate, newValue) => {
    if (filtersMenuModalsState[modalStateToUpdate] === undefined) return null
    setFiltersMenuModalsState({
      ...filtersMenuModalsState,
      [modalStateToUpdate]: newValue
    })
  }

  return (
    <FiltersMenuModalsContext.Provider value={{ filtersMenuModalsState, updateFiltersMenuModalsState, modalsAccessible }}>
      {children}
    </FiltersMenuModalsContext.Provider>
  )
}
