import React, { forwardRef } from 'react'
import { Button as PrimeButton } from 'primereact/button'

const Button = forwardRef(
  /**
 * @param {import("primereact/button").ButtonProps & React.RefAttributes<HTMLButtonElement>} props
 * @param {React.Ref<HTMLButtonElement>} ref
 */
  (props, ref) => {
    return <PrimeButton ref={ref} {...props} />
  })

export default Button
