import React from 'react'
import Button from '../../atoms/Button.atom'
import PropTypes from 'prop-types'
import { chooseButtonClass } from '../../../utils/buttons.utils'

export default function SecondaryButton ({ type = 'regular', className, rounded = true, ...props }) {
  // eslint-disable-next-line
  return <Button label={props.label} icon={props.icon} onClick={props.onClick} className={`p-button-secondary ${chooseButtonClass(type)} ${rounded ? 'border-round-3xl' : ''} ${className}`} disabled={props.disabled}/>
}

SecondaryButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['regular', 'text', 'outlined']),
  rounded: PropTypes.bool,
  disabled: PropTypes.bool
}
