import React, { useState } from 'react'
import { ConfigProvider, Select } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import MyDatePicker from '../atoms/DatePicker.atom'
import PeriodPicker from '../../views/generalReport/controllers/components/PeriodPicker'
import { RANGES } from '../../consts/CompoundRangeDatePicker'
import useIsHoldingCtrlDown from '../../hooks/useIsHoldingCtrlDown'
const { Option } = Select

/** @type {import("./CompoundRangeDatePicker.compound").GetFormat} */
function getFormat (period) {
  let format

  switch (period) {
  case RANGES.DAY:
    format = 'DD/MM/YYYY'
    break
  case RANGES.WEEK:
    format = '\'Semana\' wo\'/\'YY'
    break
  case RANGES.MONTH:
    format = 'MMMM/YY'
    break
  default:
    format = 'DD/MM/YYYY'
    break
  }

  return format
}

/** @type {import("./CompoundRangeDatePicker.compound")["default"]} */
const CompoundRangeDatePicker = (props) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const hasCustomRange = props.hasCustomRange ?? true
  const period = props.period ?? RANGES.DAY
  const format = getFormat(period)

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  const order = [RANGES.DAY, RANGES.WEEK, RANGES.MONTH].concat(hasCustomRange ? [RANGES.CUSTOM] : [])
  const nextInOrder = (currentPeriod) => {
    const index = order.indexOf(currentPeriod)
    return order[index + 1] ?? order[0]
  }

  const onPeriodChange = (newPeriod) => {
    const [startDate, endDate] = props.dateRange
    let newDateRange = [startDate, startDate]

    switch (newPeriod) {
    case RANGES.DAY:
      newDateRange = [startDate.set({ hour: 12 }), startDate.set({ hour: 12 })]
      break
    case RANGES.WEEK:
      newDateRange = [startDate.startOf('week').set({ hour: 12 }), startDate.endOf('week').set({ hour: 12 })]
      break
    case RANGES.MONTH:
      newDateRange = [startDate.startOf('month').set({ hour: 12 }), startDate.endOf('month').set({ hour: 12 })]
      break
    case RANGES.CUSTOM:
      newDateRange = [startDate.set({ hour: 12 }), endDate.set({ hour: 12 })]
      break
    default:
      console.error('Invalid period in CompoundRangeDatePicker', newPeriod)
    }

    props.setDateRange(newDateRange)
    props.setPeriod(newPeriod)
  }

  return (
    <ConfigProvider locale={esES}>
      <Select className={`range-date-calendar__select ${isHoldingCtrlDown ? 'ctrl-selectable-range-selector' : ''}`} value={period} onChange={onPeriodChange}
        open={isSelectorOpen}
        onDropdownVisibleChange={(visible) => {
          if (!isHoldingCtrlDown) {
            setIsSelectorOpen(visible)
          }
        }}
        onClick={() => {
          if (isHoldingCtrlDown) {
            onPeriodChange(nextInOrder(period))
          }
        }}
        suffixIcon={<span className="p-dropdown-trigger-icon p-clickable pi pi-chevron-down"></span>}
        {...props.selectProps}>
        <Option value={RANGES.DAY}>Diario</Option>
        <Option value={RANGES.WEEK}>Semanal</Option>
        <Option value={RANGES.MONTH}>Mensual</Option>
        {hasCustomRange && <Option value={RANGES.CUSTOM}>Personalizado</Option>}
      </Select>
      {period === RANGES.CUSTOM
        ? <PeriodPicker
          className="range-date-calendar__date-picker"
          dateRange={props.dateRange}
          setDateRange={props.setDateRange}
          format={format}
          allowClear={false}
          inputReadOnly
        />
        : (
          <MyDatePicker
            inputReadOnly
            className="range-date-calendar__date-picker"
            picker={period}
            value={props.dateRange[0]}
            onChange={(newDate) => {
              let newDateRange = [newDate, newDate]
              switch (period) {
              case RANGES.DAY:
                newDateRange = [newDate.set({ hour: 12 }), newDate.set({ hour: 12 })]
                break
              case RANGES.WEEK:
                newDateRange = [newDate.startOf('week').set({ hour: 12 }), newDate.endOf('week').set({ hour: 12 })]
                break
              case RANGES.MONTH:
                newDateRange = [newDate.startOf('month').set({ hour: 12 }), newDate.endOf('month').set({ hour: 12 })]
                break
              default:
                console.error('Invalid period in CompoundRangeDatePicker', period)
              }
              props.setDateRange(newDateRange)
            }}
            format={format}
            allowClear={false}
            {...props.datePickerProps}
          />
        )}
    </ConfigProvider>
  )
}
export default CompoundRangeDatePicker
