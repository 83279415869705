import React, { useEffect, useState, useRef } from 'react'
import { getIndividualTasksOfTeamMemberId } from '../../../services/report.service'
import RegisteredTasksDataTable from './tables/RegisteredTasksTable/RegisteredTaskTable.controller'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import useToast from '../../../hooks/useToast.hook'
import { useNavigate } from 'react-router-dom'
import { RANGES } from '../../../consts/CompoundRangeDatePicker'
import ModalFormCreateTask from '../../../components/compounds/ModalFormCreateTask.compound'

const RegisteredTasks = React.forwardRef(({
  teamMember,
  period,
  dateRange,
  setShowControls,
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal
}, ref) => {
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const { setErrorMessage } = useToast()
  const navigate = useNavigate()
  const DEBOUNCE_DELAY = 500
  const debounceTimeout = useRef(null)

  useEffect(() => {
    if (!teamMember?.id || Object.keys(teamMember || {}).length < 2) {
      return
    }
    setUpdatingTask(null)
    setLoading(true)
    clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => {
      gettingIndividualTasks(true)
        .finally(() => {
          setLoading(false)
          setShowControls(true)
        })
    }, DEBOUNCE_DELAY)
    return () => clearTimeout(debounceTimeout.current)
  }, [teamMember, dateRange])

  useEffect(() => {
    if (error) {
      setErrorMessage({ message: error })
    }
  }, [error])

  const gettingIndividualTasks = async (globalLoad = false) => {
    try {
      if (!teamMember?.id) {
        setLoading(false)
        return
      }
      if (globalLoad) {
        setLoading(true)
      }
      const [startDate, endDate] = dateRange
      const response = await getIndividualTasksOfTeamMemberId(teamMember.id, startDate, endDate)
      if (response.success) {
        setTasks(response.result)
      } else {
        setError(response.message || 'Error desconocido')
      }
    } catch (err) {
      setError('Ocurrió un error al obtener las tareas')
    } finally {
      setLoading(false)
    }
  }

  const cm = useRef(null)
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }, {
      label: 'Historial de cambios',
      icon: 'pi pi-history',
      command: () => {
        console.log('historial click', selectedTask)
        navigate('/historial-tarea', {
          state: { taskId: selectedTask?.taskId }
        })
      }
    }
  ]
  const [cmItemsState, setCmItemsState] = useState(cmItems)
  useEffect(() => {
    if (!selectedTask) {
      setCmItemsState([])
    }
    if (!selectedTask?.hasChangelog) {
      setCmItemsState(cmItems.slice(0, 1))
    } else {
      setCmItemsState(cmItems)
    }
  }, [selectedTask])
  if (loading) {
    return (
      <div className='w-full h-20rem flex align-items-center justify-content-center'>
        <ProgressSpinner />
      </div>
    )
  }

  function getExportFilename () {
    const base = 'REP_IND_Reporte individual_'
    let periodStr = ''

    const [startDate, endDate] = dateRange

    switch (period) {
    case RANGES.DAY:
      {
        const day = String(startDate.day).padStart(2, '0')
        const month = String(startDate.month).padStart(2, '0')
        const year = String(startDate.year).slice(-2)
        periodStr = `${day}-${month}-${year}`
      }
      break
    case RANGES.WEEK:
      {
        const weekNumber = startDate.weekNumber
        const year = startDate.year.toString().slice(-2)
        periodStr = 'Semana' + weekNumber + '-' + year
      }
      break
    case RANGES.MONTH:
      {
        const month = startDate.setLocale('es').monthLong.charAt(0).toUpperCase() + startDate.setLocale('es').monthLong.slice(1)
        const year = startDate.year.toString().slice(-2)
        periodStr = month + '-' + year
      }
      break
    case RANGES.CUSTOM:
      {
        const day1 = String(startDate.day).padStart(2, '0')
        const month1 = String(startDate.month).padStart(2, '0')
        const year1 = String(startDate.year).slice(-2)

        const day2 = String(endDate.day).padStart(2, '0')
        const month2 = String(endDate.month).padStart(2, '0')
        const year2 = String(endDate.year).slice(-2)
        periodStr = `${day1}-${month1}-${year1}_${day2}-${month2}-${year2}`
      }
      break
    default:
      console.error('Periodo no válido al intentar exportar')
      periodStr = 'Error'
    }

    return base + `${teamMember.name.split(' ').join('')}${teamMember.lastName.split(' ').join('')}_${periodStr}`
  }

  return (
    <>
      <RegisteredTasksDataTable
        ref={ref}
        exportFilename={getExportFilename()}
        period={period}
        dateRange={dateRange}
        data={tasks}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
        cm={cm}
      />
      <ContextMenu ref={cm} model={cmItemsState} />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={teamMember?.id}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />

      <ModalFormCreateTask
        visible={openCreateTaskForTeamMemberModal}
        setVisible={setOpenCreateTaskForTeamMemberModal}
        teamMember={teamMember}
        onCreateCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
          setLoading(false)
        }}
        onSubmit={() => {
          setLoading(true)
        }}
      />
    </>
  )
})

export default RegisteredTasks
