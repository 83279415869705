import React, { useEffect, useState } from 'react'
import Modal from '../../atoms/Modal.atom'
import PropTypes, { element } from 'prop-types'
import { useMessage } from '../../../hooks/useMessage.hook'
import { messages } from '../../../consts/common.const'
import { Message } from '../../atoms/Message.atom'
import PrimaryButton from '../../molecules/buttons/PrimaryButton.molecule'
import SecondaryButton from '../../molecules/buttons/SecondaryButton.molecule'

export default function ModalForm ({ children, className = '', closable = false, ...props }) {
  const { ref, setErrorMessage } = useMessage()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    const mounted = true

    if (mounted) {
      if (props.externalMessageError) {
        setErrorMessage({ summary: props.externalMessageError })
      }
    }
  })

  async function handleSubmit () {
    setDisabled(true)
    const response = await props.service()
    // console.log('response modal', response)
    if (response === null || response === undefined) {
      setDisabled(false)
      return null
    }
    if (response.success) {
      if (props.postSubmit) {
        props.postSubmit(response.result)
      }
      props.setVisible(false)
      setDisabled(false)
    } else {
      const { errorMessage, message } = response
      if (ref.current) {
        setErrorMessage({ summary: errorMessage || message || messages.dataDoesNotLoad.details })
        setDisabled(false)
      }
    }
  }

  // Revisar bug visual
  const onHideHandler = () => {
    props.setVisible(false)
    return props.cleanUp ? props.cleanUp() : null
  }
  const handleSave = props.onSave || handleSubmit

  const defaultFooter = (
    <div>
      <SecondaryButton rounded icon='pi pi-times' label='Cancelar' type='text' onClick={onHideHandler} disabled={disabled}/>
      {props.footerChildren && props.footerChildren}
      {/* eslint-disable-next-line */}
      <PrimaryButton rounded icon='pi pi-check' label={props.saveLabel || 'Guardar'} onClick={handleSave} disabled={disabled || props.disabledSaveBtn} className={`mr-0 ${props.saveLabelClassName}`}/>
    </div>
  )
  return (
    <>
      <Modal
        // header={props.withoutHeader ? null : props.header || 'Formulario'}
        header={() => {
          if (props.withoutHeader) {
            return null
          }
          if (props.header) {
            return props.header
          }
          if (props?.headerIconClass || props?.headerTitle) {
            return (
              <div className="flex gap-3 align-items-center">
                <i className={`${props?.headerIconClass} text-4xl`} style={{ color: '#2896D8' }}/>
                <span className="text-3xl font-semibold font-cairo">{props?.headerTitle}</span>
              </div>
            )
          }
        }}
        visible={props.visible}
        breakpoints={{ '960px': '75vw' }} style={{ width: '50vw', maxWidth: '560px' }}
        footer={ props.withoutFooter ? '' : props.footer || defaultFooter }
        onHide={onHideHandler}
        draggable={false}
        className = {className}
        closable={closable}
        dismissableMask={props.dismissableMask}
      >
        <Message ref={ref}/>
        {children}
      </Modal>
    </>
  )
}

ModalForm.propTypes = {
  children: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.arrayOf(element), element, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.arrayOf(element), element, PropTypes.string]),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  service: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
}
