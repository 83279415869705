/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { formatDecimals } from '../../../../utils/numbers.utils'
import PieChart from '../../../../components/compounds/graphics/PieChart.compound'
import useScreenDimensionsContext from '../../../../hooks/contextConsumers/useScreenDimensionsContext.hook'
import SquareColorListLegend from '../../../../components/compounds/graphics/components/SquareColorListLegend'
import { OPACITY_HIGHLIGHT_LEVELS } from '../../../../consts/modules/graphics.consts'
import { debounce } from 'lodash'

const CategoriesChart = ({
  data: chartData
}) => {
  const colors = chartData?.reduce((acc, cat) => {
    acc[cat.categoryTitle] = cat?.categoryColor || '#64748B'
    return acc
  }, {})
  const formattedData = [
    {
      data: chartData?.map(cat => ({
        name: cat.categoryTitle,
        value: cat.sisuTime,
        color: colors[cat.categoryTitle],
        extraInfo: {
          tooltip: cat.categorySubtitle
        }
      }))
    }
  ]
  const [data, setData] = useState(formattedData)
  const [legendHeight, setLegendHeight] = useState(0)
  const { screenWidth } = useScreenDimensionsContext()
  useEffect(() => {
    setData(formattedData)
  }, [chartData])

  const isMobile = screenWidth < 780

  useEffect(() => {
    const getLegendHeight = () => {
      setTimeout(() => {
        const legendHeight = document.querySelector('.categories-chart .container-legend-graphic')?.clientHeight + 20
        if (legendHeight) setLegendHeight(legendHeight)
        else setTimeout(getLegendHeight, 100)
      }, 100)
    }
    getLegendHeight()
  }, [data, isMobile])

  const tooltipTemplate = (data) => {
    const sum = formattedData[0].data?.reduce((acc, cur) => acc + cur.value, 0)
    const percentage = data.value / sum * 100
    return (
      <div className='custom-recharts-tooltip flex text-white flex-column'>
        <span className='font-bold'>{data.name}</span>
        <span>{formatDecimals(data.value)} horas</span>
        <span>{formatDecimals(percentage, 1)}%</span>
      </div>
    )
  }

  const debouncedSetData = debounce(setData, 50)
  const handleMouseEnterLegend = (e) => {
    const hoveredLegend = e?.currentTarget?.dataset?.name
    data[0].data = data[0].data.map(item => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity: item.name === hoveredLegend ? OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED : OPACITY_HIGHLIGHT_LEVELS.NOT_HIGHLIGHTED
      }
    }))
    debouncedSetData([...data])
  }

  const handleMouseLeaveLegend = () => {
    data[0].data = data[0].data.map(item => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity: OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED
      }
    }))
    debouncedSetData([...data])
  }

  return (
    <div className='doughnut-chart categories-chart'>
      <span className='chart-title text-4xl font-semibold'>Categorías</span>
      <PieChart
        data={data}
        chartContainerConfig={{
          shadow: false,
          className: 'w-full mx-auto',
          style: { minHeight: `${legendHeight}px` }
        }}
        label={false}
        responsiveContainerConfig={{
          minHeight: '230px',
          minWidth: '250px',
          maxWidth: !isMobile ? '450px' : '200px'
          // maxHeight: '500px'
        }}
        legend
        legendTemplate={(data) => (
          <SquareColorListLegend
            data={data}
            onMouseEnter={handleMouseEnterLegend}
            onMouseLeave={handleMouseLeaveLegend}
          />
        )}
        legendConfig={{
          verticalAlign: isMobile ? 'bottom' : 'top',
          align: isMobile ? 'center' : 'right',
          layout: isMobile ? 'horizontal' : 'vertical'
        }}
        tooltip
        tooltipTemplate={tooltipTemplate}
        showEmptyMessage={!data?.[0].data?.length}
        emptyMessageConfig={{
          title: 'Sin información',
          message: 'El integrante del equipo seleccionado no tiene registros, intenta cambiando los filtros.'
        }}
      />
    </div>
  )
}

export default CategoriesChart
