import React from 'react'
import PropTypes from 'prop-types'

export default function ErrorMessageForm (props) {
  return (
    <span style={{ color: '#e24c4c' }}>*{props.errorMessage}</span>
  )
}

ErrorMessageForm.propTypes = {
  errorMessage: PropTypes.string
}
