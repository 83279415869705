import React from 'react'
import BadgeWithTooltip from '../../molecules/BadgeWithTooltip.molecule'

const InvernaderoSubtitleWithBadge = ({ invernadero }) => {
  return (
    <div className='flex gap-2 align-items-center'>
      <BadgeWithTooltip color={invernadero?.colorValor} value={invernadero?.nombre || invernadero?.invernaderoVirtualNombre}/>
      {invernadero?.id
        ? <span className='text-2xl'>
          {invernadero?.cicloNombre}
        </span>
        : ''}
    </div>
  )
}

export default InvernaderoSubtitleWithBadge
