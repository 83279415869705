import React, { useLayoutEffect, useRef, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import MultiSelect from '../../../../../components/atoms/MultiSelect.atom'
import { createRoot } from 'react-dom/client'
import ColumnContent from './ColumnContent.controller'
import { groupFooterTemplate, groupHeaderTemplate, StickyFooter } from './GroupTemplates.controller'
import { arraysContainSameStrings } from '../../../../../utils/arrays.utils'
import useIsHoldingCtrlDown from '../../../../../hooks/useIsHoldingCtrlDown'
import { DateTime } from 'luxon'
import ProjectChip from '../../../../../components/molecules/ProjectChip.molecule'
import { useSession } from '../../../../../hooks/auth/useSession.hook'

const RegisteredTasksDataTable = React.forwardRef(({
  setSelectedTask,
  data,
  cm,
  period,
  updatingTask,
  exportFilename
}, ref) => {
  if (!data) return null
  const {
    tasks,
    totals,
    totalsByDay
  } = data
  const [filteredTasks, setFilteredTasks] = useState(tasks || [])
  const [hasMounted, setHasMounted] = useState(false)
  const modules = [...new Map(tasks?.map(item => {
    return [item.module, { label: item.module, value: item.moduleId }]
  })).values()]
  let hasSpecialCategories = false
  const categories = [...new Map(tasks?.map(item => {
    if (item?.specialCategoryId) {
      hasSpecialCategories = true
      return [item.specialCategoryId, { label: item.specialCategoryTitle, value: item.specialCategoryId, isSpecialCategory: true }]
    }
    return [item.categoryId, { label: item.categoryTitle, value: item.categoryId, isSpecialCategory: false }]
  })).values()]
  const { user } = useSession()
  const categoryFilterOptionsRef = useRef(null)

  const categoryFilterTemplate = (options) => {
    categoryFilterOptionsRef.current = options

    return (
      <MultiSelect
        value={options.value}
        options={categories}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar categoría'
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={1}
        selectedItemsLabel='{0} seleccionados'
        disabled={categories.length === 0}
      />
    )
  }

  const moduleFilterOptionsRef = useRef(null)

  const moduleFilterTemplate = (options) => {
    moduleFilterOptionsRef.current = options
    const nonEmptyModules = modules.filter(m => m.value !== null)
    const modulesWithEmpty = [{
      label: 'Sin módulo',
      value: null
    }, ...nonEmptyModules]

    return (
      <MultiSelect
        value={options.value}
        options={modulesWithEmpty}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        placeholder='Filtrar módulo'
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={1}
        selectedItemsLabel='{0} seleccionados'
        disabled={modules.length === 0}
      />
    )
  }

  const clientFilterOptionsRef = useRef(null)

  const clientFilterTemplate = (options) => {
    clientFilterOptionsRef.current = options
    const projects = []
    const selectedClients = []
    tasks?.forEach((item, index) => {
      let project = projects.find(project => project.name === item.clientName)
      if (!project) {
        project = {
          name: item.clientName,
          code: item.clientCode,
          type: 'client',
          items: []
        }
        projects.push(project)
      }
      let projectItem = project.items.find(project => project.name === item.projectName)
      if (!projectItem) {
        projectItem = {
          name: item.projectName,
          code: item.projectCode,
          clientCode: item.clientCode,
          type: 'project'
        }
        project.items.push(projectItem)
      }
    })
    return (
      <MultiSelect
        className='client-project-multiselect'
        panelClassName='max-w-10rem'
        value={options.value}
        options={projects}
        onChange={(e) => {
          options.filterCallback(e.value)
        }}
        optionLabel='name'
        optionValue='name'
        placeholder='Todos'
        optionGroupLabel='name'
        optionGroupChildren='items'
        optionGroupTemplate={(option) => {
          if (option.type === 'client') {
            return (
              <ClientChip
                clientCode={option.code}
              />
            )
          }
        }}
        itemTemplate={(option) => {
          return (
            <div className='project-item-template flex align-items-center gap-2'>
              <ProjectChip
                clientCode={option.clientCode}
                projectCode={option.code}
              />
              <span className='white-space-normal'>
                {option.name}
              </span>
            </div>
          )
        }}
        selectedItemTemplate={(option) => {
          if (!option) return 'Todos'
          const item = projects
            .flatMap(client => client.items)
            .find(item => item.name === option)
          if (!selectedClients.includes(item.clientCode)) {
            selectedClients.push(item.clientCode)
            return (
              <>
                <ClientChip
                  clientCode={item.clientCode}
                />
                <ProjectChip
                  clientCode={item.clientCode}
                  projectCode={item.code}
                />
              </>
            )
          }
          return (
            <ProjectChip
              clientCode={item.clientCode}
              projectCode={item.code}
            />
          )
        }}
        emptyFilterMessage='Misión fallida... no encontramos nada'
        maxSelectedLabels={3}
        selectedItemsLabel='{0} seleccionados'
        disabled={projects.length === 0}
      />
    )
  }

  useLayoutEffect(() => {
    if (!hasMounted || filteredTasks?.length) {
      const dataTable = document.querySelector('.p-datatable-table')
      const footerDiv = document.createElement('table')
      footerDiv.className = 'custom-datatable-footer'
      dataTable.appendChild(footerDiv)
      createRoot(footerDiv).render(<StickyFooter totals={totals} />)
      setHasMounted(true)

      return () => {
        const dataTable = document.querySelector('.p-datatable-table')
        const footerDiv = document.querySelector('.custom-datatable-footer')
        if (footerDiv) {
          dataTable.removeChild(footerDiv)
        }
      }
    }
  }, [hasMounted, filteredTasks])

  const isHoldingCtrlDown = useIsHoldingCtrlDown()

  return (
    <>
      <DataTable
        ref={ref}
        exportFilename={exportFilename}
        className='individual-report-table'
        value={
          tasks?.map(task => ({
            ...task,
            date: DateTime.fromISO(task.date?.split('T')[0]).set({ hour: 12 }).toISODate(),
            categoryId: task.specialCategoryId || task.categoryId,
            categoryTitle: task.specialCategoryTitle || task.categoryTitle
          }))
        }
        rowGroupMode='subheader'
        groupRowsBy='date'
        rowGroupHeaderTemplate={groupHeaderTemplate}
        rowGroupFooterTemplate={(rowData) => groupFooterTemplate(rowData, totalsByDay, period)}
        removableSort
        onValueChange={filteredData => setFilteredTasks(filteredData)}
        onContextMenu={({ originalEvent, data }) => {
          if (user?.rol !== 'admin' && user?.projectRole !== 'admin') return
          cm?.current.show(originalEvent)
          setSelectedTask(data)
        }}
        scrollable
        style={{ height: '100%' }}
        scrollHeight='100%'
      >
        {ColumnContent(
          clientFilterTemplate,
          moduleFilterTemplate,
          categoryFilterTemplate,
          cm,
          setSelectedTask,
          isHoldingCtrlDown,
          hasSpecialCategories,
          (moduleId) => {
            const currValue = moduleFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              moduleFilterOptionsRef.current.filterApplyCallback([])
            } else {
              moduleFilterOptionsRef.current.filterApplyCallback([moduleId])
            }
          },
          (categoryId) => {
            const currValue = categoryFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              categoryFilterOptionsRef.current.filterApplyCallback([])
            } else {
              categoryFilterOptionsRef.current.filterApplyCallback([categoryId])
            }
          },
          (projectName) => {
            const currValue = clientFilterOptionsRef.current.value
            if (currValue?.length === 1) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback([projectName])
            }
          },
          (clientCode) => {
            const currValues = clientFilterOptionsRef.current.value
            const filteredClients = Array.from(new Set(tasks.filter(task => task.clientCode === clientCode).map(client => client.projectName)))
            if (currValues && arraysContainSameStrings(currValues, filteredClients)) {
              clientFilterOptionsRef.current.filterApplyCallback([])
            } else {
              clientFilterOptionsRef.current.filterApplyCallback(filteredClients)
            }
          },
          updatingTask
        )?.map((column, index) => (
          <Column
            key={index}
            {...column}
          />
        ))}
      </DataTable>
    </>
  )
})

export default React.memo(RegisteredTasksDataTable)
