import React from 'react'
import CustomDialog from '../../../../components/molecules/CustomDialog.molecule'
import PrimaryButton from '../../../../components/molecules/buttons/PrimaryButton.molecule'
import SecondaryButton from '../../../../components/molecules/buttons/SecondaryButton.molecule'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'
import { DateTime, Info } from 'luxon'
import {
  compareDates
} from '../../../../utils/date.utils'
const { createIgnoredWorkDay } = require('../../../../services/workDay.service')
const { useSession } = require('../../../../hooks/auth/useSession.hook')

const MissingDayDialog = () => {
  const {
    showMissingDayDialog,
    setShowMissingDayDialog,
    setShowedMissingDayDialog,
    selectedDate,
    setSelectedDate,
    setEditingMissingDay,
    setIsDayEditable,
    setRefresh
  } = useTimeRegisterContext()
  const {
    user
  } = useSession()
  return (
    <CustomDialog
      key={selectedDate}
      visible={showMissingDayDialog}
      setVisible={setShowMissingDayDialog}
      headerTitle="Día sin registrar"
      headerIconClass="pi pi-exclamation-triangle clr-danger"
      withoutDivider
      footer={(
        <div className="flex justify-content-end">
          <SecondaryButton
            label="Registrar tareas"
            onClick={() => {
              setShowMissingDayDialog(false)
              setShowedMissingDayDialog(true)
              setEditingMissingDay(true)
              setIsDayEditable(true)
            }}
            className='clr-secondary'
            type='text'
          />
          <PrimaryButton
            label="Ignorar día"
            icon="pi pi-check"
            className='m-0'
            onClick={async () => {
              await createIgnoredWorkDay(user.id, DateTime.fromJSDate(selectedDate).toISODate())
              setRefresh()
              setShowMissingDayDialog(false)
              setShowedMissingDayDialog(false)
              setEditingMissingDay(false)
              const currentDate = DateTime.fromJSDate(selectedDate)
              const nextDate = currentDate.plus({ days: 1 }).toJSDate()
              if (!compareDates(currentDate.toJSDate(), DateTime.local().toJSDate())) {
                setSelectedDate(nextDate)
              }
            }}
            borderAccent
            rounded
          />
        </div>
      )}
    >
      ¡Ups! Tienes el día {Info.weekdays('long')[DateTime.fromJSDate(selectedDate).weekday - 1]} en la semana actual sin registrar. ¿Qué te gustaría hacer?
    </CustomDialog>
  )
}

export default MissingDayDialog
