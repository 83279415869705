import React from 'react'
import { ProgressSpinner as PrimeProgressSpinner } from 'primereact/progressspinner'

const SPINNER_PROPS = {
  style: { width: '50px', height: '50px' },
  strokeWidth: 8,
  'aria-label': 'Cargando'
}
export default function ProgressSpinner (props) {
  return (
    <PrimeProgressSpinner
      {...props}
      {...SPINNER_PROPS}
    />
  )
}
