import React from 'react'
import { SelectButton } from 'primereact/selectbutton'

const OrderSwitch = ({
  orderMode,
  setOrderMode
}) => {
  return (
    <div>
      <span className='clr-text font-semibold'>
        Orden de módulos:
      </span>
      <SelectButton
        value={orderMode === 0 ? 'Personalizado' : 'Alfabético'}
        className='mt-2'
        onChange={(e) => setOrderMode(e.value === 'Personalizado' ? 0 : 1)}
        options={['Personalizado', 'Alfabético']}
      />
    </div>
  )
}

export default OrderSwitch
