import React from 'react'
import TimeRegister from '../../views/timeRegister/TimeRegister.view'
import { AppRoutes } from '../../consts/appRoutes.consts'
import GeneralReport from '../../views/generalReport/GeneralReport.view'
import IndividualReport from '../../views/individualReport/IndividualReport.view'
import Clients from '../../views/clients/Clients.view'
import CreateClient from '../../views/clients/CreateClient.view'
import EditClient from '../../views/clients/EditClient.view'
import Home from '../../views/home/Home.view'
import Settings from '../../views/settings/Settings.view'
import TaskChangeLog from '../../views/taskChangelog/TaskChangeLog.view'
import Dashboard from '../../views/dashboard/Dashboard.view'
import OccupationReport from '../../views/occupationReport/OccupationReport.view'
import ClientBudget from '../../views/clientBudget/ClientBudget.view'

export const ADMIN_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />
  },
  {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister />
  },
  {
    path: AppRoutes.reporteGeneral.index,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.horasSemana,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.horasProyecto,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.horasEquipo,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.horasCliente,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.horasClienteRedirect,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteGeneral.consumosEquipo,
    element: <GeneralReport />
  },
  {
    path: AppRoutes.reporteIndividual.index,
    element: <IndividualReport />
  },
  {
    path: AppRoutes.reporteIndividual.redirect,
    element: <IndividualReport />
  },
  {
    path: AppRoutes.clientes.index,
    element: <Clients />
  },
  {
    path: AppRoutes.clientes.crear,
    element: <CreateClient />
  },
  {
    path: AppRoutes.clientes.editar,
    element: <EditClient />
  },
  ...(process.env.REACT_APP_ENVIROMENT !== 'production'
    ? [
      {
        path: AppRoutes.clientes.presupuestoCliente,
        element: <ClientBudget/>
      }
    ]
    : []),
  {
    path: AppRoutes.configuracion.index,
    element: <Settings />
  },
  {
    path: AppRoutes.historialCambios.index,
    element: <TaskChangeLog />
  },
  {
    path: AppRoutes.historialCambios.redirect,
    element: <TaskChangeLog />
  },
  {
    path: AppRoutes.dashboard.index,
    element: <Dashboard />
  },
  {
    path: AppRoutes.reporteOcupacion.index,
    element: <OccupationReport />
  }
]
