import React from 'react'
import { formatToHtmlId } from '../../../../utils/strings.utils'
import Tooltip from '../../../atoms/Tooltip.atom'
import PropTypes from 'prop-types'

const SquareColorListLegend = ({
  data,
  onMouseEnter,
  onMouseLeave
}) => {
  const uniqueElements = data.filter((item, index, self) =>
    index === self.findIndex((obj) => obj.name === item.name)
  )

  return (
    <div className='flex flex-row md:flex-column gap-2 w-full md:w-10rem flex-wrap'>
      {uniqueElements.map((item, index) => {
        const tooltipId = `chart-label-${formatToHtmlId(item?.name)}-${index}`
        return (
          <React.Fragment key={item.name}>
            <div
              key={item.name}
              className='flex align-items-center gap-2'
              // id={tooltipId}
            >
              <div
                className='flex align-items-center gap-2'
                data-name={item.name}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <div
                  style={{ backgroundColor: item.color || '#64748B', minWidth: 10, minHeight: 10 }}
                ></div>
                <span
                  className='text-sm white-space-nowrap'
                >{item.name}</span>
              </div>
            </div>
            {
              item?.extraInfo?.tooltip &&
              <Tooltip
                key={'t-' + index}
                target={`#${tooltipId}`}
                content={item.extraInfo.tooltip}
                position='bottom'
                style={{
                  width: '227px'
                }}
              />
            }
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default SquareColorListLegend

SquareColorListLegend.propTypes = {
  data: PropTypes.array,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}
