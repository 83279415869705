import React from 'react'
import Card from '../../../components/atoms/Card.atom'
import InputTextForm from '../../../components/molecules/InputTextForm.molecule'
import SecondaryTitle from '../../../components/atoms/SecondaryTitle.atom'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import DropdownInput from '../../../components/molecules/DropdownInput.molecule'
import InputPasswordForm from '../../../components/molecules/InputPasswordForm.molecule'
import { ROLE_NAMES } from '../../../consts/modules/usuarios.consts'
import { validateFieldValue } from '../../../utils/validations.utils'

const { AGENTE, AGENTE_ADUANAL, TRANSPORTISTA } = ROLE_NAMES

const FormBasicInfoUsuarios = ({ isInUserEditionMode, validations, setValidations, basicInfoFormState, setBasicInfoFormState, usersOptions }) => {
  const handleChange = (e) => {
    const value = e?.value || e.target?.value
    const name = e.target.name
    if (isInUserEditionMode && name === 'password') {
      setBasicInfoFormState((prevState) => ({ ...prevState, [name]: value }))
      return
    }
    let newValidations = validations
    if (name === 'email') {
      const errors = validations.email.errors.filter(error => error.idError !== 5)
      const newValidationsWithoutEmailExistsMessage = {
        ...validations,
        email: {
          ...validations.email,
          errors
        }
      }
      newValidations = newValidationsWithoutEmailExistsMessage
    }
    if (name === 'password') {
      const errors = validations.password.errors.filter(error => error.idError !== 6)
      const newValidationsWithoutPasswordMessage = {
        ...validations,
        password: {
          ...validations.password,
          errors
        }
      }
      newValidations = newValidationsWithoutPasswordMessage
    }
    setValidations(validateFieldValue(newValidations, name, value))
    setBasicInfoFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  let isRolAgente = false
  let isRolAgenteAduanal = false
  let isTransportista = false

  if (basicInfoFormState?.rol) {
    isRolAgente = basicInfoFormState?.rol.nombre === AGENTE
    isRolAgenteAduanal = basicInfoFormState?.rol.nombre === AGENTE_ADUANAL
    isTransportista = basicInfoFormState?.rol.nombre === TRANSPORTISTA
  }

  return (
    <>
      <SecondaryTitle title="Información básica"/>
      <Card>
        <FormContainer>
          <FieldContainer>
            <InputTextForm
              name="nombre"
              label="Nombre"
              placeholder="Ingresa un nombre"
              onChange={handleChange}
              value={basicInfoFormState.nombre}
              error={validations.nombre.errors}
            />
          </FieldContainer>
          <FieldContainer>
            <DropdownInput
              name="rol"
              options={usersOptions?.roles}
              optionLabel="nombre"
              label="Rol"
              placeholder="Selecciona un rol"
              onChange={handleChange}
              value={basicInfoFormState.rol}
              error={validations.rol.errors}
            />
          </FieldContainer>
          {
            isTransportista &&
              <FieldContainer>
                <DropdownInput
                  name="lineaTransportista"
                  options={usersOptions?.lineasTransportista}
                  label="Línea transportista"
                  placeholder="Selecciona una línea"
                  optionLabel="nombre"
                  onChange={handleChange}
                  value={basicInfoFormState.lineaTransportista}
                  error={validations.lineaTransportista.errors}
                />
              </FieldContainer>
          }
          {
            isRolAgente &&
              <FieldContainer>
                <DropdownInput
                  name="locacion"
                  options={usersOptions?.locaciones}
                  label="Locación"
                  placeholder="Selecciona una locación"
                  optionLabel="nombre"
                  onChange={handleChange}
                  value={basicInfoFormState.locacion}
                  error={validations.locacion.errors}
                />
              </FieldContainer>
          }
          {
            isRolAgenteAduanal &&
              <FieldContainer>
                <DropdownInput
                  name="nacionalidad"
                  options={usersOptions?.nacionalidades}
                  label="Nacionalidad"
                  placeholder="Selecciona una nacionalidad"
                  optionLabel="nombre"
                  onChange={handleChange}
                  value={basicInfoFormState.nacionalidad}
                  error={validations.nacionalidad.errors}
                />
              </FieldContainer>
          }

          <FieldContainer>
            <DropdownInput
              name="departamento"
              options={usersOptions?.departamentos}
              label="Departamento"
              placeholder="Selecciona una departamento"
              optionLabel="nombre"
              onChange={handleChange}
              value={basicInfoFormState.departamento}
              error={validations.departamento.errors}
            />
          </FieldContainer>
          <FieldContainer>
            <InputTextForm
              name="email"
              label="Correo electrónico"
              placeholder="Ingresa un correo"
              onChange={handleChange}
              value={basicInfoFormState.email}
              error={validations.email.errors}
            />
          </FieldContainer>
          {
            isInUserEditionMode ||
            <FieldContainer>
              <InputPasswordForm
                name="password"
                type="password"
                label="Contraseña"
                placeholder="Ingresa una contraseña"
                onChange={handleChange}
                value={basicInfoFormState.password}
                error={validations.password.errors}
              />
            </FieldContainer>
          }
        </FormContainer>
      </Card>
    </>
  )
}

export default FormBasicInfoUsuarios
