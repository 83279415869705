/**
 * Converts a decimal number to a time string
 * Ex. 2.5 -> '02:30'
 * @param {String | Number} decimalHours
 * @returns
 */
export const formatHours = (decimalHours) => {
  const floatHours = parseFloat(decimalHours)
  const hours = Math.floor(floatHours)
  const minutes = Math.round((floatHours - hours) * 60)
  return `${String(hours).padStart(2, '0') || 0}:${String(minutes).padStart(2, '0') || 0}`
}
