import React, { forwardRef } from 'react'
import Button from '../../atoms/Button.atom'
import PropTypes from 'prop-types'
import { chooseButtonClass } from '../../../utils/buttons.utils'

/**
 * @type {import("./PrimaryButton.molecule")["default"]}
 */
const PrimaryButton = forwardRef(
  ({ btnType = 'regular', rounded = true, borderAccent = false, ...props }, ref) => {
    return (
      props.viewOnly
        ? null
        : <Button
          label={props.label}
          icon={props.icon}
          onClick={props.onClick}
          className={`${chooseButtonClass(btnType)} ${rounded ? 'border-round-3xl' : ''} bg-primary-highlight ${borderAccent ? 'border-primary-accent' : ''} ${props.className}`}
          disabled={props.disabled}
          id={props.id}
          ref={ref}
          style={props.style}
        />
    )
  }
)

/**
 * @type {import("./PrimaryButton.molecule").PrimaryButtonProps}
 */
const propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  borderAccent: PropTypes.bool,
  btnType: PropTypes.oneOf(['text', 'outlined', 'block', 'link', 'rounded'])
}

PrimaryButton.propTypes = propTypes

export default PrimaryButton
