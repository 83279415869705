import React, { useEffect, useState, useContext } from 'react'
import Column from '../../../components/atoms/table/Column.atom'
import DataTable from '../../../components/atoms/table/DataTable.atom'
import { toastMessages } from '../../../consts/common.const'
import UsersOptionsContext from '../../../contexts/data/UsersOptions.context'
import useToast from '../../../hooks/useToast.hook'
import { getUsuarios } from '../../../services/usuario.service'
import { createToastConfig } from '../../../utils/toast.utils'
import Dropdown from '../../../components/atoms/Dropdown.atom'
import { STATUS_FILTER_OPTIONS } from '../../../consts/modules/usuarios.consts'
import { useNavigate } from 'react-router-dom'
import MultiSelectFilter from '../../../components/molecules/MultiSelectFilter.molecule'
import { AppRoutes } from '../../../consts/appRoutes.consts'

const UsersTable = () => {
  const [usuariosTableData, setUsuariosTableData] = useState([])
  const [rolFilterOptions, setRolFilterOptions] = useState([])
  const [permisosDeEdicionOptions, setPermisosDeEdicionOptions] = useState([])
  const [userSelected, setUserSelected] = useState(null)

  const { usersOptions } = useContext(UsersOptionsContext)
  const toast = useToast()
  const navigate = useNavigate()

  const tableDataMapping = (usuarios, roles, permisosDeEdicion) => usuarios.map((usuario) => {
    const rol = roles.find(rol => rol.id === usuario.rolId)
    const permisoDeEdicion = permisosDeEdicion.find(permiso => permiso.id === usuario.permisoDeEdicionId)
    const status = usuario.deshabilitado === 1 ? 'Deshabilitado' : 'Habilitado'

    return {
      id: usuario.id,
      nombre: usuario.nombre,
      rol: rol.nombre,
      permisoDeEdicion: permisoDeEdicion.nombre,
      status
    }
  })

  const gettingInitialData = async () => {
    const responseService = await getUsuarios()
    if (!responseService.success) {
      const dataDoesNotLoadMessage = createToastConfig(toastMessages.dataDoesNotLoad)
      return toast.setMessage(...dataDoesNotLoadMessage)
    }
    const tableData = tableDataMapping(
      responseService.result,
      usersOptions.roles,
      usersOptions.permisosDeEdicion
    )
    setUsuariosTableData(tableData)
    setRolFilterOptions(usersOptions.roles)
    setPermisosDeEdicionOptions(usersOptions.permisosDeEdicion)
  }
  useEffect(() => {
    if (usersOptions) {
      gettingInitialData()
    }
  }, [usersOptions])

  useEffect(() => {
    if (userSelected) {
      navigate(AppRoutes.usuarios.actualizar.replace(':userId', userSelected.id))
    }
  }, [userSelected])

  const rolFilterTemplate = (options) => {
    return (
      <MultiSelectFilter
        value={options.value}
        options={rolFilterOptions }
        optionLabel="nombre"
        optionValue="nombre"
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Selecciona algún rol"
        className="p-column-filter"
        showClear
        selectedItemsLabel='roles seleccionados'
        maxSelectedLabels={2}
      />
    )
  }

  const permisosDeEdicionFilterTemplate = (options) => {
    return (
      <MultiSelectFilter
        value={options.value}
        optionLabel="nombre"
        optionValue="nombre"
        options={permisosDeEdicionOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Selecciona algún permiso"
        className="p-column-filter"
        showClear
        selectedItemsLabel={'permisos seleccionados'}
      />
    )
  }

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={STATUS_FILTER_OPTIONS}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Selecciona algún estatus"
        className="p-column-filter"
        showClear
      />
    )
  }

  return (
    <DataTable
      value={usuariosTableData}
      selection={userSelected}
      onSelectionChange={e => setUserSelected(e.value)}
      scrollable
      filterDisplay="row"
      selectionMode="single"
      rowClassName={(e) => ({ 'datatable-row-disabled': e.deshabilitado })}
    >
      <Column
        field="nombre"
        header="Nombre de usuario"
        filter
        showFilterMenu={false}
        filterPlaceholder="Buscar por nombre"
        filterMatchMode="contains"
        showClearButton={false}
      />
      <Column
        field="rol"
        header="Rol"
        filter
        showFilterMenu={false}
        filterElement={rolFilterTemplate}
        filterMatchMode="in"
        showClearButton={false}
      />
      <Column
        field="permisoDeEdicion"
        header="Permisos de edición"
        filter
        filterElement={permisosDeEdicionFilterTemplate}
        filterMatchMode="in"
        showFilterMenu={false}
        showClearButton={false}
      />
      <Column
        field="status"
        header="Estatus"
        filter
        filterElement={statusFilterTemplate}
        filterMatchMode="equals"
        showFilterMenu={false}
        showClearButton={false}
      />
    </DataTable>
  )
}

export default UsersTable
