import React from 'react'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import MyRangePicker from '../atoms/RangePicker.atoms'
import { REPORTS_PERIODS_ENG } from '../../consts/date.consts'

/**
 * @type {import('./SwitchableDatePicker.compound')['default']}
 */
const SwitchableAnnualPicker = (props) => {
  return (
    <ConfigProvider locale={esES}>
      <MyRangePicker
        className="range-date-calendar__date-picker"
        picker={REPORTS_PERIODS_ENG.ANUAL}
        value={props.dateRange}
        onChange={props.setDateRange}
        format='YYYY'
        {...props.datePickerProps}
      />
    </ConfigProvider>
  )
}
export default SwitchableAnnualPicker
