import React from 'react'
import Tooltip from '../atoms/Tooltip.atom'
import { generateRandomNumber } from '../../utils/numbers.utils'

const IconWithTooltip = ({ iconClassName = 'pi pi-info-circle', tooltipValue = '', position = undefined }) => {
  const id = generateRandomNumber()
  const defaultPosition = 'bottom'
  return (
    <>
      <Tooltip target={`.icon-tooltip-${id}`} position={position || defaultPosition}/>
      <i
        className={`${iconClassName} icon-tooltip-${id}`}
        data-pr-tooltip={tooltipValue}
      />
    </>

  )
}

export default IconWithTooltip
