import React from 'react'
import ModalForm from '../../../../../components/compounds/modalForm/ModalForm.compunds'
import SecondaryButton from '../../../../../components/molecules/buttons/SecondaryButton.molecule'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'

const EditModuleConfirmModal = ({
  visible,
  setVisible,
  editValue,
  onAccept = () => {},
  onReject = () => {}
}) => {
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerIconClass='pi pi-info-circle'
      headerTitle={'Editar modulo'}
      footer={
        <div>
          <SecondaryButton rounded label='Cancelar' type='text' onClick={() => {
            setVisible(false)
            onReject()
          }} />
          <PrimaryButton rounded icon='pi pi-check' label={'Aceptar'} onClick={() => {
            setVisible(false)
            onAccept()
          }} />
        </div>
      }
    >
      <span>
        Si modificas este módulo al guardar el formulario, afectará todas las tareas registradas en el.
      </span>
    </ModalForm>
  )
}

export default EditModuleConfirmModal
