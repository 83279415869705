import SERVICE_CONSTS from '../consts/services.consts'
// import { API_MENU } from '../consts/apiRoutes.consts'
// import { api } from './base/api.service'

const {
  RESPONSE_BASIC_STRUCTURE,
  MESSAGES
} = SERVICE_CONSTS

export const getFiltersOptions = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  const resultJson = {
    result: 'SUCCESS',
    success: true,
    message: 'TEST'
  }
  // try {
  //   result = await api.get(`${API_MENU}/options`)
  //   if (!result.ok) {
  //     const errorResult = await result.json()
  //     response.message = errorResult.details
  //     throw new Error()
  //   }
  // } catch (error) {
  //   return response
  // }

  // const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
