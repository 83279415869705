/* eslint-disable */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import SectionHeading from '../../../../components/layouts/containers/SectionHeading.container'
import FieldContainer from '../../../../components/layouts/containers/FieldContainer.container'
import TabMenuContentContainer from '../../../../components/layouts/containers/TabMenuContentContainer.container'
import {
  deleteTask,
  deleteTasks,
  duplicateTask,
  duplicateTasks,
  getTasksOfTeamMemberIDAtDate,
  updateTasksOrder
} from '../../../../services/task.service'
import useSessionContext from '../../../../hooks/contextConsumers/useSessionContext.hook'
import ContextMenu from '../../../../components/atoms/ContextMenu.atom'
import EditTask from '../../../../components/compounds/ModalFormEditTask.compound'
import DatePicker from './components/DatePicker'
import TaskList from './components/TaskList'
import { getWeekFromMondayUntilToday } from '../../../../utils/timeRegister.utils'
import { compareDates } from '../../../../utils/date.utils'
import { DateTime } from 'luxon'
import { TIME_REGISTER_MESSAGES } from '../../../../consts/modules/timeRegister.consts'
import ModalForm from '../../../../components/compounds/modalForm/ModalForm.compunds'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'
import { getMultipleSelectionMenu, getSingleSelectionMenu } from './utils/contextMenu.utils'
import SimpleMessage from '../../../../components/atoms/SimpleMessage.atom'
import useEffectAfterMount from '../../../../hooks/useEffectAfterMount.hook'

const TimeReport = ({
  onClickBeginEditingNewTaskName
}) => {
  const {
    doFetchTasks: refresh,
    setRefresh,
    setHasTasks,
    isDayFinished,
    setIsDayFinished,
    setIsDayEditable,
    isDayEditable,
    lastWorkDay,
    uncompleteDay,
    selectedDate,
    setSelectedDate,
    previousSelectedDate,
    loading: workDayLoading,
    missingDays,
    setCanSaveChanges
  } = useTimeRegisterContext()

  const [dailyTasks, setDailyTasks] = useState(undefined)
  const [selectedTask, setSelectedTask] = useState(undefined)
  const [editTaskDialog, setEditTaskDialog] = useState(false)
  const [message, setMessage] = useState(null)
  const [selectedTasks, setSelectedTasks] = useState([])
  const [tasksLoading, setTasksLoading] = useState(false)
  const [isRequestingBulkDeletion, setIsRequestingBulkDeletion] = useState(false)
  const { user } = useSessionContext()

  // useEffect(() => {
  //   // if (lastWorkDay) {
  //   //   setSelectedDate(DateTime.fromISO(lastWorkDay.date.split('T')[0]).set({ hour: 12 }).toJSDate())
  //   // } else {
  //   //   setSelectedDate(DateTime.local().set({ hour: 12 }).toJSDate())
  //   // }
  //   // const newDate = lastWorkDay
  //   //   ? DateTime.fromISO(lastWorkDay.date.split('T')[0]).set({ hour: 12 }).toJSDate()
  //   //   : DateTime.local().set({ hour: 12 }).toJSDate()
  //   // if (selectedDate?.getTime() !== newDate.getTime()) {
  //   //   console.error('cambio date', newDate)
  //   //   setSelectedDate(newDate)
  //   // }
  // }, [lastWorkDay])

  useEffectAfterMount(() => {
    if (!editTaskDialog) {
      console.log('EditTaskDialog closed')
      // gettingTasksAtDate(selectedDate)
      setRefresh()
    }
  }, [editTaskDialog])

  useEffect(() => {
    const selectedDateHasChanged = previousSelectedDate && selectedDate.getTime() !== previousSelectedDate.getTime()

    // setMessage(null)
    // console.log('selectedDate, refresh', selectedDate, refresh)
    gettingTasksAtDate(selectedDate)
    if (selectedDateHasChanged) setSelectedTasks([])

    const week = getWeekFromMondayUntilToday()
    if (week.some(day => compareDates(day, selectedDate))) {
      setIsDayEditable(true)
    } else {
      setIsDayEditable(false)
    }
  }, [selectedDate, refresh])

  useEffect(() => {
    // if (!dailyTasks) return
    if (isDayFinished && isDayEditable/* && DateTime.fromJSDate(selectedDate).toISODate() !== DateTime.local().setZone('UTC-6').set({ hour: 12 }).toISODate() */) {
      setMessage(TIME_REGISTER_MESSAGES.FINISHED_EDITABLE_DAY)
    } else if (!isDayEditable) {
      setMessage(TIME_REGISTER_MESSAGES.FINISHED_NOT_EDITABLE_DAY)
    } else if (uncompleteDay) {
      setMessage({
        ...TIME_REGISTER_MESSAGES.MISSING_DAY,
        text: TIME_REGISTER_MESSAGES.MISSING_DAY.text
      })
    } else {
      setMessage(null)
    }
  }, [isDayEditable, isDayFinished, selectedDate])

  const gettingTasksAtDate = async (date) => {
    setTasksLoading(true)
    // console.log('gettingTasksAtDate', date)
    const responseDailyTasks = await getTasksOfTeamMemberIDAtDate(user.teamMemberID, DateTime.fromJSDate(date))
    setDailyTasks(responseDailyTasks.result)
    setHasTasks(responseDailyTasks.result?.length > 0)
    setIsDayFinished(responseDailyTasks.result?.[0]?.isDayFinished)
    setTasksLoading(false)
  }
  const cm = useRef(null)
  const multiSelectCMRef = useRef(null)

  const onClickSelectCheckbox = (task) => {
    const alreadySelected = selectedTasks.find(selectedTask => selectedTask.taskID === task.taskID)
    if (alreadySelected) {
      setSelectedTasks(selectedTasks.filter(selectedTask => selectedTask.taskID !== task.taskID))
    } else {
      setSelectedTasks([...selectedTasks, task])
    }
  }

  const items = getSingleSelectionMenu({
    onDuplicate: async () => {
      await duplicateTask(selectedTask.taskID)
      setCanSaveChanges(true)
      setRefresh()
    },
    onEdit: () => setEditTaskDialog(true),
    onDelete: async () => {
      await deleteTask(selectedTask.taskID)
      setCanSaveChanges(true)
      setRefresh()
    },
    onSelect: () => onClickSelectCheckbox(selectedTask)
  })

  const multiSelectCMItems = getMultipleSelectionMenu({
    labelTemplate: () => <p className='p-2' style={{ fontSize: '1em', borderBottom: 'solid thin #edf0f3' }}> {selectedTasks.length} tarea{selectedTasks.length > 1 ? 's' : ''} seleccionadas</p>,
    onDeselect: () => setSelectedTasks([]),
    onDuplicate: async () => {
      const sortedTasks = selectedTasks.sort((a, b) => a.taskOrder - b.taskOrder || a.taskID - b.taskID)?.map(task => task.taskID)
      await duplicateTasks(sortedTasks)
      await gettingTasksAtDate(selectedDate)
      setRefresh()
      setSelectedTasks([])
      setCanSaveChanges(true)
    },
    onDelete: async () => {
      setIsRequestingBulkDeletion(true)
    }
  })

  const handleItemDrop = async (e) => {
    const newTasks = e.value.map((task, index) => (
      {
        id: task.taskID,
        orderIndex: index + 1
      }
    ))
    await updateTasksOrder({ tasks: newTasks })
    setDailyTasks(e.value)
    // setRefresh()
    await gettingTasksAtDate(selectedDate)
  }

  return (
    <TabMenuContentContainer className='daily-tasks-wrapper'>
      <SectionHeading>
        <FieldContainer className='pb-0 px-0 pt-0' col='12' md='12'>
          <SectionHeading className='pb-0 flex justify-content-between'>
            <h2>Mi registro del día</h2>
            <DatePicker
              key={selectedDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              uncompleteDay={uncompleteDay}
              calendarProps={{
                style: {
                  minWidth: 190
                }
              }}
            />
          </SectionHeading>
        </FieldContainer>
      </SectionHeading>
      {
        message && <SimpleMessage {...message} className='w-full justify-content-start mt-0 mb-2 mt-3' />
      }
      <FieldContainer className='pt-0 px-0' md={12}>
        <TaskList
          dailyTasks={dailyTasks}
          setSelectedTask={setSelectedTask}
          handleItemDrop={handleItemDrop}
          isDayFinished={isDayFinished}
          isDayEditable={isDayEditable}
          selectedTasks={selectedTasks}
          onClickSelectCheckbox={onClickSelectCheckbox}
          contextMenuRef={cm}
          multiSelectCMRef={multiSelectCMRef}
          messageProps={message}
          onClickBeginEditingNewTaskName={onClickBeginEditingNewTaskName}
          lastWorkDay={lastWorkDay}
          selectedDate={selectedDate}
          // loading={tasksLoading || workDayLoading}
          loading={workDayLoading}
        />
      </FieldContainer>
      <ContextMenu ref={cm} model={items} />
      <ContextMenu ref={multiSelectCMRef} model={multiSelectCMItems} />
      <EditTask
        key={selectedTask?.taskID}
        visible={editTaskDialog}
        setVisible={setEditTaskDialog}
        task={selectedTask}
        onlyActiveProjects={false}
        onlyFavoriteProjects={true}
        onEditCompleted={() => {
          setCanSaveChanges(true)
        }}
      />
      <ModalForm
        visible={isRequestingBulkDeletion}
        setVisible={setIsRequestingBulkDeletion}
        header={
          <div className="flex gap-3 align-items-center">
            <i className="pi pi-pencil text-4xl" style={{ color: '#2896D8' }} />
            <span className="text-3xl font-semibold">¿Estás seguro?</span>
          </div>
        }
        service={async () => {
          const arrayOfTasks = selectedTasks.map(task => task.taskID)
          await deleteTasks(arrayOfTasks)
          await gettingTasksAtDate(selectedDate)
          setRefresh()
          setSelectedTasks([])
          setIsRequestingBulkDeletion(false)
        }}
        saveLabel='Confirmar'
      >
        Eliminarás <strong>{selectedTasks.length}</strong> tareas ¿Estás seguro de que quieres eliminarlas?
      </ModalForm>
    </TabMenuContentContainer>
  )
}

export default TimeReport
