export const colorSchemeSystem = 'ligth'
export const menuMode = 'sidebar'

export const severities = {
  error: 'error',
  success: 'success',
  info: 'info',
  warnig: 'warn'
}

export const toastMessages = {
  dataDoesNotLoad: {
    type: severities.error,
    headline: 'Ups...',
    details: 'Hubo un problema al cargar los datos.'
  },
  failToUpload: {
    type: severities.error,
    headline: 'Ups... Hubo un error',
    details: 'No se pudo actualizar tus datos.'
  },
  failToSave: {
    type: severities.error,
    headline: 'Ups... Hubo un error',
    details: 'No se pudo guardar tus datos.'
  },
  failIncompleteInformation: {
    type: severities.warnig,
    headline: 'Ups...',
    details: 'Hay datos faltantes.'
  },
  failInvalidInformation: {
    type: severities.warnig,
    headline: 'Ups...',
    details: 'Hay datos inválidos.'
  },
  failThisRecordAlreadyExists: {
    type: severities.warnig,
    headline: 'Ups...',
    details: 'Este registro ya existe.'
  },
  failValidatingEmail: {
    type: severities.error,
    headline: 'Ups...',
    details: 'No se pudo validar el correo.'
  },
  failToUploadFirma: {
    type: severities.error,
    headline: 'Ups...',
    details: 'No se pudo guardar la firma del usuario.'
  },
  successToUpload: {
    type: severities.success,
    headline: '¡Acción éxitosa!',
    details: 'Se han actualizado tus datos correctamente.'
  },
  successToSave: {
    type: severities.success,
    headline: '¡Acción éxitosa!',
    details: 'Se han guardado tus datos correctamente.'
  }
}

export const messages = {
  dataDoesNotLoad: {
    type: severities.error,
    details: 'Hubo un problema al cargar tus datos.'
  },
  failToUpload: {
    type: severities.error,
    details: 'Ups... Hubo un error\nNo se pudo actualizar tus datos.'
  }
}
