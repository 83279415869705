import React from 'react'
import Modal from '../atoms/Modal.atom'
import PropTypes from 'prop-types'
import PrimaryButton from './buttons/PrimaryButton.molecule'
import DangerButton from './buttons/DangerButton.molecule'

const ModalConfirmation = (props) => {
  const loadingSaveButton = props.loadingSaveButton !== undefined ? props.loadingSaveButton : false
  const defaultContent = {
    title: 'Titulo',
    message: 'Mensaje de confirmación.',
    buttonCancelLabel: 'Cancelar',
    buttonSaveLabel: 'Aceptar'
  }
  const ConfirmationButton = () => {
    if (props.btnAcceptType === 'danger') {
      return <DangerButton
        loading={loadingSaveButton}
        icon="pi pi-exclamation-triangle"
        onClick={() => {
          props.onVisibleChange(!props.visible)
          props.onAccept()
        }}
        label={props.content?.buttonSaveLabel || defaultContent.buttonSaveLabel} />
    }
    return <PrimaryButton
      loading={loadingSaveButton}
      icon="pi pi-check"
      onClick={() => {
        props.onVisibleChange(!props.visible)
        if (props.onAccept) props.onAccept()
      }}
      label={props.content?.buttonSaveLabel || defaultContent.buttonSaveLabel} />
  }
  const showCancelButton = props.showCancelButton !== undefined ? props.showCancelButton : true

  return (
    <>
      <Modal
        draggable={false}
        closable={false}
        visible={props.visible}
        header={
          <div className="flex align-items-center">
            <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style={{ width: '32px', height: '32px' }}>
              <i className="pi pi-info text-lg"></i>
            </span>
            <span className="font-medium text-2xl text-900">{props.content?.title || defaultContent.title}</span>
          </div>
        }
        footer={
          <div className="border-top-1 surface-border pt-3">
            {
              showCancelButton && <PrimaryButton
                btnType='text'
                icon="pi pi-times"
                onClick={() => props.onVisibleChange(!props.visible)}
                label={props.content?.buttonCancelLabel || defaultContent.buttonCancelLabel}
                className="p-button-text" />
            }
            <ConfirmationButton/>
          </div >
        }
        modal
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '40vw' }}>
        {props.content?.mainTemplate
          ? props.content?.mainTemplate()
          : <p className="line-height-3 p-0 m-0">
            {props.content?.message || defaultContent.message}
          </p>
        }
      </Modal>
    </>

  )
}

ModalConfirmation.propTypes = {
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onAccept: PropTypes.func,
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    buttonCancelLabel: PropTypes.string,
    buttonSaveLabel: PropTypes.string
  }),
  showCancelButton: PropTypes.bool
}

export default ModalConfirmation
