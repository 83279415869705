/* eslint-disable */
import React, { useState } from 'react'
import ModalForm from '../../../components/compounds/modalForm/ModalForm.compunds'
import FormContainer from '../../../components/layouts/containers/FormContainer.container'
import { InputText } from 'primereact/inputtext'
import InputTextForm from '../../../components/molecules/InputTextForm.molecule'
import {
  checkAllFields,
  fieldsHaveErrors,
  isNotEmpty,
  validateFieldValue
} from '../../../utils/validations.utils'
import Button from '../../../components/atoms/Button.atom'
import FieldContainer from '../../../components/layouts/containers/FieldContainer.container'
import { useMessage } from '../../../hooks/useMessage.hook'
import useToast from '../../../hooks/useToast.hook'
import { Message } from '../../../components/atoms/Message.atom'

const emptyFieldsState = {
  nombre: {
    errors: [],
    value: '',
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  edad: {
    errors: [],
    value: '',
    typeValidations: [isNotEmpty],
    validationOptions: {}
  }
}
const ModalFormSandbox = () => {
  const { ref, setErrorMessage, setSuccessMessage } = useMessage()
  const { setSuccessMessage: setToastSuccess } = useToast()
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({
    nombre: ''
  })
  const initialFieldsState = {
    nombre: {
      errors: [],
      value: '',
      typeValidations: [isNotEmpty],
      validationOptions: {}
    },
    edad: {
      errors: [],
      value: '',
      typeValidations: [isNotEmpty],
      validationOptions: {}
    }
  }
  const [validations, setValidations] = useState(initialFieldsState)
  function handleChange ({ target: { name, value } }) {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
  }
  async function handleSubmit () {
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }

    const res = {
      success: true
    }

    if (res.success) {
      setSuccessMessage({ summmary: 'Formulario enviado!' })
    }
    setTimeout(() => {
      setToastSuccess({ message: 'Formulario enviado!', lifeTime: 150000 })
      return res
    }, 1500)
  }
  function cleanUp () {
    setData({
      nombre: '',
      edad: ''
    })
    setValidations(emptyFieldsState)
  }

  return (
    <>
      <ModalForm
        visible={visible}
        setVisible={setVisible}
        header={
          <div className="flex gap-3 align-items-center">
            <i className="pi pi-check text-4xl" style={{ color: '#2896D8' }}/>
            <span className="text-3xl font-semibold">Formulario</span>
          </div>
        }
        service={handleSubmit}
        cleanUp={cleanUp}
        postSubmit={cleanUp}
        closable={false}
      >
        <Message ref={ref} />
        <FormContainer>
          <FieldContainer md={12}>
            <InputTextForm
              label='Nombre'
              name='nombre'
              placeholder='Este es el placeholder del nombre'
              value={data.nombre}
              onChange={handleChange}
              error={validations.nombre.errors}
            />
          </FieldContainer>
          <FieldContainer md={12}>
            <InputTextForm
              label='Edad'
              name='edad'
              placeholder='Este es el placeholder de la edad'
              value={data.edad}
              onChange={handleChange}
              error={validations.edad.errors}
              require
            />
          </FieldContainer>
        </FormContainer>
      </ModalForm>
      <Button label='Abrir formulario modal' onClick={() => setVisible(true)}/>
    </>
  )
}

export default ModalFormSandbox
