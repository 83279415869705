import React from 'react'
import { Password } from 'primereact/password'

const InputPassword = (props) => {
  return (
    <Password feedback={false} toggleMask {...props}/>
  )
}

export default InputPassword
