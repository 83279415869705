import React from 'react'

export const percentageBody = (rowData, field, decimals = 2) => {
  const float = parseFloat(rowData?.[field])
  if (isNaN(float)) return <span>N/A</span>
  const data = parseFloat(parseFloat(float * 100).toFixed(decimals))

  if (Number.isInteger(data)) return <span>{data} %</span>
  return <span>{data.toFixed(decimals)} %</span>
}
