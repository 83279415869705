import React, { useState } from 'react'
import ClientProjectChip from '../../../../components/molecules/ClientProjectChip.molecule'
import { getFavoriteActiveProjects, getTeamMemberActiveProjects, saveFavoriteProjects } from '../../../../services/project.service'
import useSessionContext from '../../../../hooks/contextConsumers/useSessionContext.hook'
import { OverlayPanel } from '../../../../components/atoms/OverlayPanel.atom'
import { InlineMultiSelectDropdown } from '../../../../components/molecules/InlineMultiSelectDropdown.molecule'
import Button from '../../../../components/atoms/Button.atom'
import { arraysContainSameNumbers } from '../../../../utils/arrays.utils'
import useEffectAfterMount from '../../../../hooks/useEffectAfterMount.hook'

const orderProjects = (projects) => {
  const sisuIntOptions = [...projects?.filter(option => option?.clientTagName === 'INT' && option?.projectName !== 'SISU (interno)')]
  let withoutInt = projects?.filter(p => p.clientTagName !== 'INT')
  withoutInt = withoutInt.sort((a, b) => {
    if (a.clientTagName < b.clientTagName) return -1
    if (a.clientTagName > b.clientTagName) return 1
    if (a.projectTagName < b.projectTagName) return -1
    if (a.projectTagName > b.projectTagName) return 1
    return 0
  })
  return [...sisuIntOptions, ...withoutInt]
}

const MyProjects = ({
  onAccept,
  optionValue = 'projectID',
  optionLabel = 'projectTagName',
  overlayRef
}) => {
  const [allProjects, setAllProjects] = useState([])
  const [selectedProjects, setSelectedProjects] = useState([])
  const [bufferedSelectedProjects, setBufferedSelectedProjects] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { user } = useSessionContext()

  useEffectAfterMount(() => {
    if (isOpen) {
      getProjectsForElement()
    }
  }, [isOpen])

  const getProjectsForElement = async () => {
    setLoading(true)
    await gettingProjects()
    await gettingFavoriteProjects()
    setLoading(false)
  }
  const gettingProjects = async () => {
    try {
      const response = await getTeamMemberActiveProjects(user.teamMemberID)
      if (response?.result) {
        setAllProjects(orderProjects(response.result))
      }
    } catch (error) {
      console.error(error)
    }
  }
  const gettingFavoriteProjects = async () => {
    try {
      const response = await getFavoriteActiveProjects(user.teamMemberID)
      if (response?.result) {
        const withoutInt = response.result.filter(p => p.projectID !== 1)
        setSelectedProjects(withoutInt.map(p => p.projectID))
        setBufferedSelectedProjects(withoutInt.map(p => p.projectID))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <OverlayPanel
      ref={overlayRef}
      dismissable={false}
      id='local-multiselect-dropdown-overlay'
      onShow={() => setIsOpen(true)}
      onHide={() => setIsOpen(false)}
    >
      <InlineMultiSelectDropdown
        value={bufferedSelectedProjects}
        options={allProjects}
        optionValue={optionValue}
        optionLabel={optionLabel}
        placeholder='Selecciona un proyecto'
        onChange={setBufferedSelectedProjects}
        maxHeight={200}
        itemTemplate={(option) => {
          const current = allProjects?.find(o => o[optionValue] === option[optionValue])
          if (!current) return null
          const { clientTagName, projectTagName } = current
          return (
            <div className='flex gap-2 align-items-center'>
              <ClientProjectChip
                clientCode={clientTagName}
                projectCode={projectTagName}
              />
            </div>
          )
        }}
        loading={loading}
        maxSelectedLabels={1}
        selectedItemsLabel={
          <span>{bufferedSelectedProjects?.length} proyectos seleccionados</span>
        }
      />
      <div className="p-dialog-footer">
        <div>
          <Button
            label="Cancelar"
            icon='pi pi-times'
            rounded
            className='p-button p-component p-button-secondary p-button-text border-round-3xl'
            onClick={() => {
              overlayRef.current.hide()
              setBufferedSelectedProjects(selectedProjects)
            }}
          />
          <Button
            label='Guardar'
            icon='pi pi-check'
            rounded
            disabled={arraysContainSameNumbers(selectedProjects, bufferedSelectedProjects) || submitting}
            className='bg-primary-highlight border-round-3xl'
            onClick={async () => {
              setSelectedProjects(bufferedSelectedProjects)
              setSubmitting(true)
              try {
                await saveFavoriteProjects(user.teamMemberID, bufferedSelectedProjects.map(p => ({ projectID: p })))
                overlayRef.current.hide()
                onAccept()
              } catch (error) {
                console.error(error)
              }
              setSubmitting(false)
            }}
          />
        </div>
      </div>
    </OverlayPanel>
  )
}

export default MyProjects
