/* eslint-disable */
import React from 'react'
import AppMenu from './controllers/AppMenu'
import { colorSchemeSystem, menuMode } from '../consts/common.const'
import MainLayout from '../components/layouts/MainLayout.layout'
import MainContentLayout from '../components/layouts/MainContentLayout.layout'
import SidebarContext from '../contexts/SidebarContext'
import { ApplicationRoutes } from './ApplicationRoutes'
import AppFiltersMenu from './controllers/AppFiltersMenu'
import AppOverlayBackground from './controllers/AppOverlayBackground'
import { ScreenDimensionsContextProvider } from '../contexts/ScreenDimensionsContext.context'
import { FiltersMenuModalsContextProvider } from '../contexts/FiltersMenuModalsContext.context'
import FiltersMenuContextProvider from '../contexts/FiltersMenuContext.context'
import AppHeader from './controllers/AppHeader'
import { ReportFiltersContextProvider } from '../contexts/ReportFiltersContext.context'
import { ClientColorsProvider } from '../contexts/data/ClientColors.context'
import useDebugKeybinds from '../hooks/useDebugKeybinds'

export function AuthorizedRoutes () {
  if (process.env.NODE_ENV !== 'production') {
    useDebugKeybinds()
  }

  return (
    <ScreenDimensionsContextProvider>
      <SidebarContext>
        <FiltersMenuModalsContextProvider>
          <FiltersMenuContextProvider>
            <ReportFiltersContextProvider>
              <ClientColorsProvider>
                <MainLayout>
                  {/* <AppHeader/> */}
                  <AppMenu
                    menuMode={menuMode}
                    colorScheme={colorSchemeSystem}
                  />

                  <AppFiltersMenu/>

                  <AppOverlayBackground/>

                  <MainContentLayout>
                    <ApplicationRoutes />
                  </MainContentLayout>

                </MainLayout>
              </ClientColorsProvider>
            </ReportFiltersContextProvider>
          </FiltersMenuContextProvider>
        </FiltersMenuModalsContextProvider>
      </SidebarContext>
    </ScreenDimensionsContextProvider>
  )
};
