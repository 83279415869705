import React from 'react'
import CustomDialog from '../../../../components/molecules/CustomDialog.molecule'
import PrimaryButton from '../../../../components/molecules/buttons/PrimaryButton.molecule'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'

const UncompleteDayDialog = () => {
  const {
    showUncompleteDayDialog,
    setShowUncompleteDayDialog,
    showedUncompleteDayDialog,
    setShowedUncompleteDayDialog,
    showMissingDayDialog,
    showedMissingDayDialog
  } = useTimeRegisterContext()
  if (!showUncompleteDayDialog) return null
  return (
    <CustomDialog
      visible={(showUncompleteDayDialog && !showedUncompleteDayDialog && !showedMissingDayDialog) || !showMissingDayDialog}
      setVisible={setShowUncompleteDayDialog}
      headerTitle="Finalización pendiente"
      headerIconClass="pi pi-exclamation-triangle clr-danger"
      withoutDivider
      footer={(
        <div className="flex justify-content-end">
          <PrimaryButton
            label="Ir al día pendiente"
            icon="pi pi-check"
            className='m-0'
            onClick={() => {
              setShowUncompleteDayDialog(false)
              setShowedUncompleteDayDialog(true)
            }}
            borderAccent
            rounded
          />
        </div>
      )}
    >
      ¡Ups! No puedes continuar con tu registro de hoy, tienes otro día sin finalizar.
    </CustomDialog>
  )
}

export default UncompleteDayDialog
