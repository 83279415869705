import React from 'react'
import FormCreateClient from './controllers/createClient/FormCreateClient.controller'
import FormHeaderCreateClient from './controllers/FormHeaderClient.controller'

const CreateClient = () => {
  return (
    <>
      <FormHeaderCreateClient/>
      <FormCreateClient/>
    </>
  )
}

export default CreateClient
