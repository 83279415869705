import React from 'react'
import { Tooltip as PrimeTooltip } from 'primereact/tooltip'

/**
 * @param {import("primereact/tooltip").TooltipProps} props
 * @returns
 */
const Tooltip = React.forwardRef((props, ref) => {
  return <PrimeTooltip {...props} ref={ref}/>
})

export default Tooltip
