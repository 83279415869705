/* eslint-disable */
import React from 'react'
import MultiSelect from '../atoms/MultiSelect.atom'
import ClientChip from '../molecules/ClientChip.molecule'
import ProjectChip from '../molecules/ProjectChip.molecule'

const MultiSelectClientProjectFilter = ({
  data,
  options
}) => {
  const projects = data.map(client => ({
    clientId: client?.data?.clientId,
    name: client.data.name,
    code: client.data.code,
    items: client.children.map(child => ({
      clientId: client?.data?.clientId,
      name: child.data.name,
      code: child.data?.tagName || child.data.name.split('-')[0].trim(),
      clientCode: client?.data?.code
    }))
  }))
  // let selectedClients = []
  // const [selectedClients, setSelectedClients] = React.useState([])
  const getSelectedItemsLabel = () => {
    const selectedValues = options.value || []
    const selectedParents = selectedValues.filter(value =>
      projects.some(project => project.name === value)
    )
    const adjustedSelectedCount = selectedValues.length - selectedParents.length
    return adjustedSelectedCount > 0
      ? `${adjustedSelectedCount} seleccionados`
      : 'Ningún proyecto seleccionado'
  }
  return (
    <MultiSelect
      className='client-project-filter-multiselect'
      value={options.value}
      options={projects}
      onChange={(e) => {
        if (!e?.value || !Array.isArray(e.value)) {
          return
        }
        const selectedNames = e.value.map(project => project?.name || project)
        const parentNames = [...new Set(
          projects
            .filter(project =>
              project.items.some(item => selectedNames.includes(item.name))
            )
            .map(project => project.name)
        )]
        const cleanedSelection = selectedNames.filter(name => {
          const parent = projects.find(project => project.name === name)
          if (parent) {
            const hasSelectedChildren = parent.items.some(item => selectedNames.includes(item.name))
            return hasSelectedChildren
          }
          return true
        })
        const finalSelection = [...new Set([...parentNames, ...cleanedSelection])]
        options.filterCallback(finalSelection)
      }}
      optionLabel="name"
      optionValue="name"
      placeholder={!projects || !projects?.length ? 'Sin opciones' : 'Todos'}
      optionGroupLabel='name'
      optionGroupChildren='items'
      optionGroupTemplate={(option) => {
        return (
          <ClientChip
            clientCode={option.code}
            className='w-fit'
          />
        )
      }}
      itemTemplate={(option) => {
        return (
          <div className='flex gap-2'>
            <ProjectChip
              clientCode={option.clientCode}
              projectCode={option.code}
            />
            <span>
              {option.name?.split('-')[1]}
            </span>
          </div>
        )
      }}
      selectedItemTemplate={(option) => {
        if (!option) {
          return 'Todos'
        }
        const project = projects
          .flatMap(client => client.items)
          .find(item => item.name === option)
        const client = projects.find(c => c.name === option)
        if (client) {
          return null
        } else if (project) {
          // console.log('selectedClients', selectedClients)
          // if (!selectedClients.includes(project.clientCode)) {
          //   const aux = [...selectedClients]
          //   console.log('aux', aux.push(project.clientCode))
          //   setSelectedClients([
          //     ...aux,
          //     project.clientCode
          //   ])
          //   return (
          //     <>
          //       <ClientChip
          //         clientCode={project.clientCode}
          //       />
          //       <ProjectChip
          //         clientCode={project.clientCode}
          //         projectCode={project.code}
          //       />
          //     </>
          //   )
          // }
          return (
            <ProjectChip
              clientCode={project.clientCode}
              projectCode={project.code}
            />
          )
        }
        return option
      }}
      maxSelectedLabels={6}
      selectedItemsLabel={getSelectedItemsLabel()}
      disabled={!projects || !projects?.length}
    />
  )
}

export default MultiSelectClientProjectFilter
