import React, { useEffect, useState, useRef } from 'react'
import { DateTime } from 'luxon'
import { getIndividualTasksOfClientId } from '../../../services/report.service'
import useToast from '../../../hooks/useToast.hook'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import ProgressSpinner from '../../../components/atoms/misc/ProgressSpinner.atom'
import ContextMenu from '../../../components/atoms/ContextMenu.atom'
import ModalFormEditTask from '../../../components/compounds/ModalFormEditTask.compound'
import RegisteredClientTaskTable from './tables/RegisteredClientTaskTable/RegisteredClientTaskTable.controller'
import ClientPicker from './components/ClientPicker'
import ResetButton from './components/ResetButton'
import useMountedState from '../../../hooks/useMountedState'
import { useNavigate } from 'react-router-dom'
import CompoundRangeDatePicker from '../../../components/compounds/CompoundRangeDatePicker.compound'
import { thisWeekRange } from '../../../utils/date.utils'
import { RANGES } from '../../../consts/CompoundRangeDatePicker'

const INITIAL_SELECTED_CLIENT = null

const HoursByClientReport = React.forwardRef((props, ref) => {
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { setErrorMessage } = useToast()
  const [editTaskVisible, setEditTaskVisible] = useState(false)
  const [updatingTask, setUpdatingTask] = useState(null)
  const {
    tasksByClientClients,
    tasksByClientSelectedClient,
    setTasksByClientSelectedClient,
    tasksByClientPeriod,
    setTasksByClientPeriod,
    tasksByClientDateRange,
    setTasksByClientDateRange
  } = useReportFiltersContext()

  const isMounted = useMountedState()
  const navigate = useNavigate()

  //* Request identifier ref,
  //* to allow ignoring stale responses / prevent race conditions
  const currentRequestId = useRef(null)

  useEffect(() => {
    setUpdatingTask(null)
    setLoading(true)

    gettingIndividualTasks(true)
      .catch(() => {
        setLoading(false)
      })
  }, [tasksByClientDateRange, tasksByClientSelectedClient, tasksByClientPeriod])

  useEffect(() => {
    if (error) {
      setErrorMessage({ message: error })
    }
  }, [error])

  const gettingIndividualTasks = async (globalLoad = false) => {
    if (globalLoad) {
      setLoading(true)
    }

    let response = {}
    if (tasksByClientSelectedClient) {
      const [startDate, endDate] = tasksByClientDateRange
      //* We generate & assign this promise's ID
      const requestId = DateTime.now().toISO()
      currentRequestId.current = requestId

      const resp = await getIndividualTasksOfClientId(tasksByClientSelectedClient, startDate, endDate)
      if (!isMounted()) return

      // ? could be done with an abort controller
      //* We check if this response is still relevant...
      if (currentRequestId.current !== requestId) {
        return //* ...and we ignore it if it's stale
      }
      response = resp
    }
    if (response.success) {
      setTasks(response.result)
    } else {
      setError(response.message)
    }
    if (globalLoad) {
      setLoading(false)
    }
  }

  const cm = useRef(null)
  const cmItems = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: async () => {
        setEditTaskVisible(true)
      }
    }, {
      label: 'Historial de cambios',
      icon: 'pi pi-history',
      command: () => {
        navigate('/historial-tarea', {
          state: { taskId: selectedTask?.taskId }
        })
      }
    }
  ]
  const [cmItemsState, setCmItemsState] = useState(cmItems)
  useEffect(() => {
    if (!selectedTask) {
      setCmItemsState([])
    }
    if (!selectedTask?.hasChangelog) {
      setCmItemsState(cmItems.slice(0, 1))
    } else {
      setCmItemsState(cmItems)
    }
  }, [selectedTask])

  const controls =
    <div className='flex justify-content-between align-items-center pb-3 '>
      <div className="mr-0 ml-1 flex flex-1 align-items-center gap-3">
        {tasksByClientClients.length > 1
          ? <ClientPicker
            clients={tasksByClientClients}
            selectedClient={tasksByClientSelectedClient}
            setSelectedClient={setTasksByClientSelectedClient}
          />
          : null
        }
        <CompoundRangeDatePicker
          dateRange={tasksByClientDateRange}
          setDateRange={setTasksByClientDateRange}
          period={tasksByClientPeriod}
          setPeriod={setTasksByClientPeriod}
        />
      </div>
      {props.downloadCsvButton(tasksByClientSelectedClient === null)}
      <ResetButton onClick={() => {
        setTasksByClientSelectedClient(INITIAL_SELECTED_CLIENT)
        ref.current?.reset()
        setTasksByClientPeriod(RANGES.WEEK)
        setTasksByClientDateRange(thisWeekRange())
        setTasks([])
      }} />
    </div>

  if (loading) {
    return (
      <>
        {controls}
        <div className='w-full h-20rem flex align-items-center justify-content-center'>
          <ProgressSpinner />
        </div>
      </>
    )
  }

  return (
    <>
      {controls}
      <RegisteredClientTaskTable
        ref={ref}
        data={tasks}
        cm={cm}
        setSelectedTask={setSelectedTask}
        updatingTask={updatingTask}
        isDailyPeriod={tasksByClientPeriod === RANGES.DAY}
        period={tasksByClientPeriod}
        dateRange={tasksByClientDateRange}
        selectedClient={tasksByClientSelectedClient}
      />
      <ContextMenu ref={cm} model={cmItemsState} />
      <ModalFormEditTask
        visible={editTaskVisible}
        setVisible={setEditTaskVisible}
        task={selectedTask}
        teamMemberId={null}
        onSubmit={() => {
          setUpdatingTask(selectedTask)
        }}
        onEditCompleted={(errorMessage) => {
          if (errorMessage) {
            setError(errorMessage)
          }
          gettingIndividualTasks()
          setEditTaskVisible(false)
          setUpdatingTask(null)
        }}
        onlyActiveProjects={false}
        isAdminEdit
        waitForResponse={false}
      />
    </>
  )
})

export default HoursByClientReport
