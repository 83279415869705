import React from 'react'
import Chip from '../atoms/misc/Chip.atom'
import { floatToPercentage } from '../../utils/numbers.utils'

const OccupationPercentageChip = ({ percentageValue }) => {
  const getPercentageColor = (percentage) => {
    if (percentage >= 80) {
      return '#00B4FF'
    } else if (percentage < 80 && percentage >= 70) {
      return '#2CA949'
    } else if (percentage < 70 && percentage >= 60) {
      return '#F97316'
    } else if (percentage < 60) {
      return '#FC3D39'
    }
  }

  return (
    <Chip
      className={'ocupation-chip text-white'}
      style={{ backgroundColor: `${getPercentageColor(percentageValue)}` }}
      template={
        <>
          <span className={'font-bold'} style={{ fontSize: '12px', padding: '3.5px 7px' }}>{percentageValue}%</span>
        </>
      }
    />
  )
}

export default OccupationPercentageChip
