import React, { useRef, useState } from 'react'
import IndividualReportContent from './controllers/IndividualReportContent.controller'
import IndividualReportHeader from './controllers/IndividualReportHeader.controller'

const IndividualReport = () => {
  const [openCreateTaskForTeamMemberModal, setOpenCreateTaskForTeamMemberModal] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const registeredTasksRef = useRef(null)

  return (
    <>
      <IndividualReportHeader
        currentTab={currentTab}
        openCreateTaskForTeamMemberModal={openCreateTaskForTeamMemberModal}
        setOpenCreateTaskForTeamMemberModal={setOpenCreateTaskForTeamMemberModal}
        registeredTasksRef={registeredTasksRef}
      />
      <IndividualReportContent
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        openCreateTaskForTeamMemberModal={openCreateTaskForTeamMemberModal}
        setOpenCreateTaskForTeamMemberModal={setOpenCreateTaskForTeamMemberModal}
        registeredTasksRef={registeredTasksRef}
      />
    </>
  )
}

export default IndividualReport
