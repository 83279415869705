import React from 'react'
import Badge from '../atoms/Badge.atom'
import Tooltip from '../atoms/Tooltip.atom'
import { generateRandomNumber } from '../../utils/numbers.utils'

const BadgeWithTooltip = ({ value, tooltipValue = '', color, position = undefined, esFisico = false }) => {
  const colorClasses = esFisico ? `small-badge-invFisico small-badge-invFisico--${color || 'htg'}` : `small-badge small-badge--${color || 'htg'}`
  const id = generateRandomNumber()
  const defaultPosition = 'right'
  return (
    <>
      <Tooltip target={`.asignacion-data-${value?.replace(/\+/g, '').toLowerCase().split(' ').join('-')}-${id}`} position={position || defaultPosition}/>
      <Badge
        className={`asignacion-data-${value?.replace(/\+/g, '').toLowerCase().split(' ').join('-')}-${id} ${colorClasses}`}
        data-pr-tooltip={tooltipValue}
        value={value}
      />
    </>

  )
}

export default BadgeWithTooltip
