export const formatDropdownProjects = (projects) => {
  const sisuIntOptions = [...projects.filter(option => option?.clientTagName === 'INT')]
  let restOptions = projects.filter(option => option?.clientTagName !== 'INT')
  restOptions = restOptions.sort((a, b) => {
    if (a.clientTagName < b.clientTagName) return -1
    if (a.clientTagName > b.clientTagName) return 1
    if (a.projectTagName < b.projectTagName) return -1
    if (a.projectTagName > b.projectTagName) return 1
    return 0
  })
  return [...sisuIntOptions, ...restOptions]
}
