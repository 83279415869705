import React, { useEffect, useState } from 'react'
import ModalForm from './modalForm/ModalForm.compunds'
import FormContainer from '../layouts/containers/FormContainer.container'
import InputTextForm from '../molecules/InputTextForm.molecule'
import FieldContainer from '../layouts/containers/FieldContainer.container'
import {
  checkAllFields,
  fieldsHaveErrors,
  isANumberGreaterThanZero,
  isNotEmpty,
  validateFieldValue
} from '../../utils/validations.utils'
import DropdownInput from '../molecules/DropdownInput.molecule'
import { getProjectCategories } from '../../services/category.service'
import { getProjectModules } from '../../services/module.service'
import { getActiveProjects, getAllProjects, getTeamMemberActiveProjects } from '../../services/project.service'
import useSessionContext from '../../hooks/contextConsumers/useSessionContext.hook'
import InputTime from '../molecules/InputTime.molecule'
import InputSwitchForm from '../molecules/InputSwitchForm.molecule'
import { editTask } from '../../services/task.service'
import DropdownWithTitleSubtitle from '../molecules/DropdownWithTitleSubtitle.molecule'
import { formatDropdownProjects } from '../../utils/project.utils'
import DropdownProjectFilter from './DropdownProjectFilter.compound'
import Label from '../atoms/Label.atom'
import DropdownCustomFilterSearch from '../molecules/DropdownCustomFilterSearch.molecule'
import DropdownCategoryFilter from './DropdownCategoryFilter.compound'

const EditTask = ({
  visible,
  setVisible,
  task,
  teamMemberId = null,
  onSubmit,
  onEditCompleted = () => { },
  onlyActiveProjects = false,
  onlyFavoriteProjects = false,
  isAdminEdit = false,
  waitForResponse = true
}) => {
  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [modules, setModules] = useState([])
  const [adjustTime, setAdjustTime] = useState(false)
  const [hasSpecialCategory, setHasSpecialCategory] = useState(false)
  const [data, setData] = useState({
    name: task?.task || '',
    comment: task?.comment || '',
    categoryId: task?.specialCategoryId || task?.categoryId || '',
    clientCode: task?.clientCode || '',
    projectId: task?.projectId || '',
    moduleId: task?.moduleId || '',
    sisuTime: task?.sisuTime || '',
    billedTime: task?.billedTime || '',
    adjustTimeReason: task?.timeDifferenceComment || ''
  })

  useEffect(() => {
    setData({
      name: task?.task || '',
      comment: task?.comment || '',
      categoryId: task?.specialCategoryId || task?.categoryId || '',
      clientCode: task?.clientCode || '',
      projectId: task?.projectId || '',
      moduleId: task?.moduleId || '',
      sisuTime: task?.sisuTime || '',
      billedTime: task?.billedTime || '',
      adjustTimeReason: task?.timeDifferenceComment || ''
    })
    setHasSpecialCategory(task?.specialCategoryId)
  }, [task])

  const initialFieldsState = {
    name: {
      value: task?.task || '',
      errors: [],
      typeValidations: [isNotEmpty],
      validationsOptions: {}
    },
    comment: {
      value: task?.comment || '',
      errors: [],
      typeValidations: [],
      validationsOptions: {}
    },
    categoryId: {
      value: task?.specialCategoryId || task?.categoryId || '',
      errors: [],
      typeValidations: [isNotEmpty],
      validationsOptions: {}
    },
    projectId: {
      value: task?.projectId || '',
      errors: [],
      typeValidations: [isNotEmpty],
      validationsOptions: {}
    },
    moduleId: {
      value: task?.moduleId || '',
      errors: [],
      typeValidations: [],
      validationsOptions: {}
    },
    sisuTime: {
      value: task?.sisuTime || '',
      errors: [],
      typeValidations: [isANumberGreaterThanZero],
      validationsOptions: {}
    },
    billedTime: {
      value: task?.billedTime || '',
      errors: [],
      typeValidations: [],
      validationsOptions: {}
    },
    adjustTimeReason: {
      value: task?.timeDifferenceComment || '',
      errors: [],
      typeValidations: [],
      validationsOptions: {}
    }
  }
  const [validations, setValidations] = useState(initialFieldsState)
  const { user } = useSessionContext()
  function handleChange ({ target: { name, value } }) {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
  }

  async function handleSubmit () {
    if (onSubmit && !waitForResponse) {
      onSubmit()
    }
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }
    if (adjustTime && data.sisuTime === data.billedTime) {
      return {
        success: false,
        message: 'Si ajustas las horas cliente, estas deben ser diferentes a las horas trabajadas'
      }
    }
    if (!waitForResponse) {
      setVisible(false)
    }
    try {
      const res = await editTask({
        ...data,
        id: task.taskID,
        date: task.date,
        teamMemberId: teamMemberId || user.id,
        categoryId: hasSpecialCategory ? task?.categoryId : data.categoryId,
        specialCategoryId: hasSpecialCategory ? data.categoryId : null,
        comment: data.comment === '' ? null : data.comment,
        billedTime: !adjustTime ? data.sisuTime : data.billedTime,
        timeDifferenceComment: !adjustTime || data.adjustTimeReason === '' ? null : data.adjustTimeReason,
        moduleId: data.moduleId === '' ? null : data.moduleId,
        isAdminEdit,
        workDayId: task.workDayId
      })

      if (res.success) {
        setVisible(false)
      }
      onEditCompleted(!res.success ? 'Hubo un error al editar la tarea' : null)
      if (onSubmit && waitForResponse) {
        onSubmit()
      }
      return res
    } catch (error) {
      onEditCompleted(error.message)
      return {
        success: false,
        message: error.message
      }
    }
  }
  useEffect(() => {
    gettingProjectsData()
    if (data?.projectId && data?.projectId !== task?.projectId) {
      setValidations(prev => ({
        ...prev,
        categoryId: {
          value: '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        },
        moduleId: {
          value: '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        }
      }))
      setData(prev => ({
        ...prev,
        moduleId: '',
        categoryId: ''
      }))
      gettingCategoriesData()
      gettingModulesData()
    }
  }, [visible, data?.projectId])
  useEffect(() => {
    if (data?.projectId) {
      if (data?.moduleId && task?.projectId !== data?.projectId) {
        setValidations(prev => ({
          ...prev,
          moduleId: {
            value: '',
            errors: [],
            typeValidations: [],
            validationsOptions: {}
          },
          categoryId: {
            value: '',
            errors: [],
            typeValidations: [isNotEmpty],
            validationsOptions: {}
          }
        }))
      }
      gettingCategoriesData()
      gettingModulesData()
    }
  }, [data?.projectId])

  useEffect(() => {
    if (
      !task?.projectId ||
      !task?.categoryId
    ) {
      return
    }
    if (task?.specialCategoryId) {
      setHasSpecialCategory(true)
    } else {
      setHasSpecialCategory(false)
    }
    setData({
      name: task?.task || '',
      comment: task?.comment || '',
      categoryId: task?.specialCategoryId || task?.categoryId || '',
      clientCode: task?.clientCode || '',
      projectId: task?.projectId || '',
      moduleId: task?.moduleId || '',
      sisuTime: task?.sisuTime || 0,
      billedTime: task?.billedTime || 0,
      adjustTimeReason: task?.timeDifferenceComment || ''
    })
    setValidations(initialFieldsState)
    setAdjustTime(task?.sisuTime !== task?.billedTime)
  }, [task])

  useEffect(() => {
    setValidations(prev => ({
      ...prev,
      adjustTimeReason: {
        value: prev.adjustTimeReason.value,
        errors: adjustTime && prev.adjustTimeReason.errors.value ? [prev.adjustTimeReason.errors.value] : [],
        typeValidations: adjustTime ? [isNotEmpty] : [],
        validationsOptions: {}
      }
    }))
  }, [adjustTime])

  const gettingProjectsData = async () => {
    let responseProjects
    if (onlyActiveProjects) {
      responseProjects = await getActiveProjects(user.id)
    } else if (onlyFavoriteProjects) {
      // responseProjects = await getFavoriteActiveProjects(user.id) // Checar si es el comportamiento deseado
      responseProjects = await getTeamMemberActiveProjects(user.id)
    } else {
      responseProjects = await getAllProjects()
    }
    if (responseProjects?.result) {
      const orderedProjects = formatDropdownProjects(responseProjects.result)
      setProjects(orderedProjects)
    }
  }

  const gettingCategoriesData = async () => {
    const responseCategories = await getProjectCategories(data?.projectId)
    setCategories(responseCategories?.result)
    if (responseCategories?.result?.find(c => c.specialCategoryID)) {
      setHasSpecialCategory(true)
    } else {
      setHasSpecialCategory(false)
    }
  }

  const gettingModulesData = async () => {
    const responseModules = await getProjectModules(data?.projectId)
    const modules = responseModules.result
    setModules(modules)
    if (modules && modules.length > 0) {
      setValidations(prev => ({
        ...prev,
        moduleId: {
          value: prev.moduleId.value || '',
          errors: [],
          typeValidations: [isNotEmpty],
          validationsOptions: {}
        }
      }))
    } else {
      setValidations(prev => ({
        ...prev,
        moduleId: {
          value: prev.moduleId.value || '',
          errors: [],
          typeValidations: [],
          validationsOptions: {}
        }
      }))
    }
  }
  const canGroupByName = categories?.findIndex(c => c.categoryGroup && !c.specialCategoryID) !== -1
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={
        <div className="flex gap-3 align-items-center">
          <i className="pi pi-pencil text-4xl" style={{ color: '#2896D8' }} />
          <span className="text-3xl font-semibold">Editar tarea</span>
        </div>
      }
      service={handleSubmit}
      disabledSaveBtn={fieldsHaveErrors(validations)}
    >
      <FormContainer className='edit-task-form'>
        <FieldContainer md={12}>
          <InputTextForm
            label='Nombre'
            name='name'
            placeholder='Nombre de la tarea'
            value={data.name}
            onChange={handleChange}
            error={validations.name.errors}
            onBlur={() => {
              handleChange({ target: { name: 'name', value: data.name?.trim() } })
            }}
          />
        </FieldContainer>
        <FieldContainer md={12}>
          <InputTextForm
            label='Comentario'
            name='comment'
            placeholder='Agrega un comentario'
            value={data.comment}
            onChange={handleChange}
            onBlur={() => {
              handleChange({ target: { name: 'comment', value: data.comment?.trim() } })
            }}
            error={validations.comment.errors}
            optional
          />
        </FieldContainer>
        <FieldContainer md={12}>
          <DropdownCategoryFilter
            label='Categoría'
            key={data.categoryId + hasSpecialCategory}
            options={categories}
            optionLabel={`${hasSpecialCategory ? 'categoryName' : 'categoryTitle'}`}
            optionValue={`${hasSpecialCategory ? 'specialCategoryID' : 'categoryID'}`}
            name="categoryId"
            value={
              categories?.find(c => c?.[hasSpecialCategory ? 'specialCategoryID' : 'categoryID'] === data.categoryId)?.[hasSpecialCategory ? 'specialCategoryID' : 'categoryID']
            }
            onChange={handleChange}
            titleKey={`${hasSpecialCategory ? 'categoryGroup' : 'categoryTitle'}`}
            subtitleKey='categorySubtitle'
            error={validations.categoryId.errors}
            fullWidth
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownProjectFilter
            name='projectId'
            label='Proyecto'
            placeholder="Selecciona un proyecto"
            value={data.projectId}
            onChange={({ target: { value } }) => {
              const { clientTagName } = projects.find(p => p.projectID === value)
              setData(prev => ({
                ...prev,
                clientCode: clientTagName,
                projectId: value
              }))
              if (clientTagName === 'INT') {
                setAdjustTime(false)
              }
            }}
            options={projects}
            error={validations.projectId.errors}
            badgeTemplate
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownInput
            filter
            className='modules-dropdown'
            placeholder="Selecciona un módulo"
            label="Módulo"
            name="moduleId"
            options={modules}
            optionLabel="moduleName"
            optionValue="moduleID"
            value={data.moduleId}
            error={validations.moduleId.errors}
            onChange={handleChange}
            disabled={modules?.length === 0}
            itemTemplate={(option) => {
              return (
                <div className='flex gap-2 align-items-center module-item'>
                  <span className='flex-1'>{option.moduleName}</span>
                </div>
              )
            }}
            emptyMessage='No hay modulos aquí... ¡este cliente es minimalista!'
            filterTemplate={(props) => <DropdownCustomFilterSearch props={props} inputProps={{
              autoFocus: true
            }} />}
          />
        </FieldContainer>
        <FieldContainer md={6} className='flex align-items-center'>
          <InputTime
            className='w-full'
            label='Duración'
            name='sisuTime'
            value={data.sisuTime || 0}
            onChange={({ value }) => {
              handleChange({ target: { name: 'sisuTime', value } })
            }}
            error={validations.sisuTime.errors}
            fullWidth
          />
        </FieldContainer>
        <FieldContainer md={6} className='flex align-items-center m-0 input-switch-adjust-time'>
          {data.clientCode !== 'INT'
            ? <InputSwitchForm
              label='Ajustar horas cliente'
              value={adjustTime}
              onChange={() => setAdjustTime(!adjustTime)}
            />
            : null
          }
        </FieldContainer>
        {
          adjustTime &&
          <>
            <FieldContainer md={6} className='flex align-items-center'>
              <InputTime
                label='Horas cliente'
                name='billedTime'
                value={data.billedTime || 0}
                onChange={({ value }) => {
                  handleChange({ target: { name: 'billedTime', value } })
                }}
                error={validations.billedTime.errors}
                fullWidth
              />
            </FieldContainer>
            <FieldContainer md={6}>
              <InputTextForm
                label='Motivo de la diferencia'
                name='adjustTimeReason'
                placeholder='Agrega una razón'
                value={data.adjustTimeReason}
                onChange={handleChange}
                error={validations.adjustTimeReason.errors}
                onBlur={() => {
                  handleChange({ target: { name: 'adjustTimeReason', value: data.adjustTimeReason?.trim() } })
                }}
              />
            </FieldContainer>
          </>
        }
      </FormContainer>
    </ModalForm>
  )
}

export default EditTask
