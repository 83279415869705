import React from 'react'
import { InputText as PrimeInputText } from 'primereact/inputtext'
/**
 * @param {import("primereact/inputtext").InputTextProps} props
 * @returns
 */
const InputText = React.forwardRef(
  /**
   * @param {import("primereact/inputtext").inputtext} props
   * @param {React.Ref<HTMLDivElement>} ref
   * @returns {JSX.Element}
   */
  (props, ref) => {
    return (
      <PrimeInputText
        ref={ref}
        {...props}
      />
    )
  }
)

export default InputText
