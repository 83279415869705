import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

export const VALIDATE_DATE_RANGE = PropTypes.shape({
  startDate: (props, propName, componentName) => {
    if (!(props[propName] instanceof DateTime)) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Expected an instance of DateTime.`
      )
    }
  },
  endDate: (props, propName, componentName) => {
    if (!(props[propName] instanceof DateTime)) {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Expected an instance of DateTime.`
      )
    }
  }
})
