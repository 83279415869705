import React from 'react'
import ModalForm from '../../../components/compounds/modalForm/ModalForm.compunds'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import SecondaryButton from '../../../components/molecules/buttons/SecondaryButton.molecule'
import { deactivateClient, deleteClient } from '../../../services/client.service'
import { deactivateProject, deleteProject } from '../../../services/project.service'

const MODAL_MESSAGES = {
  DEACTIVATE_CLIENT: {
    title: 'Desactivar cliente',
    description: 'La información relacionada con este cliente, se mantendrá sin modificaciones. Sin embargo, no estará disponible para futuros registros',
    service: deactivateClient
  },
  DEACTIVATE_PROJECT: {
    title: 'Desactivar proyecto',
    description: 'La información relacionada con este proyecto, se mantendrá sin modificaciones. Sin embargo, no estará disponible para futuros registros',
    service: deactivateProject
  },
  DELETE_CLIENT: {
    title: 'Eliminar cliente',
    description: 'Si eliminas este cliente, se eliminarán los proyectos y módulos relacionados con él. Esta acción es irreversible ¿Estás seguro de eliminarlo?',
    service: deleteClient
  },
  DELETE_PROJECT: {
    title: 'Eliminar proyecto',
    description: 'Si eliminas este proyecto, se eliminarán los módulos relacionados con él. Esta acción es irreversible ¿Estás seguro de eliminarlo?',
    service: deleteProject
  }
}

const ActionModal = ({
  row,
  visible,
  setVisible,
  modalAction,
  onFinishedSubmit
}) => {
  let isClient = false
  let isDeactivate = false
  if (row?.children) {
    isClient = true
  }
  if (modalAction === 'deactivate') {
    isDeactivate = true
  }

  const getModalMessage = () => {
    if (isDeactivate) {
      return isClient ? MODAL_MESSAGES.DEACTIVATE_CLIENT : MODAL_MESSAGES.DEACTIVATE_PROJECT
    }
    return isClient ? MODAL_MESSAGES.DELETE_CLIENT : MODAL_MESSAGES.DELETE_PROJECT
  }

  const modalContent = getModalMessage()

  const handleSubmit = async () => {
    const response = await modalContent.service(row.id)
    if (response.success) {
      setVisible(false)
    }
    onFinishedSubmit()
  }

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerIconClass='pi pi-info-circle'
      headerTitle={modalContent?.title}
      footer={
        <div>
          <SecondaryButton rounded label='Cancelar' type='text' onClick={() => setVisible(false)} />
          <PrimaryButton rounded icon='pi pi-check' label={'Aceptar'} onClick={handleSubmit}/>
        </div>
      }
      service={handleSubmit}
    >
      <span>
        {modalContent?.description}
      </span>
    </ModalForm>
  )
}

export default ActionModal
