import { calcPorcentaje } from './calc/generalCalcs.calc'

export const getColor = (index, colors = []) => {
  if (index < colors.length) {
    return colors[index]
  } else {
    index = index - colors.length
    return getColor(index, colors)
  }
}

export const getUniqueResourcesFromData = (data, keyOfData = '') => {
  if (!data) return []
  const uniqueResources = new Set()
  data.forEach(record => {
    if (!record?.[keyOfData]) return
    Object.keys(record?.[keyOfData]).forEach((resourceKey) => {
      uniqueResources.add(resourceKey)
    })
  })
  return [...uniqueResources]
}

export const getDataRechartsFromData = (data = [], keyOfData = '') => {
  if (!data) return []
  return data.map(record => {
    return { ...record, ...record?.[keyOfData], [keyOfData]: null }
  })
}

export const getBrushStartIndex = (data, itemsSize) => {
  if (data?.length <= itemsSize) return 0
  return data?.length - itemsSize
}

export const getRadiusOfPie = (countOfAllPies, indexPie, onePie) => {
  // size of pie when less than 4 pies 100
  // size of pie of 4 pies 72.5
  // size of pie of 5 pies 60

  // size of gap of every pie when 4 and less pies 10
  // size of gap of every pie when 5 pies 5

  // const indexedPiesRadius = {
  //   1: {
  //     0: { innerRadius: 220, outerRadius: 320 }
  //   },
  //   2: {
  //     0: { innerRadius: 220, outerRadius: 320 },
  //     1: { innerRadius: 110, outerRadius: 210 }
  //   },
  //   3: {
  //     0: { innerRadius: 220, outerRadius: 320 },
  //     1: { innerRadius: 110, outerRadius: 210 },
  //     2: { innerRadius: 0, outerRadius: 100 }
  //   },
  //   4: {
  //     0: { innerRadius: 247.5, outerRadius: 320 },
  //     1: { innerRadius: 165, outerRadius: 237.5 },
  //     2: { innerRadius: 82.5, outerRadius: 155 },
  //     3: { innerRadius: 0, outerRadius: 72.5 }
  //   },
  //   5: {
  //     0: { innerRadius: 260, outerRadius: 320 },
  //     1: { innerRadius: 195, outerRadius: 255 },
  //     2: { innerRadius: 130, outerRadius: 190 },
  //     3: { innerRadius: 65, outerRadius: 125 },
  //     4: { innerRadius: 0, outerRadius: 60 }
  //   }
  // }
  const indexedPiesRadius = {
    1: {
      0: { innerRadius: '62%', outerRadius: '100%' } // 220/320 y 320/320
    },
    2: {
      0: { innerRadius: '68.75%', outerRadius: '100%' },
      1: { innerRadius: '34.38%', outerRadius: '65.63%' } // 110/320 y 210/320
    },
    3: {
      0: { innerRadius: '68.75%', outerRadius: '100%' },
      1: { innerRadius: '34.38%', outerRadius: '65.63%' },
      2: { innerRadius: '0%', outerRadius: '31.25%' } // 0/320 y 100/320
    },
    4: {
      0: { innerRadius: '77.34%', outerRadius: '100%' }, // 247.5/320 y 320/320
      1: { innerRadius: '51.56%', outerRadius: '74.22%' }, // 165/320 y 237.5/320
      2: { innerRadius: '25.78%', outerRadius: '48.44%' }, // 82.5/320 y 155/320
      3: { innerRadius: '0%', outerRadius: '22.66%' } // 0/320 y 72.5/320
    },
    5: {
      0: { innerRadius: '81.25%', outerRadius: '100%' }, // 260/320 y 320/320
      1: { innerRadius: '60.94%', outerRadius: '79.69%' }, // 195/320 y 255/320
      2: { innerRadius: '40.63%', outerRadius: '59.38%' }, // 130/320 y 190/320
      3: { innerRadius: '20.31%', outerRadius: '39.06%' }, // 65/320 y 125/320
      4: { innerRadius: '0%', outerRadius: '18.75%' } // 0/320 y 60/320
    }
  }
  const countOfAllPiesToUse = countOfAllPies > 5 ? 5 : countOfAllPies
  return onePie ? { innerRadius: 0, outerRadius: 320 } : indexedPiesRadius?.[countOfAllPiesToUse]?.[indexPie]
}

export const getXAndYAxisLabelPieChart = ({ cx, cy, innerRadius, outerRadius, midAngle, countOfAllPies, index }) => {
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * (((countOfAllPies - 1) === index) ? 0.5 : 0.4)
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)
  return { x, y }
}

export const getComposedDataRechartsFromData = (data = []) => {
  if (!data) return []
  return data.map(record => {
    return {
      ...record,
      ...record?.lineData,
      ...record?.areaData,
      ...record?.barData,
      lineData: null,
      areaData: null,
      barData: null
    }
  })
}

export function * getIndexOfColor (index, limit = 10) {
  while (index < limit) {
    yield index
    index++
  }
}

const calcValueOfPorcentaje = (total, porcentaje) => {
  if (!total) return 0
  if (!porcentaje) return 0
  return (total * porcentaje) / 100
}

export const getDataOfStackPorcentageType = (data = []) => {
  return data.map(record => {
    const oldExtraInfo = record?.extraInfo || {}
    const newRecord = {
      ...record,
      barData: {},
      extraInfo: {
        ...oldExtraInfo,
        originData: record.barData
      }
    }
    let totalOfDataRecord = 0
    const barDataValues = Object.values(record.barData)
    barDataValues.forEach((value, index) => {
      const isTheLastItem = index === barDataValues.length - 1
      const offset = isTheLastItem
        ? calcValueOfPorcentaje(
          totalOfDataRecord + Number(value),
          0.001
        )
        : 0
      totalOfDataRecord += Number(value) + offset
    })
    Object.entries(record.barData).forEach(([key, value]) => {
      newRecord.barData[key] = calcPorcentaje(totalOfDataRecord, value)
    })
    return newRecord
  })
}

export const getMinAndMaxValueOfData = (data = [], keyOfData = '', config) => {
  const minGap = config?.minGap || 0
  const maxGap = config?.maxGap || 0
  const allValues = []

  data.forEach((dataItem) => {
    if (!dataItem?.[keyOfData]) return
    const values = Object.values(dataItem?.[keyOfData])
    allValues.push(...values)
  })

  const max = Math.max(...allValues)
  const min = Math.min(...allValues)
  return { min: min - minGap, max: max + maxGap }
}

export const thereAreSomeData = (data = [], chart = 'barData') => {
  return data.some((record) => Object.keys(record?.[chart]).length > 0)
}

export const getDataOfBubbleIndexed = (data = [], resources, dataKey) => {
  const dataIndexedByBubbleData = data.reduce((acc, record) => {
    resources.forEach(bubbleDataKey => {
      const extraInfo = record?.extraInfo?.[bubbleDataKey] || null
      const index = 1
      const dataKeyValue = record[dataKey]
      const value = record?.bubbleData?.[bubbleDataKey] || 0
      if (!acc[bubbleDataKey]) {
        acc[bubbleDataKey] = [{
          [dataKey]: dataKeyValue,
          name: bubbleDataKey,
          index,
          value,
          extraInfo
        }]
      } else {
        acc[bubbleDataKey].push({
          [dataKey]: dataKeyValue,
          name: bubbleDataKey,
          index,
          value,
          extraInfo
        })
      }
    })
    return acc
  }, {})
  return dataIndexedByBubbleData
}

export const getCorrectLabelBubbleChart = (label, length) => {
  if (label.length > 9 && length > 9) return `${label.substring(0, 9)}...`
  return label
}
