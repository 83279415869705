/* eslint-disable */
import React from 'react'
import TeamChip from '../../../../../components/molecules/TeamChip.molecule'

const teamMemberNameTemplate = (rowData) => {
  if (rowData.data.code !== undefined) {
    return (
      <div className='flex align-items-center'>
        <TeamChip teamTag={rowData.data.code} />
      </div>
    )
  } else if (rowData.data.teamMemberID !== undefined) {
    return (
      <div className='flex align-items-center'>
        <span className='white-space-nowrap px-2'>{rowData.data.name}</span>
      </div>
    )
  } else {
    return (
      <div className='flex align-items-center'>
        <span className='white-space-nowrap px-2'>N/A</span>
      </div>
    )
  }
}

const billedSisuTimeTemplate = (rowData) => {
  if (rowData.data.billedTime) {
    return (
      <span className='flex align-items-center gap-1'>
        {rowData.data.billedTime}
      </span>
    )
  } else {
    return (
      <span className='flex align-items-center gap-1'>
        0
      </span>
    )
  }
}

const summaryTemplate = (rowData) => {
  if (rowData.data.averageTime) {
    return (
      <span className='flex align-items-center gap-1'>
        {rowData.data.averageTime}
      </span>
    )
  } else {
    return (
      <span className='flex align-items-center gap-1'>
        0
      </span>
    )
  }
}

const ColumnContent = (
  rangeToConsider
) => {
  return [
    // Team member column
    {
      body: teamMemberNameTemplate,
      style: { padding: '7px 7px 7px 0px' },
      field: `team`,
      header: 'Equipos',
    },
    // Period columns
    ...(rangeToConsider.map((rangePeriod) => {
      return {
        key: rangePeriod,
        field: `rangeData.${rangePeriod}.sisuTime`,
        header: `${rangePeriod}`,
        style: { padding: '7px 7px 7px 0px' },
        body: (rowData) => {
          if (rowData.data.rangeData[`${rangePeriod}`].sisuTime) {
            return (
              <span className='flex align-items-center gap-1'>
                {rowData.data.rangeData[`${rangePeriod}`].sisuTime}
              </span>
            )
          } else {
            return (
              <span className='flex align-items-center gap-1'>
                0
              </span>
            )
          }
        }
      }
    })),
    {
      body: billedSisuTimeTemplate,
      style: { padding: '7px 7px 7px 0px' },
      field: `billedTime`,
      header: 'Totales'
    },
    {
      body: summaryTemplate,
      style: { padding: '7px 7px 7px 0px' },
      field: 'averageTime',
      header: 'Promedio'
    }
  ]
}

export default ColumnContent
