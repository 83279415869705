import React from 'react'
import Tooltip from '../../atoms/Tooltip.atom'

const ClientSubtitleWithBadge = ({ cliente, withTooltip = false }) => {
  return (
    <>
      {withTooltip && <Tooltip target={`.client-badge--${cliente.nombre.toLowerCase().split(' ').join('-')}`} position='bottom'>
        {cliente.nombre}
      </Tooltip>}
      <div className='flex gap-2 align-items-center'>
        <img src={cliente.imagen} alt={cliente.nombre} className={`client-badge client-badge--${cliente.nombre?.toLowerCase().split(' ').join('-')}`}/>
        {!withTooltip && <span className='text-2xl'>{cliente.nombre}</span>}
      </div>
    </>
  )
}

export default ClientSubtitleWithBadge
