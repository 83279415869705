import React from 'react'

const DifferenceReasonTable = ({
  header,
  body,
  total
}) => {
  return (
    <table style={{ borderCollapse: 'collapse' }}>
      {
        body?.length
          ? (
            <>
              <thead>
                <tr>
                  {
                    header?.map((item, index) => {
                      const isLastItem = index === header.length - 1
                      return (
                        <th key={index} style={{ paddingBottom: 10 }} className={`border-bottom-1 border-white white-space-nowrap text-left font-normal ${!isLastItem ? 'pr-2' : ''}`}>{item}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  body?.map((row, index) => {
                    return (
                      <tr key={index}>
                        {
                          Object.keys(row)?.map((item, index) => {
                            return (
                              <td key={index} style={{ paddingTop: 10, paddingBottom: 10 }} className='pr-2 border-bottom-1 border-white'>{row[item]}</td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </>
          )
          : (
            <tbody>
              <tr>
                <td className='text-center' colSpan={header?.length}>No hay comentarios</td>
              </tr>
            </tbody>
          )
      }
      {body?.length > 0 &&
        <tfoot>
          <tr>
            <td className='py-2 pr-2'>Total</td>
            <td className='py-2 pr-2'>
              {total}
            </td>
          </tr>
        </tfoot>
      }
    </table>
  )
}

export default DifferenceReasonTable
