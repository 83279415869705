import React from 'react'
import FormHeaderUsuarios from './controllers/FormHeaderUsuarios.controller'
import FormSectionUsuarios from './controllers/FormSectionUsuarios.controller'

const FormUsuarios = () => {
  return (
    <>
      <FormHeaderUsuarios/>
      <FormSectionUsuarios/>
    </>
  )
}

export default FormUsuarios
