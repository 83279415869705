import React, { useState } from 'react'
import Column from '../../../../../components/atoms/table/Column.atom'
import ColumnGroup from '../../../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../../../components/atoms/table/RowColumnGroup.atom'
import { CustomTreeTable as TreeTable } from '../../../../../components/atoms/table/CustomTreeTable.atom'
import { useSession } from '../../../../../hooks/auth/useSession.hook'
import { roundReportData } from '../../../../../utils/report.utils'
import { CLIENT_CHIP_COLUMN_PROPS } from '../../../../../consts/tables.consts'
import TextIconWithTooltip from '../../../../../components/molecules/TextIconWithTooltip.molecule'
import { floatToPercentage, formatDecimals, isNumber } from '../../../../../utils/numbers.utils'
import { TimeDifferenceBadge } from '../../components/CellReportBadges'
import MultiSelectClientProjectFilter from '../../../../../components/compounds/MultiSelectClientProjectFilter.compound'
import { sortParentsWithStickyChildren } from '../../../../../utils/treetable.utils'
import DifferenceReasonTable from '../../components/DifferenceReasonTable'
import listDetailsIcon from '../../../../../design/assests/images/icons/list_details.svg'
import Modal from '../../../../../components/atoms/Modal.atom'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import ClientChip from '../../../../../components/molecules/ClientChip.molecule'
import ProjectChip from '../../../../../components/molecules/ProjectChip.molecule'
import ClientProjectChip from '../../../../../components/molecules/ClientProjectChip.molecule'

function TimeDifferenceModal ({ modalData, onClose, isOpen }) {
  if (!modalData) return null
  const { fullName, total, timeDifferences, clientCode, hasMoreThanOneProject } = modalData
  let isRoot = modalData.isRoot
  let groupedHeaderTimeDifferences = timeDifferences

  if (isRoot) {
    if (hasMoreThanOneProject) {
      groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
        const [, projectName] = el.projectName.split(' - ')
        return ({ ...el, key: projectName })
      })
    } else {
      groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
        return ({ ...el, key: el.teamMemberName })
      })
      isRoot = false
    }
  } else {
    groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
      return ({ ...el, key: el.teamMemberName })
    })
  }

  groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.sort((a, b) => a.teamMemberName.localeCompare(b.teamMemberName))

  return (
    <Modal
      visible={isOpen}
      draggable={false}
      onHide={onClose}
      header={
        <div className='flex gap-3'>
          <img style={{ width: 32 }} src={listDetailsIcon} alt='Detalles' />
          <div className='flex flex-column'>
            <div style={{ fontSize: 24 }}>
              Detalles de horas perdonadas
            </div>
            <div className='flex gap-2 align-items-center mt-2'>
              {isRoot
                ? <>
                  <ClientChip clientCode={clientCode} />
                  <p style={{ fontSize: 14, fontWeight: 700 }}>
                    {fullName}
                  </p>
                </>
                : (
                  <ClientProjectChip clientCode={clientCode} projectCode={timeDifferences[0].projectCode} />
                )}
            </div>
          </div>
        </div>
      }
      className='rendimiento-por-proyectos-report-hours-details-modal w-full'
      contentClassName='flex'
      dismissableMask
    >
      <div className='w-full flex-1 overflow-auto'>
        <DataTable
          scrollable scrollHeight='flex'
          value={groupedHeaderTimeDifferences}
          rowGroupMode="subheader"
          groupRowsBy="key"
          sortField="key"
          sortOrder={1}
          rowGroupHeaderTemplate={(data) => {
            return (
              <div className='flex align-items-center w-full gap-2'>
                {isRoot && <ProjectChip projectCode={data.projectCode} clientCode={clientCode} />}
                <strong>{data.key}</strong>
              </div>
            )
          }}
        >
          {isRoot && <Column field="teamMemberName" footer="Total" header="Integrante"/>}
          <Column field="comment" footer={!isRoot ? 'Total' : ''} header="Motivo"/>
          <Column style={{ maxWidth: '135px' }} field="time" footer={total} header="Horas"/>
        </DataTable>
      </div>
    </Modal>
  )
}

const calculateTotals = (report) => {
  const totals = report.reduce((acc, client) => {
    client?.children?.forEach((project) => {
      acc.distributionSisuTime += Number(project.data.distributionSisuTime)
      acc.distributionBilledTime += Number(project.data.distributionBilledTime) || 0
      acc.sisuTime += Number(project.data.sisuTime)
      acc.timeDifference += Number(project.data.timeDifference) || 0
      if (project.data.clientId !== 1) {
        acc.billedTime += Number(project.data.billedTime)
        acc.timePerformance += project.data.timePerformance
        acc.totalPerformanceElements++
      }
    })
    return acc
  }, {
    distributionSisuTime: 0,
    distributionBilledTime: 0,
    sisuTime: 0,
    billedTime: 0,
    timeDifference: 0,
    timePerformance: 0,
    totalPerformanceElements: 0,
    timeDifferencePercentage: 0
  })
  totals.timePerformance = totals.timePerformance === 0 ? '-' : totals.timePerformance / totals.totalPerformanceElements
  return totals
}

const HoursByProjectReportDataTable = React.forwardRef(({
  reportData: originalData,
  expandedKeys,
  setExpandedKeys
}, ref) => {
  // const [expandedKeys, setExpandedKeys] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [filters, setFilters] = useState({
    'data.name': { value: null, matchMode: 'in' }
  })
  const reportData = roundReportData(originalData)
  const resetFilters = () => {
    collapseAll()
    setFilters({
      'data.name': { value: null, matchMode: 'in' }
    })
    setFilteredData([])
  }
  const auth = useSession()
  const newRef = React.useRef()
  const [modalData, setModalData] = useState(null)
  const [isTimeDiffDetailsModalOpen, setIsTimeDiffDetailsModalOpen] = useState(false)

  React.useImperativeHandle(ref, () => ({
    ...newRef.current,
    reset () {
      collapseAll()
      setFilters({
        'data.name': { value: null, matchMode: 'in' }
      })
      setFilteredData([])
      newRef.current.reset()
    }
  }), [])

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Cliente/Proyecto"
          rowSpan={3}
          colSpan={2}
          field='data.name'
          filter
          filterField='data.name'
          filterElement={(options) =>
            <MultiSelectClientProjectFilter
              key={reportData.data.length}
              options={options}
              data={reportData.data}
            />
          }
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          filterMatchMode={'in'}
          onFilterApplyClick={(e) => {
            if (!e.constraints?.value) {
              collapseAll()
            } else {
              expandAll()
              setFilteredData(e.constraints?.value)
              setFilters({
                'data.name': { value: e.constraints?.value, matchMode: 'in' }
              })
            }
          }}
          onFilterClear={resetFilters}
        />
      </Row>
      <Row>
        <Column header="Distribución de horas" colSpan={2} rowSpan={1} />
        <Column header="Horas totales" colSpan={3} rowSpan={1} />
        <Column
          header={
            <TextIconWithTooltip
              content='Rendimiento'
              tooltipValue='Porcentaje de horas facturadas respecto al total de horas trabajadas'
            />
          }
          rowSpan={2}
          sortField='data.timePerformance'
          removableSort
        />
      </Row>
      <Row>
        <Column
          header="Trabajadas"
          colSpan={1}
          rowSpan={1}
          sortable
          sortField='data.distributionSisuTime'
          removableSort
        />
        <Column
          header="Facturadas"
          colSpan={1}
          rowSpan={1}
          sortable
          sortField='data.distributionBilledTime'
          removableSort
        />
        <Column
          header="Trabajadas"
          rowSpan={1}
          sortable
          sortField='data.sisuTime'
          removableSort
        />
        <Column
          header="Facturadas"
          rowSpan={1}
          sortable
          sortField='data.billedTime'
          removableSort
        />
        <Column
          header="Diferencia"
          rowSpan={1}
          sortable
          sortField='data.timeDifference'
          removableSort
        />
      </Row>
    </ColumnGroup>
  )

  const footerGroup = () => {
    reportData.totals = calculateTotals(filteredData?.length > 0 ? filteredData : reportData.data)
    return (
      <ColumnGroup key={reportData.totals}>
        <Row>
          <Column footer="Totales" colSpan={2} />
          <Column footer={floatToPercentage(reportData?.totals?.distributionSisuTime)} />
          <Column footer={floatToPercentage(reportData?.totals?.distributionBilledTime)} />
          <Column footer={formatDecimals(reportData?.totals?.sisuTime)} />
          <Column footer={formatDecimals(reportData?.totals?.billedTime?.toFixed(2))} />
          <Column
            footer={() => {
              const totalDifference = reportData?.totals?.timeDifference
              const totalSisuTime = reportData?.totals?.sisuTime
              const percentageDifference = totalDifference / totalSisuTime
              return (
                <span className='flex gap-2 w-full'>
                  <span className='w-4'>{formatDecimals(totalDifference?.toFixed(2))}</span>
                  {
                    totalDifference > 0 &&
                    <TimeDifferenceBadge
                      value={totalDifference}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      percentage={floatToPercentage(percentageDifference)}
                    />
                  }
                </span>
              )
            }}
          />
          <Column
            footer={() => {
              return isNumber(reportData?.totals?.timePerformance) ? floatToPercentage(reportData?.totals?.timePerformance, 1) : '-'
            }}
          />
        </Row>
      </ColumnGroup>
    )
  }
  const collapseAll = () => {
    setExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      newExpandedKeys[node.key] = true
    })
    setExpandedKeys(newExpandedKeys)
  }

  const adminExpandCondition = (node) => {
    if (node?.children?.length > 1) { return true } else { return false }
  }

  const teamMemberExpandCondition = (node) => {
    if (node?.children) { return true } else { return false }
  }

  return (
    <>
      <TimeDifferenceModal
        modalData={modalData}
        onClose={() => {
          setIsTimeDiffDetailsModalOpen(false)
          setModalData(null)
        }}
        isOpen={isTimeDiffDetailsModalOpen}
      />
      <TreeTable
        ref={newRef}
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup()}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        filters={filters}
        globalFilterFields={['data.name']}
        expanderConditions={(node) => {
          if (auth.user.rol === 'admin') { return adminExpandCondition(node) } else { return teamMemberExpandCondition(node) }
        }}
        onValueChange={(e) => setFilteredData(e)}
      >
        <Column
          {...CLIENT_CHIP_COLUMN_PROPS}
        />
        <Column
          field="data.distributionSisuTime"
          header="Distribución horas trabajadas"
          body={(node) => <span>{floatToPercentage(node?.data?.distributionSisuTime)}</span>}
          sortable
          sortFunction={sortParentsWithStickyChildren}
        />
        <Column
          field="data.distributionBilledTime"
          header="Distribución horas facturadas"
          sortable
          body={(node) => {
            let distributionBilledTime
            if (node?.data?.distributionBilledTime === 'N/A') {
              distributionBilledTime = 'N/A'
            } else if (node?.data?.distributionBilledTime < 0.001) {
              distributionBilledTime = '< 1%'
            } else {
              distributionBilledTime = floatToPercentage(node?.data?.distributionBilledTime)
            }
            return (
              <span className={`${node?.data?.distributionBilledTime === 'N/A' ? 'opacity-30' : ''}`}>{distributionBilledTime}</span>
            )
          }}
          sortFunction={sortParentsWithStickyChildren}
        />
        <Column
          field="data.sisuTime"
          header="Total horas trabajadas"
          sortable
          sortFunction={sortParentsWithStickyChildren}
        />
        <Column
          field="data.billedTime"
          header="Total horas facturadas"
          sortable
          sortFunction={sortParentsWithStickyChildren}
          body={(node) => {
            return (
              <span className={`${node?.data?.billedTime === 'N/A' ? 'opacity-30' : ''}`}>{node?.data?.billedTime}</span>
            )
          }}
        />
        <Column
          field="data.timeDifference"
          header="Diferencia de horas"
          sortable
          sortFunction={sortParentsWithStickyChildren}
          body={(node) => {
            let timeDifferenceComments = []
            let total = 0
            let isRoot = false

            if (node.data.taskTimeDifferenceComments?.length > 0) {
              timeDifferenceComments = Object.entries(node.data.condensedTimeDifferenceData?.items)
              total = node.data?.condensedTimeDifferenceData?.total
            }

            let onClick
            if (node.data.taskTimeDifferenceComments !== undefined && node.data.taskTimeDifferenceComments.length !== 0) {
              const fullName = node.data.name
              if (node.data.taskTimeDifferenceComments[0].projectName !== undefined) {
                isRoot = true
              }

              const timeDifferences = node.data.taskTimeDifferenceComments.map(el => ({
                teamMemberName: el.name + ' ' + el.lastName,
                comment: el.comment.trim(),
                time: el.time.trim(),
                projectCode: el.projectCode,
                projectName: el.projectName
              }))

              onClick = () => {
                let hasMoreThanOneProject = false
                if (node.children) {
                  hasMoreThanOneProject = node.children.length > 1
                }
                setModalData({ fullName, timeDifferences, total, clientCode: node.data.code, isRoot, hasMoreThanOneProject })
                setIsTimeDiffDetailsModalOpen(true)
              }
            }

            return (
              <span className="flex justify-content-between align-items-center w-full gap-2">
                <span className={`w-4 text-left ${node?.data?.timeDifference === 'N/A' ? 'opacity-30' : ''}`}>
                  {node.data.timeDifference}
                </span>
                {
                  node.data.timeDifference > 0 &&
                  <span className="flex-grow-1 text-left">
                    <TimeDifferenceBadge
                      value={node.data.timeDifference}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      badgeProps={{
                        onClick,
                        style: {
                          cursor: onClick ? 'pointer' : 'default'
                        }
                      }}
                      percentage={floatToPercentage(node.data.timeDifference / node.data.sisuTime, 1)}
                      tooltip={
                        <DifferenceReasonTable
                          header={['Motivo', 'Horas']}
                          body={timeDifferenceComments}
                          total={total}
                        />
                      }
                    />
                  </span>
                }
              </span>
            )
          }}
        />
        <Column
          field="data.timePerformance"
          header="Rendimiento"
          sortable
          sortFunction={sortParentsWithStickyChildren}
          body={(node) => {
            return (
              <span className={`${node?.data?.clientId === 1 ? 'opacity-30' : ''}`}>
                {
                  node?.data?.timePerformance !== 'N/A' && node?.data?.clientId !== 1
                    ? floatToPercentage(node?.data?.timePerformance, 1)
                    : 'N/A'
                }
              </span>
            )
          }}
        />
      </TreeTable>
    </>
  )
})

export default React.memo(HoursByProjectReportDataTable)
