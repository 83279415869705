import React from 'react'
import FormContainer from '../../../../../components/layouts/containers/FormContainer.container'
import FieldContainer from '../../../../../components/layouts/containers/FieldContainer.container'
import InputTextForm from '../../../../../components/molecules/InputTextForm.molecule'

const FormProject = ({
  formData,
  handleChange,
  validations,
  layout = 'vertical'
}) => {
  const cols = layout === 'horizontal' ? 6 : 12
  return (
    <FormContainer className='mt-3'>
      <FieldContainer md={cols} className={layout === 'horizontal' && 'mb-0'}>
        <InputTextForm
          label='Nombre'
          placeholder='Nombre del proyecto'
          name='name'
          value={formData.name}
          onChange={handleChange}
          error={validations.name.errors}
        />
      </FieldContainer>
      <FieldContainer md={cols} className={layout === 'horizontal' && 'mb-0'}>
        <InputTextForm
          label='Código'
          placeholder='Código del proyecto'
          name='code'
          value={formData.code}
          onChange={handleChange}
          error={validations.code.errors}
          maxLength={3}
        />
      </FieldContainer>
    </FormContainer>
  )
}

export default FormProject
