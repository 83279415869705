import React, { useLayoutEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Column from './Column'
import ColumnControls from './ColumnControls'
import CreateModule from './CreateModule'

const CustomPickList = ({
  leftItems,
  rightItems,
  selectedItems,
  setSelectedItems,
  setItems,
  moveItem,
  handleCreate,
  onDelete,
  orderMode,
  containerRef,
  visibleModal,
  onEdit
}) => {
  useLayoutEffect(() => {
    const element = document.querySelector('.p-picklist-list')
    let root
    if (containerRef.current) {
      const tempContainer = document.createElement('div')
      root = createRoot(tempContainer)
      root.render(
        <CreateModule
          onSubmit={handleCreate}
        />
      )
      element.prepend(tempContainer)
    }
    return () => {
      if (root) {
        setTimeout(() => root.unmount())
      }
      if (element && element.firstChild) {
        element.removeChild(element.firstChild)
      }
    }
  }, [handleCreate])

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        // key={leftItems || rightItems}
        className='flex gap-3'
        ref={containerRef}
      >
        <Column
          key={'active' - selectedItems.length}
          title='Activos'
          items={leftItems}
          moveItem={moveItem}
          orderMode={orderMode}
          onDelete={onDelete}
          visibleModalWarn={visibleModal}
          onEdit={onEdit}
          onSelect={(module) => {
            const isSelected = selectedItems.some(selectedModule => selectedModule.name === module.name)
            const filteredSelectedItems = selectedItems.filter(selectedModule => leftItems.some(item => item.name === selectedModule.name))
            if (isSelected) {
              module.selected = 0
              setSelectedItems(filteredSelectedItems.filter(selectedModule => selectedModule.name !== module.name))
            } else {
              module.selected = 1
              setSelectedItems([...filteredSelectedItems, module])
            }
          }}
          selectedModules={selectedItems}
        />
        <ColumnControls
          elements={[
            ...leftItems,
            ...rightItems
          ]}
          selectedModules={selectedItems}
          onMoveAllToLeft={() => {
            setItems([
              ...leftItems,
              ...rightItems
            ].map((module, index) => ({ ...module, active: 1 })))
          }}
          onMoveAllToRight={() => {
            setItems([
              ...leftItems,
              ...rightItems
            ].map((module, index) => ({ ...module, active: 0 })))
          }}
          onMoveToLeft={() => {
            const newInactiveModules = rightItems.filter((module) => !module.selected)
            const newActiveModules = [
              ...leftItems,
              ...rightItems.filter((module) => module.selected)
            ]
            setItems([
              ...newActiveModules.map((module, index) => ({ ...module, active: 1 })),
              ...newInactiveModules.map((module, index) => ({ ...module, active: 0 }))
            ])
            setSelectedItems([])
          }}
          onMoveToRight={() => {
            const newActiveModules = leftItems.filter((module) => !module.selected)
            const newInactiveModules = [
              ...rightItems,
              ...leftItems.filter((module) => module.selected)
            ]
            setItems([
              ...newActiveModules.map((module, index) => ({ ...module, active: 1 })),
              ...newInactiveModules.map((module, index) => ({ ...module, active: 0 }))
            ])
            setSelectedItems([])
          }}
        />
        <Column
          key={selectedItems.length}
          title='Inactivos'
          items={rightItems}
          moveItem={moveItem}
          orderMode={orderMode}
          onDelete={onDelete}
          onSelect={(module) => {
            const isSelected = selectedItems.some(selectedModule => selectedModule.name === module.name)
            const filteredSelectedItems = selectedItems.filter(selectedModule => rightItems.some(item => item.name === selectedModule.name))
            if (isSelected) {
              module.selected = 0
              setSelectedItems(filteredSelectedItems.filter(selectedModule => selectedModule.name !== module.name))
            } else {
              module.selected = 1
              setSelectedItems([...filteredSelectedItems, module])
            }
          }}
          selectedModules={selectedItems}
        />
      </div>
    </DndProvider>
  )
}

export default CustomPickList
