import React from 'react'
import Tooltip from '../atoms/Tooltip.atom'
import { generateRandomNumber } from '../../utils/numbers.utils'

const TextIconWithTooltip = ({
  content,
  contentProps,
  iconClass = 'pi pi-info-circle',
  iconProps = {
    style: {
      color: 'light-dark(#212121, var(--text-color)'
    }
  },
  tooltipValue,
  tooltipProps,
  position = 'bottom'
}) => {
  const id = generateRandomNumber()
  return (
    <span className={'flex gap-1 align-items-center'}>
      <span {...contentProps}>{content}</span>
      <span>
        <i className={`text-xl ${iconClass} text-icon-${id}`} {...iconProps}/>
        <Tooltip
          target={`.text-icon-${id}`}
          position={position}
          {...tooltipProps}
        >
          {tooltipValue}
        </Tooltip>
      </span>
    </span>

  )
}

export default TextIconWithTooltip
