import React, { createContext, useEffect, useState } from 'react'
import { toastMessages } from '../../consts/common.const'
import useToast from '../../hooks/useToast.hook'
import { getOptionsFiltersUsuarios } from '../../services/usuario.service'
import { orderArrayOfObjects } from '../../utils/arrays.utils'
import { createToastConfig } from '../../utils/toast.utils'

const UsersOptionsContext = createContext()

export const UsersOptionsProvider = ({ children }) => {
  const [usersOptions, setUsersOptions] = useState(null)
  const toast = useToast()

  const gettingInitialData = async () => {
    const responseService = await getOptionsFiltersUsuarios()
    if (!responseService.success) {
      const dataDoesNotLoadMessage = createToastConfig(toastMessages.dataDoesNotLoad)
      return toast.setMessage(...dataDoesNotLoadMessage)
    }
    const result = responseService.result
    const sortConfiguration = { optionValue: 'nombre', sortType: 'desc' }

    setUsersOptions({
      departamentos: orderArrayOfObjects(result.departamentos, sortConfiguration),
      lineasTransportista: orderArrayOfObjects(result.lineasTransportista, sortConfiguration),
      locaciones: orderArrayOfObjects(result.locaciones, sortConfiguration),
      nacionalidades: orderArrayOfObjects(result.nacionalidades, sortConfiguration),
      roles: orderArrayOfObjects(result.roles, sortConfiguration),
      permisosDeEdicion: orderArrayOfObjects(result.permisosDeEdicion, sortConfiguration)
    })
  }

  useEffect(() => {
    gettingInitialData()
  }, [])

  return (
    <UsersOptionsContext.Provider value={{ usersOptions }}>
      {children}
    </UsersOptionsContext.Provider>
  )
}

export default UsersOptionsContext
