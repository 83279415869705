import React, { createContext, useRef } from 'react'
import Toast from '../components/atoms/misc/Toast.atom'

const ToastContext = createContext()
export default ToastContext

export function ToastContextProvider ({ children }) {
  const toast = useRef(null)

  return (
    <>
      <ToastContext.Provider value={{ toast }}>
        {children}
        <Toast ref={toast}/>
      </ToastContext.Provider>
    </>
  )
}
