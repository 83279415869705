/* eslint-disable */
import { useEffect, useRef } from 'react';

/**
 * https://github.com/streamich/react-use/blob/master/docs/usePrevious.md
 * A custom hook that keeps track of the previous state value.
 *
 * @template T The type of the state.
 * @param {T} state The current state value.
 * @returns {T | undefined} The previous state value or undefined if not set.
 */
export default function usePrevious(state) {
    /** @type {React.MutableRefObject<T | undefined>} */
    const ref = useRef();

    useEffect(() => {
        ref.current = state;
    }, [state]);

    return ref.current;
}