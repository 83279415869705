import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { View404 } from '../views/systemPages/View404.view'
import { useSession } from '../hooks/auth/useSession.hook'
import { getRoutesByRole } from '../utils/routes.utils'
import { DEFAULT_ROUTES } from './roleRoutes/defaultRoutes.routes'

export function ApplicationRoutes () {
  const { user } = useSession()
  // console.log(user)
  const routes = getRoutesByRole(user?.projectRole)

  const toRoute = ({ path, element }) => <Route key={path} path={path} element={element} />

  return <Routes>
    {/* User's specific routes */}
    {routes.map(toRoute)}

    {/* Routes for all users, no matter role */}
    {DEFAULT_ROUTES.map(toRoute)}

    {/* 404 */}
    <Route path="*" element={<View404 />} />
  </Routes>
}
