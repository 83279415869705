export const orderArrayOfObjects = (array, { optionValue, sortType = 'asc' }) => {
  const arrayToSort = [...array]
  return arrayToSort.sort((a, b) => {
    let x = null
    let y = null
    if (optionValue) {
      const typeValue = typeof a[optionValue]
      if (typeValue === 'string') {
        x = a[optionValue].trim().toLowerCase()
        y = b[optionValue].trim().toLowerCase()
      } else {
        x = Number(a[optionValue])
        y = Number(b[optionValue])
      }
    } else {
      const typeValue = typeof a
      if (typeValue === 'string') {
        x = a.trim().toLowerCase()
        y = b.trim().toLowerCase()
      } else {
        x = Number(a)
        y = Number(b)
      }
    }
    if (x === y) {
      return 0
    }
    const SORT_TYPES = {
      desc: {
        lesserValue: -1,
        higherValue: 1
      },
      asc: {
        lesserValue: 1,
        higherValue: -1
      }
    }
    const DEFAULT_SORT_TYPE = 'desc'
    if (x < y) {
      return SORT_TYPES[sortType || DEFAULT_SORT_TYPE].lesserValue
    }
    return SORT_TYPES[sortType || DEFAULT_SORT_TYPE].higherValue
  })
}

export const getUniqueValuesFromArrayOfObjects = (array = [], property = 'id') => {
  const dataMaped = array.map(el => el[property])
  const SET = new Set(dataMaped)
  return [...SET]
}

export const indexArrayOfObjectsByProperty = (array = [], property = 'id') => {
  const resultIndexed = {}
  array.forEach(element => {
    const propertyValue = element[property]
    if (resultIndexed[propertyValue]) {
      resultIndexed[propertyValue].push(element)
    } else {
      resultIndexed[propertyValue] = [element]
    }
  })
  return resultIndexed
}

export function arraysContainSameStrings (arr1, arr2) {
  if (arr1.length !== arr2.length) return false

  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false
  }

  return true
}

/**
 * @param {number[]} arr1
 * @param {number[]} arr2
 */
export function arraysContainSameNumbers (arr1, arr2) {
  if (arr1.length !== arr2.length) return false
  const sortedArr1 = arr1.slice().sort((a, b) => a - b)
  const sortedArr2 = arr2.slice().sort((a, b) => a - b)
  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

export const generateIntermediateNumbersOfRange = (start, end, separation) => {
  const result = []
  for (let i = start; i <= end; i += separation) {
    result.push(i)
  }
  return result
}
