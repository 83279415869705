import React from 'react'
import { ContextMenu as PrimeContextMenu } from 'primereact/contextmenu'

const ContextMenu = React.forwardRef((props, ref) => {
  return (
    <PrimeContextMenu
      ref={ref}
      {...props}
    />
  )
})

export default ContextMenu
