import React from 'react'
import { useParams } from 'react-router-dom'
import GlobalTitle from '../../../components/compounds/titles/GlobalTitle.compounds'

const FormHeaderUsuarios = () => {
  const params = useParams()
  const typeFormLabel = params.userId
    ? 'Actualizar'
    : 'Crear'
  return (
    <GlobalTitle title={`${typeFormLabel} usuario`} goBack/>
  )
}

export default FormHeaderUsuarios
