import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import { createClient } from '../../../../../services/client.service'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../../../consts/appRoutes.consts'

const FormClientFooter = ({
  client,
  projects,
  error: formError
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    setError(formError)
  }, [formError])
  const handleCreateClient = async () => {
    setLoading(true)
    try {
      const hexColor = getComputedStyle(document.documentElement).getPropertyValue(client?.color.match(/--[a-zA-Z0-9-]+/)[0]).trim()
      client.color = hexColor
      const response = await createClient(client, projects)
      if (!response.success) {
        throw new Error(response.error)
      }
      setTimeout(() => {
        navigate(AppRoutes.clientes.index)
      }, 500)
    } catch (error) {
      setError('Hubo un error al crear el cliente')
    }
    setLoading(false)
  }
  return (
    <>
      <div className='flex w-full justify-content-end mt-3'>
        <PrimaryButton
          label = 'Crear'
          icon = {`pi pi-${loading ? 'spinner pi-spin' : 'plus'}`}
          onClick={handleCreateClient}
          disabled={!client?.name || !client?.code || !client?.color || !projects?.length || formError}
        />
      </div>
      {error && <div className='text-red-500'>{error.message || error || 'Hubo un error al crear el cliente'}</div>}
    </>
  )
}

export default FormClientFooter
