/* eslint-disable */
import React, { useState } from 'react'
import Column from '../../../../../components/atoms/table/Column.atom'
import ColumnGroup from '../../../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../../../components/atoms/table/RowColumnGroup.atom'
import MultiSelectTeamMemberFilter from '../../../../../components/compounds/MultiSelectTeamMemberFilter.compound'
import ColumnContent from './ColumnContent.controller'
import { CustomTreeTable as TreeTable } from '../../../../../components/atoms/table/CustomTreeTable.atom'
import { roundReportData } from '../../../../../utils/report.utils'
import { formatDecimals } from '../../../../../utils/numbers.utils'
import { getMonthLongNameByNumber } from '../../../../../utils/date.utils'

const calculateTotals = (data, totals, filters) => {
  let filteredData = []
  let newTotals = {
    averageTime: 0,
    billedTime: 0
  }
  const teamMemberData = data.flatMap(node => node.children)

  if (filters["data.name"].value !== null) {
    if (totals.weekData !== undefined) {
      const weeksToConsider = Object.keys(totals.weekData)
      newTotals.weekData = {}
      weeksToConsider.forEach((week) => {
        newTotals.weekData[week] = {
          sisuTime: 0
        }
      })
    } else if (totals.monthData !== undefined) {
      const monthsToConsider = Object.keys(totals.monthData)
      newTotals.monthData = {}
      monthsToConsider.forEach((month) => {
        newTotals.monthData[month] = {
          sisuTime: 0
        }
      })
    }

    filteredData = teamMemberData.filter((node) => filters["data.name"].value.includes(node.data.name))

    filteredData.forEach((node) => {
      newTotals.averageTime += node.data.billedTime
      newTotals.billedTime += node.data.billedTime
      if (totals.weekData !== undefined) {
        const weeksToConsider = Object.keys(node.data.rangeData)
        weeksToConsider.forEach((week) => {
          newTotals.weekData[week].sisuTime += node.data.rangeData[week].sisuTime
        })
      } else if (totals.monthData !== undefined) {
        const monthsToConsider = Object.keys(node.data.rangeData)
        monthsToConsider.forEach((month) => {
          newTotals.monthData[month].sisuTime += node.data.rangeData[month].sisuTime
        })
      }
    })

    newTotals.averageTime /= totals.rangeDuration
    return newTotals
  } else {
    return totals
  }
}

const TeamHoursConsumptionTable = React.forwardRef(({
  reportData: originalData,
  startYear,
  endYear,
  period,
  expandedKeys,
  setExpandedKeys
}, ref) => {
  // const [expandedKeys, setExpandedKeys] = useState({})
  const [filteredData, setFilteredData] = useState([])
  const [filters, setFilters] = useState({
    'data.name': { value: null, matchMode: 'in' }
  })
  const reportData = roundReportData(originalData)
  const resetFilters = () => {
    collapseAll()
    setFilters({
      'data.name': { value: null, matchMode: 'in' }
    })
    setFilteredData([])
  }

  const newRef = React.useRef()
  React.useImperativeHandle(ref, () => ({
    reset() {
      collapseAll()
      setFilters({
        'data.name': { value: null, matchMode: 'in' }
      });
      setFilteredData([])
      newRef.current.reset()
    }
  }), [])

  const headerRangeName = (rangeStartYear, rangeEndYear, rangePeriod) => {
    if (rangePeriod === 'week') {
      if (rangeStartYear === rangeEndYear) {
        return `${rangeStartYear} - Semana`
      } else {
        return `${rangeStartYear}/${rangeEndYear} - Semana`
      }
    } else {
      if (rangeStartYear === rangeEndYear) {
        return `${rangeStartYear} - Mes`
      } else {
        return `${rangeStartYear}/${rangeEndYear} - Mes`
      }
    }
  }

  const headerRangeColumns = (rangePeriod) => {
    if (rangePeriod === 'week') {
      return reportData.totals?.weeksToConsider?.length
    } else if (rangePeriod === 'month') {
      return reportData.totals?.monthsToConsider?.length
    }
  }

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Equipos"
          rowSpan={3}
          colSpan={2}
          field='data'
          filter
          filterField='data.name'
          filterElement={(options) =>
            <MultiSelectTeamMemberFilter
              key={reportData.data.length}
              options={options}
              data={reportData.data}
            />
          }
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          filterMatchMode={'in'}
          onFilterApplyClick={(e) => {
            if (!e.constraints?.value) {
              collapseAll()
            } else {
              expandAll()
              setFilteredData(e.constraints?.value)
              setFilters({
                'data.name': { value: e.constraints?.value, matchMode: 'in' }
              })
            }
          }}
          onFilterClear={resetFilters}
          style={{ width: '255px' }}
        />
      </Row>
      <Row>
        {
          headerRangeColumns(period)
          ? <Column 
              header={headerRangeName(startYear, endYear, period)} 
              colSpan={headerRangeColumns(period)} 
              rowSpan={1} 
            />
          : null
        }
        <Column header="Totales" colSpan={1} rowSpan={2} />
        <Column header="Promedio" colSpan={1} rowSpan={2} />
      </Row>
      <Row>
        {period === 'week' && reportData.totals?.weeksToConsider?.map((week, index) => {
          return (
            <Column key={index} header={week} colSpan={1} rowSpan={1} style={{ width: '134px' }} />
          )
        })}
        {period === 'month' && reportData.totals?.monthsToConsider?.map((month, index) => {
          return (
            <Column key={index} header={getMonthLongNameByNumber(month)} colSpan={1} rowSpan={1} style={{ width: '134px' }} />
          )
        })}
      </Row>
    </ColumnGroup>
  )

  const footerGroup = () => {
    const totalsToUse = calculateTotals(reportData.data, reportData.totals, filters)
    return (
      <ColumnGroup key={reportData.totals}>
        <Row>
          <Column 
            colSpan={2}
            footer="Totales" 
          />
          {rangeArray(reportData)?.map((rangePeriod, index) => {
            if (period === 'week' && reportData?.totals?.weekData !== undefined) {
              return (
                <Column 
                  key={index} 
                  footer={formatDecimals(totalsToUse.weekData[rangePeriod]?.sisuTime?.toFixed(2))} 
                />
              )
            } else if (period === 'month' && reportData?.totals?.monthData !== undefined) {
              return (
                <Column 
                  key={index} 
                  footer={formatDecimals(totalsToUse.monthData[rangePeriod]?.sisuTime?.toFixed(2))} 
                />
              )
            } else {
              return (
                <Column 
                  key={index} 
                  footer='0' 
                />
              )
            }
          })}
          <Column footer={formatDecimals(totalsToUse.billedTime?.toFixed(2))} />
          <Column footer={formatDecimals(totalsToUse.averageTime?.toFixed(2))} />
        </Row>
      </ColumnGroup>
    )
  }

  const collapseAll = () => {
    setExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      newExpandedKeys[node.key] = true
    })
    setExpandedKeys(newExpandedKeys)
  }

  const teamMemberExpandCondition = (node) => {
    if (node?.children && node?.children.length > 0)
      return true;
    else
      return false;
  }

  const rangeArray = (reportDataTemporal) => {
    if (reportDataTemporal.totals?.monthsToConsider?.length !== undefined && reportDataTemporal.totals?.monthsToConsider?.length !== 0)
      return reportDataTemporal.totals.monthsToConsider
    else if (reportDataTemporal.totals?.weeksToConsider?.length !== undefined && reportDataTemporal.totals?.weeksToConsider?.length !== 0)
      return reportDataTemporal.totals.weeksToConsider
    else
      return []
  }

  return (
    <>
      <TreeTable
        ref={newRef} 
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup()}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        filters={filters}
        globalFilterFields={['data.name']}
        expanderConditions={(node) => teamMemberExpandCondition(node)}
        onValueChange={(e) => setFilteredData(e)}
      >
        {
          ColumnContent(
            rangeArray(reportData)
          )?.map((column, index) => (
            <Column
              key={index}
              {...column}
            />
          ))
        }
      </TreeTable>
    </>
  )
})

export default TeamHoursConsumptionTable
