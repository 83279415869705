export const MODALS_ACCESSIBLE = [
  'modalCrearOrdenDeVenta'
]

export const INV_MODES = {
  VIRTUAL: 1,
  FISICO: 2
}

export const SWITCH_TYPE_OPTIONS = {
  FIRST: 0,
  SECOND: 1
}

export const SWITCH_FILTER_OPTIONS = [
  { label: 'Single select', value: SWITCH_TYPE_OPTIONS.FIRST },
  { label: 'Multi select', value: SWITCH_TYPE_OPTIONS.SECOND }
]
export const FILTERS_OPTIONS = {
  BUDGETS: {
    TYPES_OF_GROUPINGS: {
      NONE: 'none',
      PRODUCTO: 'producto'
    }
  }
}
