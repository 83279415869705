import { DateTime } from 'luxon'
import { PHASE_PERCENTAGES_RANGES, PHASE_RANGE_NAMES } from '../consts/modules/budget.consts'

export const getBudgetPhaseFromPercentage = (percentage) => {
  if (percentage >= PHASE_PERCENTAGES_RANGES.INICIAL) {
    return PHASE_RANGE_NAMES.INICIAL
  } else if (percentage >= PHASE_PERCENTAGES_RANGES.INTERMEDIA) {
    return PHASE_RANGE_NAMES.INTERMEDIA
  } else if (percentage > PHASE_PERCENTAGES_RANGES.FINALIZADO) {
    return PHASE_RANGE_NAMES.CIERRE
  } else if (percentage === PHASE_PERCENTAGES_RANGES.FINALIZADO) {
    return PHASE_RANGE_NAMES.FINALIZADO
  } else {
    return PHASE_RANGE_NAMES.SOBREGIRADO
  }
}

export const getBudgetProgressBarLabelFromPercentage = (percentage, hasBudget = true, hasBilledTime = true) => {
  if (!hasBudget && hasBilledTime) {
    return {
      title: 'Sin presupuesto',
      color: 'var(--clr-danger)',
      label: 'Se registraron horas, sin tener presupuesto asignado'
    }
  }
  if (!hasBudget && !hasBilledTime) {
    return {
      title: 'Sin presupuesto',
      color: 'var(--clr-danger)',
      label: 'No se han registrado horas'
    }
  }
  let phase = {}
  if (percentage >= PHASE_PERCENTAGES_RANGES.INICIAL) {
    phase = {
      title: 'Inicial',
      color: 'var(--clr-info)',
      label: 'Que bonitos son los inicios'
    }
  } else if (percentage >= PHASE_PERCENTAGES_RANGES.INTERMEDIA) {
    phase = {
      title: 'Intermedia',
      color: '#5EADD7',
      label: 'Aquí es donde la magia sucede'
    }
  } else if (/* percentage > PHASE_PERCENTAGES_RANGES.CIERRE */ percentage > PHASE_PERCENTAGES_RANGES.FINALIZADO) {
    phase = {
      title: 'Cierre',
      color: '#9EC9D5',
      label: '¡Final a la vista!'
    }
  } else if (percentage === PHASE_PERCENTAGES_RANGES.FINALIZADO) {
    phase = {
      title: 'Finalizado',
      color: '#D9D9D9',
      label: 'Colorín colorado, el presupuesto se ha acabado'
    }
  } else {
    phase = {
      title: 'Error',
      color: 'var(--clr-danger)',
      label: '¡Ups! El presupuesto se ha sobregirado'
    }
  }
  return phase
}

export const getEstimatedBudgetEnd = (remainingBudget, historicalAverage, last3MonthsAverage) => {
  let minAverage = Math.min(historicalAverage, last3MonthsAverage) || 0
  let maxAverage = Math.max(historicalAverage, last3MonthsAverage) || 0
  if ((minAverage === 0 && maxAverage !== 0)) {
    minAverage = maxAverage
  } else if ((maxAverage === 0 && minAverage !== 0)) {
    maxAverage = minAverage
  } else if (minAverage === 0 || maxAverage === 0) {
    return {
      label: 'Sin estimación',
      estimatedEndMinDate: DateTime.local(),
      estimatedEndMaxDate: DateTime.local()
    }
  }
  // Consumo promedio mensual
  const estimatedEndMin = remainingBudget / maxAverage
  const estimatedEndMax = remainingBudget / minAverage

  const firstLabel = getEstimatedLabel(estimatedEndMin)
  const secondLabel = getEstimatedLabel(estimatedEndMax)

  let fullLabel = ''
  if (firstLabel.value <= 0 && secondLabel.value <= 0) {
    return {
      label: '0 días restantes',
      estimatedEndMinDate: DateTime.local(),
      estimatedEndMaxDate: DateTime.local()
    }
  } else if (firstLabel.value === secondLabel.value && firstLabel.unidadesSufijo === secondLabel.unidadesSufijo) {
    fullLabel = `~${firstLabel.value} ${firstLabel.unidadesSufijo}`
  } else if (firstLabel.unidadesSufijo === secondLabel.unidadesSufijo) {
    fullLabel = `~De ${firstLabel.value} a ${secondLabel.value} ${firstLabel.unidadesSufijo}`
  } else {
    fullLabel = `~De ${firstLabel.value} ${firstLabel.unidadesSufijo} a ${secondLabel.value} ${secondLabel.unidadesSufijo}`
  }

  return {
    label: fullLabel,
    estimatedEndMinDate: DateTime.local().plus({ months: estimatedEndMin }),
    estimatedEndMaxDate: DateTime.local().plus({ months: estimatedEndMax })
  }
}

const getEstimatedLabel = (valueInMonths) => {
  let unidadesSufijo = ''
  if (valueInMonths > 12) {
    const years = Math.floor(valueInMonths / 12)
    unidadesSufijo = 'año' + (years >= 2 ? 's' : '')
    valueInMonths = Math.floor(valueInMonths / 12)
  } else if (valueInMonths >= 1) {
    unidadesSufijo = 'mes' + (valueInMonths >= 2 ? 'es' : '')
    valueInMonths = Math.floor(valueInMonths)
  } else if (valueInMonths >= 0.25) {
    unidadesSufijo = 'semana' + (valueInMonths >= 0.5 ? 's' : '')
    valueInMonths = Math.floor(valueInMonths * 4)
  } else {
    unidadesSufijo = 'día' + (valueInMonths >= 0.25 / 7 ? 's' : '')
    valueInMonths = Math.floor(valueInMonths * 30)
  }
  return {
    value: valueInMonths,
    unidadesSufijo
  }
}
