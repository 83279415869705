import React, { forwardRef } from 'react'
import { Messages as PrimeMessage } from 'primereact/messages'

export const Message = forwardRef(
  /**
   *
   * @param {import('primereact/messages').MessagesProps} props
   * @param {*} ref
   * @returns
   */
  function Message (props, ref) {
    return (
      <PrimeMessage {...props} ref={ref} />
    )
  }
)
