import React, { useState } from 'react'
import FormHeaderCreateClient from './controllers/FormHeaderClient.controller'
import FormEditClient from './controllers/editClient/FormEditClient.controller'

const EditClient = () => {
  const [selectedClient, setSelectedClient] = useState()
  return (
    <>
      <FormHeaderCreateClient
        isCreate={false}
        selectedClient={selectedClient}
      />
      <FormEditClient
        setSelectedClient={setSelectedClient}
      />
    </>
  )
}

export default EditClient
