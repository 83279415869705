import {
  isANumberGreaterThanZero,
  isNotEmpty
} from '../../utils/validations.utils'

export const PHASE_PERCENTAGES_RANGES = {
  INICIAL: 70,
  INTERMEDIA: 40,
  CIERRE: 15,
  FINALIZADO: 0
}

export const PHASE_RANGE_NAMES = {
  INICIAL: 'Inicial',
  INTERMEDIA: 'Intermedio',
  CIERRE: 'Cierre',
  FINALIZADO: 'Finalizado',
  SOBREGIRADO: 'Sobregirado'
}

export const BUDGET_REGISTRY_TYPE = {
  AUMENTO: 0,
  REDUCCION: 1
}

export const ADD_BUDGET_FORM_DATA_TEMPLATE = {
  description: '',
  authorizationDate: '',
  registryType: 0,
  quantity: BUDGET_REGISTRY_TYPE.AUMENTO
}

export const ADD_BUDGET_FORM_INITIAL_STATE = {
  description: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  authorizationDate: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  registryType: {
    value: BUDGET_REGISTRY_TYPE.AUMENTO,
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  quantity: {
    value: '',
    errors: [],
    typeValidations: [isANumberGreaterThanZero],
    validationsOptions: {}
  }
}

export const AVERAGE_CONSUMPTION_TYPES = {
  MENSUAL: 'Mensual',
  SEMANAL: 'Semanal'
}

export const AVERAGE_CONSUMPTION_TYPES_OPTIONS = [
  {
    label: 'Mensual',
    value: AVERAGE_CONSUMPTION_TYPES.MENSUAL
  },
  {
    label: 'Semanal',
    value: AVERAGE_CONSUMPTION_TYPES.SEMANAL
  }
]

export const CREATE_BUDGET_MOVEMENT_ERROR_MESSAGE = {
  title: 'Error al agregar registro',
  message: 'Ocurrió un error al intentar agregar el registro al presupuesto. Por favor, intenta de nuevo.'
}

export const GET_BUDGET_INFORMATION_ERROR_MESSAGE = {
  title: 'Error al cargar información',
  message: 'Ocurrió un error al cargar la información del presupuesto. Por favor, intenta de nuevo.'
}

export const CLIENT_BUDGET_PHASES = {
  INICIAL: 'Inicial',
  INTERMEDIA: 'Intermedia',
  CIERRE: 'Cierre',
  FINALIZADO: 'Finalizado',
  SIN_PRESUPUESTO: 'Sin presupuesto',
  SOBREGIRADO: 'Sobregirado'
}
