import React from 'react'
import HeaderUsuarios from './controllers/HeaderUsuarios.controller'
import UsersTable from './controllers/UsersTable.controller'
import MainWithTableContainer from '../../components/layouts/containers/MainWithTableContainer.container'

const Usuarios = () => {
  return (
    <MainWithTableContainer>
      <HeaderUsuarios/>
      <UsersTable/>

    </MainWithTableContainer>
  )
}

export default Usuarios
