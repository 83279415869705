import {
  isANumberGreaterThanZero,
  isNotEmpty
} from '../../utils/validations.utils'

/** @type {import('./timeRegister.consts').TimeRegisterMessages} */
export const TIME_REGISTER_MESSAGES = {
  FINISHED_EDITABLE_DAY: {
    severity: 'warn',
    text: 'Ya finalizaste este día, los cambios o correcciones que hagas, se notificarán al administrador.'
  },
  FINISHED_NOT_EDITABLE_DAY: {
    severity: 'info',
    text: 'Recuerda que solo puedes realizar cambios en la semana actual. Para editar registros de semanas anteriores, contacta al administrador.'
  },
  MISSING_DAY: {
    severity: 'warn',
    text: 'Recuerda que estás editando un día pendiente. Finalízalo para continuar con las tareas de hoy.'
  }
}

/** @type {import('./timeRegister.consts').CreateTaskInitialFormState} */
export const CREATE_TASK_INITIAL_FIELD_STATE = {
  name: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  projectId: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  categoryId: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  moduleId: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  sisuTime: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty, isANumberGreaterThanZero],
    validationOptions: {}
  },
  billedTime: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {}
  },
  comment: {
    errors: [],
    value: null,
    typeValidations: [],
    validationOptions: {}
  },
  timeDifferenceComment: {
    errors: [],
    value: null,
    typeValidations: [],
    validationOptions: {}
  }
}
