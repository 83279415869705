/* eslint-disable */
import React, { useState } from 'react'
import { useSession } from '../../hooks/auth/useSession.hook'
import { Link, useNavigate } from 'react-router-dom'
import { ROLES_TO_SHOW } from '../../consts/roles.consts'
import ModalForm from '../../components/compounds/modalForm/ModalForm.compunds'
import SecondaryButton from '../../components/molecules/buttons/SecondaryButton.molecule'
import PrimaryButton from '../../components/molecules/buttons/PrimaryButton.molecule'

const AppMenuUserPreview = () => {
  const [confirmLogoutVisible, setConfirmLogoutVisible] = useState(false)
  const { user } = useSession()
  const navigate = useNavigate()
  const handleConfirmLogout = async () => {
    navigate('/logout')
  }
  const UserTemplate = () => (
    <div className="user-info flex align-items-center gap-2">
      {/* eslint-disable-next-line */}
      <div className="flex align-items-center">
        <span className='user-tag border-circle bg-white text-blue-600 flex align-items-center justify-content-center text-xs font-bold
      my-auto'>{user?.nameTag}</span>
      </div>
      <div className='flex flex-column'>
        <span className="user-name flex align-items-center">{user?.nombre?.split(' ')[0]}</span>
        <span className="user-role flex align-items-center">{ROLES_TO_SHOW[user.projectRole]}</span>
      </div>
    </div>
  )
  return (
    <div className='user-info-sidebar flex flex-column'>
      <a>
        <UserTemplate />
      </a>

      <div
        className="logout-caller flex gap-2 align-items-center mt-4 cursor-pointer"
        onClick={() => setConfirmLogoutVisible(true)}
      >
        <i className="pi pi-power-off text-xl pr-1"></i>
        <span>Cerrar sesión</span>
      </div>
      <ModalForm
        visible={confirmLogoutVisible}
        setVisible={setConfirmLogoutVisible}
        header={
          <div className="flex gap-3 align-items-center">
            <i className="pi pi-exclamation-circle text-4xl" style={{ color: '#2896D8' }} />
            <span className="text-3xl font-semibold font-cairo">Cierre de sesión</span>
          </div>
        }
        footer={
          <div>
            <SecondaryButton icon='pi pi-times clr-secondary' label='Cancelar' type='text' onClick={() => setConfirmLogoutVisible(false)} />
            <PrimaryButton icon='pi pi-check' label='Cerrar' onClick={handleConfirmLogout} />
          </div>
        }
        closable={false}
        onHid
      >
        ¿Estás seguro que deseas cerrar sesión?
      </ModalForm>
    </div>
  )
}

export default AppMenuUserPreview
