import React from 'react'
import TabMenuContentContainer from '../../../components/layouts/containers/TabMenuContentContainer.container'
import ThemeToggler from '../component/ThemeToggler'

const SettingsContent = () => {
  return (
    <TabMenuContentContainer className='settings-container custom-shadow'>
      <ThemeToggler/>
    </TabMenuContentContainer>
  )
}

export default SettingsContent
