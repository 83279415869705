import React, { useEffect, useState } from 'react'
import SectionHeading from '../../../../components/layouts/containers/SectionHeading.container'
import FieldContainer from '../../../../components/layouts/containers/FieldContainer.container'
import TabMenuContentContainer from '../../../../components/layouts/containers/TabMenuContentContainer.container'
import PrimaryButton from '../../../../components/molecules/buttons/PrimaryButton.molecule'
import { getHoursResumeOfTeamMemberID } from '../../../../services/task.service'
import useSessionContext from '../../../../hooks/contextConsumers/useSessionContext.hook'
import FinishDay from './components/FinishDay'
import SaveAfterFinished from './components/SaveAfterFinished'
import { DateTime } from 'luxon'
import TimeTemplate from './components/TimeTemplate'
import useTimeRegisterContext from '../../hooks/useTimeRegisterContext.hook'
import { editFinishedWorkDay, finishWorkDay } from '../../../../services/workDay.service'
import { compareDates } from '../../../../utils/date.utils'

const TimeSummary = () => {
  const {
    doFetchTasks: refresh,
    setRefresh,
    hasTasks,
    isDayFinished,
    isDayEditable,
    lastWorkDay,
    setLastWorkDay,
    selectedDate,
    uncompleteDay,
    triggerFinishDay,
    canSaveChanges,
    setShowedMissingDayDialog,
    setShowMissingDayDialog,
    setShowUncompleteDayDialog,
    setShowedUncompleteDayDialog,
    setCanSaveChanges,
    setSelectedDate,
    setUncompleteDay,
    setEditingMissingDay,
    setWorkDayToDisplay
  } = useTimeRegisterContext()
  const [saving, setSaving] = useState(false)
  const [weekTime, setWeekTime] = useState(0)
  const [todaySisuTime, setTodaySisuTime] = useState(0)
  const [todayBilledTime, setTodayBilledTime] = useState(0)
  const [notToday, setNotToday] = useState(false)

  const [finishDayVisible, setFinishDayVisible] = useState(false)
  const { user } = useSessionContext()

  useEffect(() => {
    setFinishDayVisible(triggerFinishDay)
  }, [triggerFinishDay])

  useEffect(() => {
    getHoursSummary()
  }, [refresh, selectedDate])

  useEffect(() => {
    if (DateTime.fromJSDate(new Date(selectedDate)).startOf('day').toISODate() !== DateTime.local().startOf('day').toISODate()) {
      setNotToday(true)
    } else {
      setNotToday(false)
    }
  }, [selectedDate])

  const getHoursSummary = async () => {
    const startWeek = DateTime.fromJSDate(new Date(selectedDate)).startOf('week').toISODate()
    const endWeek = DateTime.fromJSDate(new Date(selectedDate)).endOf('week').toISODate()
    const dayDate = DateTime.fromJSDate(new Date(selectedDate)).startOf('day').toISODate()
    const response = await getHoursResumeOfTeamMemberID(
      user.teamMemberID,
      startWeek,
      endWeek,
      dayDate
    )
    setWeekTime(response?.result?.[0]?.weekHours)
    setTodaySisuTime(response?.result?.[0]?.todaySisuHours || 0)
    setTodayBilledTime(response?.result?.[0]?.todayBilledHours || 0)
  }
  const handleSave = async () => {
    setFinishDayVisible(true)
  }

  const handleSubmit = async (comment) => {
    setSaving(true)
    try {
      const response = await finishWorkDay(user.teamMemberID, DateTime.fromJSDate(selectedDate).startOf('day').toISODate(), comment)
      if (!response.success) {
        throw new Error(response.message)
      }
      setCanSaveChanges(false)
    } catch (error) {
      setCanSaveChanges(true)
      throw error
    } finally {
      setSaving(false)
    }
    setLastWorkDay(null)
    setRefresh(!refresh)
    setShowedMissingDayDialog(false)
    setShowMissingDayDialog(false)
    setShowUncompleteDayDialog(false)
    setShowedUncompleteDayDialog(false)
    const currentDate = DateTime.fromJSDate(selectedDate)
    const nextDate = currentDate.plus({ days: 1 }).toJSDate()
    if (!compareDates(currentDate.toJSDate(), DateTime.local().toJSDate())) {
      setSelectedDate(nextDate)
      setUncompleteDay(null)
      setEditingMissingDay(false)
      setWorkDayToDisplay(null)
    }
  }

  const handleSubmitAfterFinished = async () => {
    setSaving(true)
    try {
      const response = await editFinishedWorkDay(user.teamMemberID, DateTime.fromJSDate(selectedDate).startOf('day').toISODate(), lastWorkDay?.comment, true)
      if (!response.success) {
        throw new Error(response.message)
      }
      setCanSaveChanges(false)
    } catch (error) {
      setCanSaveChanges(true)
      throw error
    } finally {
      setSaving(false)
    }
    setShowedMissingDayDialog(false)
    setShowMissingDayDialog(false)
    setShowUncompleteDayDialog(false)
    setShowedUncompleteDayDialog(false)
    const currentDate = DateTime.fromJSDate(selectedDate)
    const nextDate = currentDate.plus({ days: 1 }).toJSDate()
    if (!compareDates(currentDate.toJSDate(), DateTime.local().toJSDate())) {
      setSelectedDate(nextDate)
      setUncompleteDay(null)
      setEditingMissingDay(false)
      setWorkDayToDisplay(null)
    }
  }

  const Separator = ({
    horizontal,
    className,
    height,
    width,
    minHeight,
    minWidth
  }) => {
    return (
      <div
        className={`separator ${horizontal ? 'horizontal' : 'vertical'} ${className}`}
        style={{
          height: height,
          width: width,
          minHeight: minHeight,
          minWidth: minWidth,
          backgroundColor: '#DCE1E8'
        }}
      />
    )
  }

  return (
    <TabMenuContentContainer className='mb-0'>
      <SectionHeading className='pb-0'>
        <FieldContainer col='12' md='12' className='m-0 p-0 pl-3'>
          <SectionHeading className='pb-0'>
            <TimeTemplate
              icon='pi pi-clock total-time-this-week-icon'
              label={notToday ? 'Tiempo registrado de la semana' : 'Tiempo registrado esta semana'}
              time={weekTime}
              min={0}
              max={40}
              showSentiments={false}
            />
            <div className='flex gap-3'>
              <Separator
                vertical
                height={68}
                width={1}
                bgColor={'#DCE1E8'}
              />
              <TimeTemplate
                icon='pi pi-check-circle text-green-500'
                // label='Tiempo registrado hoy'
                label={notToday ? 'Tiempo registrado del día' : 'Tiempo registrado hoy'}
                time={todaySisuTime}
                min={0}
                max={8}
              />
            </div>
            {
              (isDayEditable || uncompleteDay)
                ? <PrimaryButton
                  label={!isDayFinished ? 'Finalizar día' : 'Sincronizar cambios'}
                  icon={`pi ${saving ? 'pi-spin pi-spinner' : 'pi pi-check'}`}
                  onClick={handleSave}
                  disabled={Boolean(!hasTasks || (isDayFinished && !canSaveChanges))}
                  borderAccent
                  rounded
                />
                : <div className='w-10rem' />
            }
          </SectionHeading>
          {
            !isDayFinished
              ? <FinishDay
                visible={finishDayVisible}
                setVisible={setFinishDayVisible}
                todaySisuTime={todaySisuTime}
                todayBilledTime={todayBilledTime}
                onSubmit={handleSubmit}
              />
              : <SaveAfterFinished
                visible={finishDayVisible}
                setVisible={setFinishDayVisible}
                todaySisuTime={todaySisuTime}
                todayBilledTime={todayBilledTime}
                onSubmit={handleSubmitAfterFinished}
              />
          }
        </FieldContainer>
      </SectionHeading>
    </TabMenuContentContainer>
  )
}

export default TimeSummary
