import { DateTime } from 'luxon'
import { REPORT_PERIOD_BY_LANG } from '../consts/date.consts'

export const roundReportData = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'number') {
      obj[key] = parseFloat(obj[key].toFixed(2))
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      roundReportData(obj[key])
    }
  }
  return obj
}

export const getUserWithMostDays = (dataset) => {
  if (!dataset || dataset.length === 0) return null
  const days = Object.keys(dataset[0].week).length
  let user = dataset[0]
  dataset.forEach((item) => {
    const userDays = Object.keys(item.week).length
    if (userDays > days) {
      user = item
    }
  })
  return user
}

/**
 * Calculate end date from a start date and a period type
 * Example: '2024-11-01' + 'weekly' => '2024-11-08'
 *
 * @param {string} period
 * @param {DateTime} startDate
 * @param {'es' | 'eng'} lang
 * @returns {{startDate, endDate}}
 */
export const calculateDateRangeWithPeriodAndStartDate = (period, startDate, lang = 'es', endDate = null) => {
  let newStartDate = startDate
  let calculatedEndDate

  const getValidDate = (date) => {
    if (date.isValid) {
      return date
    }
    if (DateTime.fromJSDate(date).isValid) {
      return DateTime.fromJSDate(date)
    }
    if (DateTime.fromISO(date).isValid) {
      return DateTime.fromISO(date)
    }
  }
  switch (period) {
  case REPORT_PERIOD_BY_LANG[lang].DIARIO:
    newStartDate = getValidDate(startDate)
    calculatedEndDate = getValidDate(startDate)
      .set({ hour: 12 })
      .startOf('day')
    break
  case REPORT_PERIOD_BY_LANG[lang].SEMANAL:
    newStartDate = getValidDate(startDate)
      .startOf('week')
      .set({ hour: 12 })
    calculatedEndDate = getValidDate(startDate)
      .endOf('week')
      .set({ hour: 12 })
    break
  case REPORT_PERIOD_BY_LANG[lang].MENSUAL:
    newStartDate = getValidDate(startDate)
      .startOf('month')
      .set({ hour: 12 })
    calculatedEndDate = getValidDate(startDate)
      .endOf('month')
      .set({ hour: 12 })
    break
  case REPORT_PERIOD_BY_LANG[lang].ANUAL:
    newStartDate = getValidDate(startDate)
      .startOf('year')
      .set({ hour: 12 })
    calculatedEndDate = getValidDate(startDate)
      .endOf('year')
      .set({ hour: 12 })
    break
  case REPORT_PERIOD_BY_LANG[lang].CUSTOM:
    newStartDate = getValidDate(startDate)
    calculatedEndDate = getValidDate(endDate)
    break
  default:
    throw new Error('Periodo no soportado, las opciones son: ' + Object.values(REPORT_PERIOD_BY_LANG[lang]).join(', '))
  }
  return { startDate: newStartDate, endDate: calculatedEndDate }
}
