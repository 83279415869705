import React, { useEffect } from 'react'
import useSessionContext from '../../hooks/contextConsumers/useSessionContext.hook'
import { useNavigate } from 'react-router-dom'
import { BASE_ROUTE_PER_ROLE, Rol as TEAM_MEMBER_ROLES } from '../../consts/roles.consts'

export default function Home () {
  const { user } = useSessionContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname !== '/') return
    if (user.rol === TEAM_MEMBER_ROLES.ADMIN) {
      navigate(BASE_ROUTE_PER_ROLE.admin)
    } else {
      navigate(BASE_ROUTE_PER_ROLE.teamMember)
    }
  }, [])
  return (
    <span>👀</span>
  )
}
