import React from 'react'
import MainTitle from '../../../components/molecules/titles/MainTitle.molecule'

const DashboardHeader = () => {
  return (
    <MainTitle
      className='mb-4'
      title='Dashboard'
    />
  )
}

export default DashboardHeader
