import React from 'react'
import MainTitle from '../../../components/molecules/titles/MainTitle.molecule'

const TaskChangeLogHeader = ({
  verifyTaskLoading = false,
  task,
  isValidTaskId
}) => {
  return (
    <MainTitle
      className='task-history-title mb-4'
      title={'Historial de cambios'}
      subtitle={
        verifyTaskLoading
          ? <i className='pi pi-spin pi-spinner' />
          : isValidTaskId ? `ID Tarea: ${task?.nameTag}-${task?.taskNumberByTeamMember}` : ''
      }
      goBack={Boolean(isValidTaskId)}
    />
  )
}

export default TaskChangeLogHeader
