import React from 'react'
import ClientsHeader from './controllers/ClientsHeader.controller'
import ClientsTable from './controllers/ClientsTable.controller'

const Clients = () => {
  return (
    <>
      <ClientsHeader/>
      <ClientsTable/>
    </>
  )
}

export default Clients
