import React from 'react'
import { Tag as PrimeTag } from 'primereact/tag'

const Tag = ({ children, ...props }) => {
  return (
    <PrimeTag {...props}>
      {children}
    </PrimeTag>
  )
}
export default Tag
