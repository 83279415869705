import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import { TeamMemberProjectRoleContainer } from '../../../components/layouts/containers/TeamMemberProjectRoleContainer.container'
import { Rol } from '../../../consts/roles.consts'
import WeekHoursReport from './WeekHoursReport.controller'
import HoursByProjectReport from './HoursByProjectReport.controller'
import HoursByClientReport from './HoursByClientReport.controller'
import HoursByTeamAndTeamMemberReport from './HoursByTeamAndTeamMemberReport.controller'
import { useGetIfUserHasRole } from '../../../utils/roles.utils'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import TeamHoursConsumption from './TeamHoursConsumption.controller'

const GeneralReportContent = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentDataTableRef = useRef(null)

  const tabs = [
    '/reporte-general/carga-semanal',
    '/reporte-general/horas-proyecto',
    '/reporte-general/horas-cliente',
    '/reporte-general/horas-equipo',
    '/reporte-general/consumos-equipo'
  ]

  const initialTabIndex = tabs.indexOf(location.pathname)
  const [activeIndex, setActiveIndex] = useState(initialTabIndex === -1 ? 0 : initialTabIndex)

  const handleTabChange = (e) => {
    setActiveIndex(e.index)
    navigate(tabs[e.index])
  }

  useEffect(() => {
    const newIndex = tabs.indexOf(location.pathname)
    if (newIndex !== -1 && newIndex !== activeIndex) {
      setActiveIndex(newIndex)
    } else if (location.pathname === '/reporte-general') {
      navigate(tabs[activeIndex], { replace: true })
    }
  }, [location.pathname, activeIndex, tabs, navigate])

  const exportReport = () => {
    currentDataTableRef.current.exportCSV()
  }

  const userCanDownload = useGetIfUserHasRole([Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT])

  const downloadCsvButton = (isDisabled = false) => <PrimaryButton
    label='Descargar'
    icon='pi pi-download'
    className='h-fit mr-3'
    style={{ marginLeft: 'auto', opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto' }}
    onClick={isDisabled ? null : exportReport}
    hidden={!userCanDownload}
  />

  return (
    <div id="general-report">
      <TeamMemberProjectRoleContainer roles={[Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT]}>
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          <TabPanel className='consumo-semanal' header='Consumo semanal'>
            <WeekHoursReport ref={currentDataTableRef} />
          </TabPanel>
          <TabPanel header='Rendimiento por proyectos'>
            <HoursByProjectReport ref={currentDataTableRef} />
          </TabPanel>
          <TabPanel className='tareas-por-cliente' header='Tareas por cliente'>
            <HoursByClientReport ref={currentDataTableRef} downloadCsvButton={downloadCsvButton} />
          </TabPanel>
          <TabPanel header='Consumos por equipo'>
            <TeamHoursConsumption ref={currentDataTableRef} />
          </TabPanel>
          <TabPanel header='Consumo por integrante'>
            <HoursByTeamAndTeamMemberReport ref={currentDataTableRef} />
          </TabPanel>
        </TabView>
      </TeamMemberProjectRoleContainer>
      <TeamMemberProjectRoleContainer roles={[Rol.TEAM_LEADER]}>
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          <TabPanel header='Rendimiento por proyectos'>
            <HoursByProjectReport ref={currentDataTableRef} />
          </TabPanel>
          <TabPanel className='tareas-por-cliente' header='Tareas por cliente'>
            <HoursByClientReport ref={currentDataTableRef} downloadCsvButton={downloadCsvButton} />
          </TabPanel>
          <TabPanel header='Consumos por equipo'>
            <TeamHoursConsumption ref={currentDataTableRef} />
          </TabPanel>
        </TabView>
      </TeamMemberProjectRoleContainer>
      <TeamMemberProjectRoleContainer roles={[Rol.TEAM_MEMBER]}>
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
          <TabPanel header='Rendimiento por proyectos'>
            <HoursByProjectReport ref={currentDataTableRef} />
          </TabPanel>
        </TabView>
      </TeamMemberProjectRoleContainer>
    </div>
  )
}

export default GeneralReportContent
