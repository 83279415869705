import React from 'react'
import MainTitle from '../../../components/molecules/titles/MainTitle.molecule'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../consts/appRoutes.consts'

const FormHeaderCreateClient = ({
  isCreate = true,
  selectedClient
}) => {
  const navigate = useNavigate()
  let label
  if (isCreate) {
    label = 'Crear'
  } else {
    label = 'Consulta de'
  }
  return (
    <ContentLayoutHeader
      title={`${label} cliente`}
      className='mb-4'
      goBack
      extraContent={
        selectedClient?.code !== 'INT' && !isCreate && process.env.REACT_APP_ENVIROMENT !== 'production' &&
        <PrimaryButton
          style={{
            padding: '8.5px 17.5px',
            height: '41px'
          }}
          label='Ver presupuesto'
          className='text-base'
          icon='pi pi-wallet text-xl'
          onClick={() => {
            navigate(AppRoutes.clientes.presupuestoCliente, {
              state: {
                ...selectedClient
              }
            })
          }}
        />
      }
    />
  )
}

export default FormHeaderCreateClient
