import React from 'react'

function Divider ({
  vertical = false,
  className,
  stroke = 'md'
}) {
  return (
    <div className={`surface-900 p-divider p-component p-divider-${vertical ? 'vertical' : 'horizontal'} p-divider-solid p-divider-left ${className} ${stroke}`} role="separator">
      <div className="p-divider-content"></div>
    </div>
  )
}

export default Divider
