/* eslint-disable */
import React, { useState, useEffect } from "react";
import ClientProjectChip from "../../../../../components/molecules/ClientProjectChip.molecule";
import IconWithTooltip from "../../../../../components/molecules/IconWithTooltip.molecule";
import { WarningBadge } from "../../../../../components/molecules/CellReportBadges.molecule";
import { TimeDifferenceBadge } from "../../components/CellReportBadges";

/**
 * @returns {import("primereact/column").ColumnProps[]}
 */
const ClientColumnContent = (
  clientFilterTemplate,
  teamMemberFilterTempalte,
  moduleFilterTemplate,
  categoryFilterTemplate,
  cm,
  setSelectedTask,
  isHoldingCtrlDown,
  onLeftClickTeamMemberName,
  onLeftClickModuleName,
  onLeftClickCategoryName,
  onLeftClickProjectName,
  onLeftClickClientCode,
  updatingTask,
  hasSpecialCategories
) => {
  const [isHoldingCtrl, setIsHoldingCtrl] = useState(false);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Control") {
        setIsHoldingCtrl(true);
      }
    };

    const onKeyUp = (e) => {
      if (e.key === "Control") {
        setIsHoldingCtrl(false);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const categoryColumn = (exportable = false, hidden = false) => ({
    field: "categoryTitle",
    header: "Categoría",
    colSpan: 1,
    className: "white-space-nowrap overflow-hidden text-overflow-ellipsis",
    style: { minWidth: "180px", padding: "0.5rem", overflow: "hidden" },
    filter: true,
    filterMatchMode: "in",
    filterField: hasSpecialCategories ? "specialCategoryId" : "categoryId",
    filterElement: categoryFilterTemplate,
    showFilterMenuOptions: false,
    showFilterMatchModes: false,
    exportable,
    hidden,
    body: (rowData) => (
      <div
        className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
          isHoldingCtrlDown ? "ctrl-selectable" : ""
        }`}
        onClick={() => {
          if (isHoldingCtrlDown) {
            onLeftClickCategoryName(
              rowData[hasSpecialCategories ? "specialCategoryId" : "categoryId"]
            );
          }
        }}
      >
        {rowData?.specialCategoryTitle || rowData?.categoryTitle}
      </div>
    )
  });

  const taskColumn = (exportable = false, hidden = false) => ({
    body: (rowData) => {
      return (
        <div className="w-full flex justify-content-between align-items-center gap-2">
          <span>{rowData.task}</span>
          {rowData.comment ? (
            <IconWithTooltip
              tooltipValue={rowData.comment}
              iconClassName="pi pi-comment"
            />
          ) : null}
        </div>
      );
    },
    field: "task",
    header: "Tarea",
    style: { minWidth: "360px", flex: "1 0 360px", padding: "0.5rem" },
    filter: true,
    filterMatchMode: "contains",
    filterField: "task",
    showFilterMenuOptions: false,
    exportable,
    hidden
  });

  const moduleColumn = (exportable = false, hidden = false) => ({
    field: "module",
    header: "Módulo",
    colSpan: 1,
    style: { minWidth: "180px", flex: "1 0 180px", padding: "0.5rem", overflow: "hidden" },
    filter: true,
    filterMatchMode: "in",
    filterElement: moduleFilterTemplate,
    showFilterMenuOptions: false,
    showFilterMatchModes: false,
    filterField: "moduleId",
    exportable,
    hidden,
    body: (rowData) => (
      <div
        className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
          isHoldingCtrlDown ? "ctrl-selectable" : ""
        }`}
        onClick={() => {
          if (isHoldingCtrlDown) {
            onLeftClickModuleName(rowData.moduleId);
          }
        }}
      >
        {rowData?.module}
      </div>
    )
  });

  return [
    {
      header: "Fecha",
      field: "date",
      hidden: true
    },
    {
      header: "Cliente",
      field: "clientName",
      hidden: true
    },
    {
      body: (rowData) => {
        return (
          <div className="flex gap-2 align-items-center">
            <ClientProjectChip
              clientCode={rowData.clientCode}
              projectCode={rowData.projectCode}
              className={`${isHoldingCtrlDown ? "ctrl-selectable-badge" : ""}`}
              onClientClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickClientCode(rowData.clientCode);
                }
              }}
              onProjectClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickProjectName(rowData.projectName);
                }
              }}
            />
          </div>
        );
      },
      field: "projectCode",
      header: "Proyecto",
      colSpan: 1,
      style: { minWidth: "102px", maxWidth: "102px", padding: "0.5rem" },
      filter: true,
      filterElement: clientFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterMatchMode: "in",
      filterField: "projectName"
    },
    moduleColumn(true, true),
    {
      header: "Grupo de categoría",
      field: "category",
      hidden: true
    },
    categoryColumn(true, true),
    ...(hasSpecialCategories
      ? [
          {
            header: "Categoría Especial",
            field: "specialCategoryTitle",
            hidden: true
          }
        ]
      : []),
    {
      field: "teamMemberNameTag",
      header: "Integrante",
      colSpan: 1,
      hidden: true,
      filter: true,
      filterMatchMode: "in",
      filterElement: teamMemberFilterTempalte,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: "teamMemberNameTag",
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
            isHoldingCtrlDown ? "ctrl-selectable" : ""
          }`}
          onClick={() => {
            if (isHoldingCtrlDown) {
              onLeftClickTeamMemberName(rowData.teamMemberNameTag);
            }
          }}
        >
          {rowData?.teamMemberNameTag}
        </div>
      )
    },
    taskColumn(true, true),
    moduleColumn(),
    categoryColumn(),
    taskColumn(),
    {
      field: "sisuTime",
      header: "Horas trabajadas",
      colSpan: 1,
      style: { minWidth: "115px", flex: "0 0 115px", padding: "0.5rem" },
      body: (rowData) => {
        return (
          <div className="flex gap-1">
            <span>{rowData?.sisuTime}</span>
            {rowData?.sisuTimeModifiedAfterFinished ? (
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            ) : null}
          </div>
        );
      },
      sortable: true,
      sortField: "sisuTime"
    },
    {
      field: "billedTime",
      header: "Horas facturadas",
      sortable: true,
      sortField: "billedTime",
      colSpan: 1,
      body: (rowData) => {
        return (
          <div className="flex gap-1">
            <span className={`${rowData?.billedTime === 'N/A' ? 'opacity-30' : ''}`}>{rowData?.billedTime}</span>
            {rowData?.billedTimeModifiedAfterFinished ? (
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            ) : null}
          </div>
        );
      },
      style: { minWidth: "115px", flex: "0 0 115px", padding: "0.5rem" }
    },
    {
      header: "Diferencia",
      field: "timeDifference",
      sortable: true,
      style: { minWidth: "115px", flex: "0 0 115px", paddingLeft: "7px" },
      body: (rowData) => (
        <div className="flex gap-1 w-4rem justify-content-between">
          <span className={`${rowData?.timeDifference === 'N/A' ? 'opacity-30' : ''}`}>{rowData.timeDifference}</span>
          {rowData.billedTime !== rowData.sisuTime && (
            // <ErrorBadge />
            <TimeDifferenceBadge
              value={rowData.timeDifference}
              minTreshold={0}
              maxTreshold={0}
              referenceValue={0}
              tooltip={
                rowData.timeDifferenceComment
                  ? `Motivo: ${rowData.timeDifferenceComment}`
                  : "Sin comentarios"
              }
            />
          )}
        </div>
      )
    },
    {
      body: (item) => {
        return item.taskId === updatingTask?.taskId ? (
          <IconWithTooltip
            iconClassName="pi pi-spin pi-spinner"
            tooltipValue="Guardando cambios"
          />
        ) : (
          <i
            className="pi pi-ellipsis-v text-xl cursor-pointer"
            onClick={(event) => {
              cm?.current.show(event);
              setSelectedTask(item);
            }}
          />
        );
      },
      colSpan: 1,
      style: {
        minWidth: "30px",
        flex: "0 0 30px",
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center"
      }
    }
  ];
};

export default ClientColumnContent;
