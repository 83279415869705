import React from 'react'
import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'
import DatePicker from '../../../../components/atoms/DatePicker.atom'
import { DateTime } from 'luxon'
const { RangePicker } = DatePicker

/** @type {import('./PeriodPicker')["rangePresets"]} */
const rangePresets = () => {
  const today = DateTime.local().set({ hour: 12 })

  return [
    {
      label: 'Hoy',
      value: [
        today,
        today
      ]
    },
    {
      label: 'Semana actual',
      value: [
        today.startOf('week'),
        today.endOf('week')
      ]
    },
    {
      label: 'Semana anterior',
      value: [
        today.minus({ weeks: 1 }).startOf('week').set({ hour: 12 }),
        today.minus({ weeks: 1 }).endOf('week').set({ hour: 12 })
      ]
    },
    {
      label: 'Este mes',
      value: [
        today.startOf('month'),
        today.endOf('month')
      ]
    },
    {
      label: 'Mes anterior',
      value: [
        today.minus({ months: 1 }).startOf('month').set({ hour: 12 }),
        today.minus({ months: 1 }).endOf('month').set({ hour: 12 })
      ]
    },
    {
      label: 'Este año ',
      value: [
        today.startOf('year'),
        today.endOf('year')
      ]
    }
  ]
}

/** @type {import('./PeriodPicker')["default"]} */
const PeriodPicker = ({
  dateRange,
  setDateRange,
  ...props
}) => {
  return (
    <ConfigProvider locale={esES}>
      <RangePicker
        value={dateRange}
        presets={rangePresets()}
        onChange={setDateRange}
        separator={<p className='clr-secondary'>-</p>}
        {...props}
      />
    </ConfigProvider>
  )
}

export default PeriodPicker
