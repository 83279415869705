import React from 'react'
import Chip from '../atoms/misc/Chip.atom'
import DropdownWithTitleSubtitle from '../molecules/DropdownWithTitleSubtitle.molecule'
import { categoryDropdownOptionGroupTemplate } from '../../utils/category.utils'
import DropdownCategoryFilter from './DropdownCategoryFilter.compound'

const DropdownCategoryFilterInplace = ({
  value,
  onChange,
  label = 'Elige una categoría',
  placeholder = 'Elige una categoría',
  name = 'categoryGroup',
  options,
  optionValue,
  optionLabel = 'categoryTitle',
  error,
  withoutChip = false,
  dropdownRef,
  isEditingCategoryDropdown,
  setIsEditingCategoryDropdown,
  loading,
  dropdownInputProps,
  ...props
}) => {
  optionValue = options?.[0]?.specialCategoryID ? 'specialCategoryID' : 'categoryID'
  options = options?.map(o => ({ ...o, categoryFull: o?.categoryGroup + o?.categoryTitle + o?.categorySubtitle }))
  optionLabel = 'categoryFull'
  if (options) {
    if (options?.[0]?.specialCategoryID) {
      options = options?.sort((a, b) => a?.categoryTitle?.localeCompare(b?.categoryGroup))
    } else {
      options = options?.sort((a, b) => a?.categoryTitle?.localeCompare(b?.categoryTitle))
    }
  }

  const getCurrentOption = (value, key) => {
    return options?.find(o => o?.[key || optionValue] === value)
  }

  const displayedCategory = value
    ? (getCurrentOption(value)?.categoryTitle || getCurrentOption(value)?.categoryGroup)
    : label

  const ChipTemplate = () => {
    return (
      <Chip
        className={`btn btn-primary w-full ${error ? 'error-chip' : ''}`}
        template={
          <span className='p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem'>
            {displayedCategory} {error ? <i className='pi pi-exclamation-triangle' data-pr-tooltip="Este es un campo requerido" data-pr-position="bottom" /> : null}
          </span>
        }
        onClick={() => {
          setIsEditingCategoryDropdown(true)
          setTimeout(() => {
            dropdownRef.current?.show()
          }, 100)
        }}
      />
    )
  }

  return (
    <div className='dropdown-category-filter'>
      {
        isEditingCategoryDropdown
          ? (
            <DropdownCategoryFilter
              ref={dropdownRef}
              value={value}
              onChange={onChange}
              name={name}
              options={options}
              optionValue={optionValue}
              optionLabel={optionLabel}
              error={error}
              placeholder={placeholder}
              dropdownInputProps={dropdownInputProps}
            />
          )
          : (
            <ChipTemplate/>
          )
      }
    </div>
  )
}

export default DropdownCategoryFilterInplace
