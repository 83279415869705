import React from 'react'
import useThemeContext from '../../../hooks/contextConsumers/useThemeContext.hook'
import InputSwitch from '../../../components/atoms/InputSwitch.atom'
import { THEME_MODES } from '../../../consts/themes.consts'

const ThemeToggler = () => {
  const { theme, changeTheme } = useThemeContext()
  return (
    <div className='font-mulish theme-toggler flex flex-column gap-3'>
      <span className='font-cairo font-semibold'>Seleccionar tema</span>
      <div className='switch-container flex align-items-center'>
        <InputSwitch
          name='theme'
          checked={theme === THEME_MODES.LIGHT}
          onChange={changeTheme}
        />
        <span>{theme === THEME_MODES.LIGHT ? 'Modo claro' : 'Modo oscuro'}</span>
      </div>
    </div>
  )
}

export default ThemeToggler
