import React from 'react'
import PropTypes from 'prop-types'
import MultiSelect from '../atoms/MultiSelect.atom'
import Label from '../atoms/Label.atom'
import Tooltip from '../atoms/Tooltip.atom'
import { FiltroGuiaIcon } from './FiltroGuiaIcon.molecule'

export default function MultiSelectFilter ({ maxSelectedLabels = 3, ...props }) {
  function lastLabel (option) {
    if (props.optionValue) {
      return option === props.value.at(-1)
    }
    return option[props.optionLabel] === props.value.at(-1)[props.optionLabel]
  }
  function selectedItemTemplate (option) {
    if (option) {
      if (props.optionValue && props.options && props.options.length > 0) {
        const label = props.options.find((e) => e[props.optionValue] === option)[props.optionLabel]
        return <span>{label}{lastLabel(option) ? '' : ','} </span>
      }
      return <span>{option[props.optionLabel]}{lastLabel(option) ? '' : ','} </span>
    }
  }
  const selectedItemsLabel = props.selectedItemsLabel ? props.selectedItemsLabel : 'elementos seleccionados'
  const forId = `multiSelect-form-${props.label?.replace(/ /g, '-')}`
  return (
    <div className='field'>
      <div className="flex justify-content-between mb-2">
        {props.label && (
          <Label htmlFor={forId}>
            {props.label} &nbsp;
            {props.helpText && (
              <>
                <Tooltip target={`.${forId}`} className="max-w-20rem">
                  {props.helpText}
                </Tooltip>
                <i className={`pi pi-question-circle ${forId}`} />
              </>
            )}
          </Label>
        )}
        <FiltroGuiaIcon
          name={props.name}
          esFiltroGuia={props.filtroGuia != null ? props.filtroGuia === props.name : undefined}
          onToggleFiltroGuia={props.onToggleFiltroGuia}
        />
      </div>
      <MultiSelect
        options={props.options}
        optionGroupLabel={props.optionGroupLabel}
        itemTemplate={props.itemTemplate}
        optionGroupChildren={props.optionGroupChildren}
        onChange={props.onChange}
        name={props.name}
        optionLabel={props.optionLabel}
        placeholder={props.placeholder}
        value={props.value}
        optionValue={props.optionValue}
        inputId={forId}
        filter={props.filter}
        selectedItemsLabel={`{0} ${selectedItemsLabel}`}
        selectedItemTemplate={props.customSelectedItemTemplate ? props.customSelectedItemTemplate : selectedItemTemplate}
        maxSelectedLabels={maxSelectedLabels}
        showClear={props.showClear}
        className={props.className}
        filterPlaceholder={props.filterPlaceholder}
        emptyFilterMessage={props.emptyFilterMessage}
      />
    </div>
  )
}

MultiSelectFilter.propTypes = {
  label: PropTypes.string,
  maxSelectedLabels: PropTypes.number,
  selectedItemsLabel: PropTypes.string,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  optionValue: PropTypes.string,
  optionGroupLabel: PropTypes.string,
  optionGroupChildren: PropTypes.string,
  itemTemplate: PropTypes.func,
  customSelectedItemTemplate: PropTypes.func,
  showClear: PropTypes.bool,
  className: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  filter: PropTypes.bool,
  filtroGuia: PropTypes.string,
  onToggleFiltroGuia: PropTypes.func
}

MultiSelectFilter.defaultProps = {
  emptyFilterMessage: 'No hay resultados',
  filterPlaceholder: 'Selecciona una opción'
}
