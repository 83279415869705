export const TYPE_OF_TOOLTIP_COLOR_VARIANTS = {
  circle: 0,
  square: 1,
  squareRounded: 2
}

export const DOMAIN_DEFAULT = [0, 'auto']

// Nuevos colores por default apartir de los definidos en la grafica de distribucion de variedades medleys
export const defaultColors = [
  '#023E66',
  '#FC3D39',
  '#14B8A6'
]

export const TYPES_FIGURES_LINE_LEGENDS = {
  normalLine: 0,
  dashedLine: 1
}

export const DEFAULT_TICK_STYLE = {
  fontSize: '14px',
  fontFamily: 'Mulish',
  fill: 'var(--text-color)'
}

export const OPACITY_HIGHLIGHT_LEVELS = {
  HIGHLIGHTED: 1,
  NOT_HIGHLIGHTED: 0.3
}
