import React from 'react'
import { ReactComponent as PaperIcon } from '../design/assests/images/icons/paper.svg'
import { ReactComponent as SuitcaseIcon } from '../design/assests/images/icons/suitcase.svg'
import { ReactComponent as LightBulbIcon } from '../design/assests/images/icons/lightbulb.svg'
import { ReactComponent as ConversationIcon } from '../design/assests/images/icons/conversation.svg'

export const categoryDropdownOptionGroupTemplate = (option) => {
  let icon

  switch (option.label.toLowerCase()) {
  case 'desarrollo':
    icon = <i className="pi pi-check-circle"></i>
    break
  case 'llamadas internas':
    icon = <SuitcaseIcon className='icon' />
    break
  case 'sdv':
    icon = <ConversationIcon className='icon' />
    break
  case 'administrativo':
    icon = <PaperIcon className='icon' />
    break
  case 'llamadas con cliente':
    icon = <LightBulbIcon className='icon' />
    break
  }

  return (
    <div className='flex w-full font-cairo'>
      {icon}
      {option.label}
    </div>
  )
}
