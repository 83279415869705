import React from 'react'
import BadgeWithTooltip from '../../molecules/BadgeWithTooltip.molecule'
import PropTypes from 'prop-types'
import StackedBadges from '../../../design/assests/images/icons/StackedBadges.svg'
import Tooltip from '../../atoms/Tooltip.atom'
import Badge from '../../atoms/Badge.atom'

const MultiplesInvernaderosSubtitleWithBadge = ({ invernaderos }) => {
  const defaultTooltipPosition = 'bottom'
  const setInvernaderos = () => {
    const allInvernaderos = invernaderos?.sort((a, b) => {
      if (a.id === null && b.id === null) {
        return 0
      } else if (a.id === null) {
        return -1
      } else if (b.id === null) {
        return 1
      } else {
        return 0
      }
    })
    let invernaderosInToolTip = null
    let invernaderosInBadge = allInvernaderos

    if (allInvernaderos?.length > 5) {
      invernaderosInBadge = allInvernaderos.slice(0, 4).sort((a, b) => {
        if (a.id && !b.id) {
          return -1
        } else if (!a.id && b.id) {
          return 1
        } else {
          return 0
        }
      })
      invernaderosInToolTip = allInvernaderos.slice(4)
    }

    return {
      invernaderosInBadge,
      invernaderosInToolTip
    }
  }

  const { invernaderosInBadge, invernaderosInToolTip } = setInvernaderos()
  const tooltipOfInvernaderosInToolTipTemplate = () => {
    if (invernaderosInToolTip) {
      return (
        <div className='flex flex-column gap-1'>
          {
            invernaderosInToolTip.map((invernadero, index) => (
              <div key={index} className='flex gap-1 align-items-center'>
                <BadgeWithTooltip
                  color={invernadero.color || invernadero.colorValor}
                  value={invernadero.nombre}
                />
                <span>{invernadero.tooltipValue}</span>
              </div>
            ))
          }
        </div>
      )
    }
    return ''
  }

  return (
    <div className="stacked-badges">
      {invernaderosInBadge?.length > 0 && (
        <>
          <img src={StackedBadges}/>
          <div className='stacked-badges__divider'/>
        </>
      )
      }
      {
        invernaderosInBadge?.map((invernadero, index) => (
          <BadgeWithTooltip
            key={index}
            color={invernadero.color || invernadero.colorValor}
            value={invernadero.nombre}
            position = {invernadero.tooltipPosition || defaultTooltipPosition}
            tooltipValue={invernadero.tooltipValue || ''}
          />
        ))
      }
      {invernaderosInToolTip &&
      <>
        <Tooltip target='.invernaderos-in-tooltip' position = 'bottom'>
          {tooltipOfInvernaderosInToolTipTemplate()}
        </Tooltip>
        <Badge className='invernaderos-in-tooltip small-badge small-badge--extra-badge' value={`+${invernaderosInToolTip?.length}`}/>
      </>
      }
    </div>
  )
}

MultiplesInvernaderosSubtitleWithBadge.propTypes = {
  invernaderos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    color: PropTypes.string,
    nombre: PropTypes.string
  }))
}

export default MultiplesInvernaderosSubtitleWithBadge
