/* eslint-disable */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Line,
  LineChart as LineChartRecharts,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  ReferenceLine,
  Label
} from 'recharts'
import { getBrushStartIndex, getColor, getDataRechartsFromData, getMinAndMaxValueOfData, getUniqueResourcesFromData, thereAreSomeData } from '../../../utils/graphics.utils'
import TooltipMultipleDefault from './components/TooltipMultipleDefault'
import { DEFAULT_TICK_STYLE, defaultColors, DOMAIN_DEFAULT, TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../consts/modules/graphics.consts'
import useYTicksCustom from '../../../hooks/graphics/useYTicksCustom.hook'
import { ScreenMessage } from '../customMessages/ScreenMessage.compound'
import ContainerOfChart from './components/ContainerOfChart'

const LineChart = ({
  width = 1080,
  height = 300,
  margin = {
    top: 25, right: 30, left: 20, bottom: 15
  },
  dataKey = 'dataKey',
  data,
  tooltipTemplate,
  tooltip,
  tooltipConfig = {},
  legendTemplate,
  legend,
  legendConfig = {},
  colorsArray = defaultColors,
  colors = undefined,
  textColors = undefined,
  responsiveContainerConfig = {},
  cartesian = true,
  lineConfig = {},
  brushConfig = {},
  brush = false,
  xAxisDomain = DOMAIN_DEFAULT,
  yAxisDomain = DOMAIN_DEFAULT,
  xAxisFormatter = (value) => value,
  yAxisFormatter = (value) => value,
  yAxiesTicksStep = undefined,
  yTickCount = 20,
  referenceLineConfig = {},
  labelAxiesConfig = {},
  whiteBackground = true,
  yAxisAutoDomain = true,
  yAxisAutoDomainGap = 10,
  xAxisTickStyle = DEFAULT_TICK_STYLE,
  yAxisTickStyle = DEFAULT_TICK_STYLE,
  referenceLinesX = null,
  referenceLinesY = null,
  chartContainerConfig = {}
}) => {
  const DEFAULT_BRUSH_CONFIG = {
    initial: 35,
    height: 30,
    stroke: '#8884d8'
  }
  const brushInitial = brushConfig?.initial || DEFAULT_BRUSH_CONFIG.initial
  const brushStroke = brushConfig?.stroke || DEFAULT_BRUSH_CONFIG.stroke
  const brushHeight = brushConfig?.height || DEFAULT_BRUSH_CONFIG.height
  const brushCustomStartIndex = brushConfig?.customStartIndex
  const brushClassName = brushConfig?.className

  const DEFAULT_RESPONSIVE_CONTAINER_CONFIG = {
    width: '100%',
    height: '100%',
    minWidth: '300px',
    minHeight: '300px',
    maxWidth: '100%',
    maxHeight: '100%'
  }
  const responseContainerWidth = responsiveContainerConfig?.width || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.width
  const responseContainerHeight = responsiveContainerConfig?.height || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.height
  const responseContainerMinWidth = responsiveContainerConfig?.minWidth || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minWidth
  const responseContainerMinHeight = responsiveContainerConfig?.minHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minHeight
  const responseContainerMaxWidth = responsiveContainerConfig?.maxWidth || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.maxWidth
  const responseContainerMaxHeight = responsiveContainerConfig?.maxHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.maxHeight


  const DEFAULT_CHART_CONTAINER_CONFIG = {
    title: '',
    iconClassName: '',
    className: '',
    shadow: true
  }
  const chartContainerTitle = chartContainerConfig?.title ?? DEFAULT_CHART_CONTAINER_CONFIG.title
  const chartContainerIconClassName = chartContainerConfig?.iconClassName ?? DEFAULT_CHART_CONTAINER_CONFIG.iconClassName
  const chartContainerClassName = chartContainerConfig?.className ?? DEFAULT_CHART_CONTAINER_CONFIG.className
  const chartContainerShadow = chartContainerConfig?.shadow ?? DEFAULT_CHART_CONTAINER_CONFIG.shadow

  const DEFAULT_LINE_CONFIG = {
    type: 'monotone',
    dotTemplate: undefined,
    activeDotTemplate: undefined,
    strokeDasharray: undefined,
    strokeDashfun: undefined,
    strokeWidth: 2
  }
  const lineType = lineConfig?.type || DEFAULT_LINE_CONFIG.type
  const lineDotTemplate = lineConfig?.dotTemplate || DEFAULT_LINE_CONFIG.dotTemplate
  const lineActiveDotTemplate = lineConfig?.activeDotTemplate || DEFAULT_LINE_CONFIG.activeDotTemplate
  const lineStrokeDasharray = lineConfig?.strokeDasharray || DEFAULT_LINE_CONFIG.strokeDasharray
  const lineStrokeDashfun = lineConfig?.strokeDashfun || DEFAULT_LINE_CONFIG.strokeDashfun
  const lineStrokeWidth = lineConfig?.strokeWidth || DEFAULT_LINE_CONFIG.strokeWidth

  const DEFAULT_TOOLTIP_CONFIG = {
    colorType: TYPE_OF_TOOLTIP_COLOR_VARIANTS.square,
    nameFormatter: undefined,
    valueFormatter: undefined,
    unit: ''
  }
  const tooltipUnit = tooltipConfig?.unit ?? DEFAULT_TOOLTIP_CONFIG.unit
  const tooltipValueFormatter = tooltipConfig?.valueFormatter ?? DEFAULT_TOOLTIP_CONFIG.valueFormatter
  const tooltipNameFormatter = tooltipConfig?.nameFormatter ?? DEFAULT_TOOLTIP_CONFIG.nameFormatter
  const tooltipColorType = tooltipConfig?.colorType ?? DEFAULT_TOOLTIP_CONFIG.colorType

  const DEFAULT_LEGEND_CONFIG = {
    nameTemplate: (name) => name
  }

  const legendNameTemplate = legendConfig?.nameTemplate ?? DEFAULT_LEGEND_CONFIG.nameTemplate

  const DEFAULT_AXIES_LABEL_CONFIG = {
    x: {
      value: '',
      position: 'insideBottom',
      className: 'font-bold',
      fill: '#000'
    },
    y: {
      value: '',
      position: { x: 80, y: -15 },
      className: 'font-bold',
      fill: '#000'
    }
  }

  const xAxiesLabelConfig = {
    ...DEFAULT_AXIES_LABEL_CONFIG.x,
    ...labelAxiesConfig.x
  }

  const yAxiesLabelConfig = {
    ...DEFAULT_AXIES_LABEL_CONFIG.y,
    ...labelAxiesConfig.y
  }

  const [currentBrush, setCurrentBrush] = useState({})

  const yAxiesTicks = useYTicksCustom({
    yAxiesTicksStep,
    data,
    yAxisDomain
  })

  const tooltipRender = (rawTooltipData) => {
    if (!rawTooltipData?.active || !rawTooltipData?.payload?.length) return null
    const payloadData = rawTooltipData?.payload?.[0]

    const recordData = payloadData?.payload ? payloadData?.payload : {}

    const linesData = rawTooltipData?.payload?.map((point) => {
      const extraInfo = recordData?.extraInfo?.[point?.name] || null
      return {
        name: point?.name,
        value: point?.value,
        color: point?.color,
        xAxisValue: recordData?.[dataKey],
        extraInfo
      }
    })

    if (!tooltipTemplate) {
      return <TooltipMultipleDefault
        data={linesData}
        tooltipUnit={tooltipUnit}
        yLabel={recordData?.[dataKey]}
        valueFormatter={tooltipValueFormatter}
        nameFormatter={tooltipNameFormatter}
        colorType={tooltipColorType}
      />
    }

    return (
      <div className="container-tooltip-graphic">
        {tooltipTemplate(linesData, recordData)}
      </div>
    )
  }

  const legendRender = (rawLegendsData) => {
    const legendsData = rawLegendsData.payload.map((legendData) => {
      return {
        name: legendData.value,
        color: legendData.color,
        textColor: legendData?.textColor
      }
    })
    return (
      <div className="container-legend-graphic">
        {legendTemplate(legendsData)}
      </div>
    )
  }

  const startIndexUsed = Boolean(brushCustomStartIndex) && brushCustomStartIndex >= 0
    ? brushCustomStartIndex
    : getBrushStartIndex(data, brushInitial)

  const rechartsData = useMemo(() => {
    return getDataRechartsFromData(data, 'lineData')
  }, [data])

  const yAxisAutoDomainData = useMemo(() => {
    if (!yAxisAutoDomain) return yAxisDomain
    const startIndexToUse = currentBrush?.startIndex || startIndexUsed
    const endIndexToUse = currentBrush?.endIndex + 1 || rechartsData.length
    const dataRef = data.slice(startIndexToUse, endIndexToUse)
    const {
      min: minData,
      max: maxData
    } = getMinAndMaxValueOfData(dataRef, 'lineData', {
      minGap: yAxisAutoDomainGap,
      maxGap: yAxisAutoDomainGap
    })
    return [minData === -10 ? 0 : minData, maxData]
  }, [rechartsData, currentBrush])

  const thereAreData = useMemo(() => thereAreSomeData(data, 'lineData'), [data])

  if (!thereAreData) {
    return (
      <ContainerOfChart withWhiteBackgroundColor={whiteBackground}>
        <ScreenMessage
          title="No hay datos disponibles"
          message="Intenta cambiar los filtros aplicados para ver la información."
        />
      </ContainerOfChart>
    )
  }
  const [showLabelY, setShowLabelY] = useState(false)
  return (
    <ContainerOfChart
      withWhiteBackgroundColor={true}
      title={chartContainerTitle}
      iconClassName={chartContainerIconClassName}
      className={chartContainerClassName}
      shadow={chartContainerShadow}
    >
      <ResponsiveContainer
        width={responseContainerWidth}
        height={responseContainerHeight}
        minWidth={responseContainerMinWidth}
        minHeight={responseContainerMinHeight}
        maxHeight={responseContainerMaxHeight}
      >
        <LineChartRecharts
          width={width}
          height={height}
          data={rechartsData}
          margin={margin}
        >
          {cartesian && <CartesianGrid strokeDasharray="3 3" />}

          <XAxis
            dataKey={dataKey}
            domain={xAxisDomain}
            tickFormatter={xAxisFormatter}
            label={xAxiesLabelConfig}
            style={xAxisTickStyle}
          />
          <YAxis
            orientation='left'
            domain={yAxisAutoDomainData}
            tickFormatter={yAxisFormatter}
            ticks={yAxiesTicks}
            label={yAxiesLabelConfig}
            tickCount={yTickCount}
            style={yAxisTickStyle}
          />

          {/* {referenceLineY !== null && <ReferenceLine y={referenceLineY} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />} */}
          {/* {referenceLineX !== null && <ReferenceLine x={referenceLineX} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />} */}
          {referenceLinesX && referenceLinesX.map((lineConfig, index) => (
            <ReferenceLine key={`reference-line-x-${index}`} {...lineConfig} />
          ))}
          {referenceLinesY && referenceLinesY.map((lineConfig, index) => (
            <ReferenceLine key={`reference-line-x-${index}`} {...lineConfig} />
          ))}
          {tooltip && <Tooltip content={tooltipRender} />}

          {legend && <Legend content={legendTemplate ? legendRender : null} formatter={legendNameTemplate || null} />}

          {getUniqueResourcesFromData(data, 'lineData').map((lineLabel, index) => {
            const key = `line-${index}-${lineLabel}`
            let colorCode = '#000000'
            let textColor = '#000000'
            if (typeof colors === 'object') {
              colorCode = colors?.[lineLabel] || getColor(index, colorsArray)
            } else if (typeof colors === 'function') {
              colorCode = colors(lineLabel, index)
            } else {
              colorCode = getColor(index, colorsArray)
            }

            if (typeof textColors === 'object') {
              textColor = textColors?.[lineLabel] || colorCode
            } else if (typeof textColors === 'function') {
              textColor = textColors(lineLabel, index)
            } else {
              textColor = getColor(index, colorsArray)
            }
            const data = {
              color: colorCode,
              textColor: textColor,
              lineLabel
            }
            const strokeDasharray = lineStrokeDashfun ? lineStrokeDashfun(data) : lineStrokeDasharray
            return (
              <Line
                key={key}
                type={lineType}
                dataKey={lineLabel}
                strokeWidth={lineStrokeWidth}
                stroke={colorCode}
                fill={colorCode}
                dot={lineDotTemplate ? (rechartsEvent) => lineDotTemplate(data, rechartsEvent) : undefined}
                activeDot={lineActiveDotTemplate ? (rechartsEvent) => lineActiveDotTemplate(data, rechartsEvent) : undefined}
                strokeDasharray={strokeDasharray}
              />
            )
          })}

          {brush && <Brush
            // onChange={(data) => setCurrentBrush(data)}
            dataKey={dataKey}
            height={brushHeight}
            stroke={brushStroke}
            startIndex={startIndexUsed}
            alwaysShowText={false}
            className={brushClassName}
          />}

        </LineChartRecharts>
      </ResponsiveContainer>
    </ContainerOfChart>
  )
}

LineChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
    bottom: PropTypes.number
  }),
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    lineData: PropTypes.object.isRequired,
    dataKey: PropTypes.string,
    extraInfo: PropTypes.object
  })),
  tooltipTemplate: PropTypes.func,
  tooltip: PropTypes.bool,
  tooltipConfig: PropTypes.shape({
    unit: PropTypes.string,
    valueFormatter: PropTypes.func,
    nameFormatter: PropTypes.func,
    colorType: PropTypes.number
  }),
  legendTemplate: PropTypes.func,
  legend: PropTypes.bool,
  legendConfig: PropTypes.shape({
    nameTemplate: PropTypes.func
  }),
  colorsArray: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  lineConfig: PropTypes.shape({
    type: PropTypes.string,
    dotTemplate: PropTypes.func,
    activeDotTemplate: PropTypes.func,
    strokeDasharray: PropTypes.string,
    strokeDashfun: PropTypes.func
  }),
  brushConfig: PropTypes.shape({
    customStartIndex: PropTypes.number,
    initial: PropTypes.number,
    height: PropTypes.number,
    stroke: PropTypes.string
  }),
  brush: PropTypes.bool,
  cartesian: PropTypes.bool,
  responsiveContainerConfig: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  xAxisDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  yAxisDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  yAxisFormatter: PropTypes.func,
  xAxisFormatter: PropTypes.func,
  yAxiesTicksStep: PropTypes.number,
  yAxisAutoDomain: PropTypes.bool,
  yAxisAutoDomainGap: PropTypes.number,
  yTickCount: PropTypes.number,
  referenceLineConfig: PropTypes.shape({
    y: PropTypes.number,
    x: PropTypes.number
  }),
  labelAxiesConfig: PropTypes.shape({
    x: PropTypes.shape({
      value: PropTypes.string,
      position: PropTypes.oneOfType([
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number
        }),
        PropTypes.string
      ]),
      className: PropTypes.string,
      fill: PropTypes.string
    }),
    y: PropTypes.shape({
      value: PropTypes.string,
      position: PropTypes.oneOfType([
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number
        }),
        PropTypes.string
      ]),
      className: PropTypes.string,
      fill: PropTypes.string
    })
  }),
  whiteBackground: PropTypes.bool
}

export default LineChart
