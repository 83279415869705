import React from 'react'
import SecondaryButton from '../molecules/buttons/SecondaryButton.molecule'
import InputTextForm from '../molecules/InputTextForm.molecule'
import DangerButton from '../molecules/buttons/DangerButton.molecule'
import FieldContainer from '../layouts/containers/FieldContainer.container'
import { isNotEmpty, validateFieldValue } from '../../utils/validations.utils'
import InputNumberForm from '../molecules/InputNumberForm.molecule'
import PropTypes from 'prop-types'

const basicValueOfValidation = { errors: [], value: null, typeValidations: [isNotEmpty] }
const DynamicInputsForm = ({
  label = 'label',
  name = 'name',
  placeholder = '',
  type = 'manage',
  typeInput = 'text',
  addInputLabel = 'Agregar',
  suffixInputNumber = '',
  colInputContainer = 12,
  mdInputContainer = 6,
  value: valueState,
  validationsValue,
  extraValidations = [],
  onChange = () => {},
  onChangeValidations = () => {}
}) => {
  const handleChange = (e) => {
    const defaultValue = typeInput === 'text' ? '' : null
    const value = e?.value || e.target?.value || defaultValue
    const name = e.target?.name || e?.originalEvent.target.name
    const newState = [...valueState]
    const inputIndexData = valueState.findIndex(data => data.optionValue === name)
    newState[inputIndexData].value = value
    onChangeValidations({ newState: validateFieldValue(validationsValue, name, value) })
    onChange({ newState })
  }
  const handleClickDeleteButtons = (e, keyValue) => {
    const newState = valueState.filter(data => data.optionValue !== keyValue)
    const validationsValueEntries = Object.entries(validationsValue)
    const newStateValidationsEntries = validationsValueEntries.filter(([key, value]) => key !== keyValue)
    const newStateValidations = newStateValidationsEntries.reduce((acc, input) => {
      acc[input[0]] = input[1]
      return acc
    }, {})
    onChange({ newState })
    onChangeValidations({ newState: newStateValidations })
  }
  const handleAddNewInput = () => {
    if (valueState.length === 0) {
      onChange({
        newState: [
          {
            optionLabel: `${label} 1`,
            optionValue: name + 1,
            value: ''
          }
        ]
      })
      onChangeValidations({
        newState: {
          ...validationsValue,
          [name + 1]: {
            ...basicValueOfValidation,
            value: '',
            optionValue: name + 1,
            typeValidations: [...basicValueOfValidation.typeValidations, ...extraValidations]
          }
        }
      })
      return
    }
    const valueStateToSort = [...valueState]
    const valuesSorted = valueStateToSort?.sort((a, b) => {
      const numberA = a.optionLabel.split(' ')[1]
      const numberB = b.optionLabel.split(' ')[1]
      const countA = Number(numberA)
      const countB = Number(numberB)

      return countB - countA
    })
    const valueMax = valuesSorted[0]
    const countMax = Number(valueMax.optionLabel.split(' ')[1])
    const countNewMax = countMax + 1
    const newState = [...valueState, {
      optionLabel: `${label} ${countNewMax}`,
      optionValue: name + countNewMax,
      value: ''
    }]
    onChangeValidations({
      newState: {
        ...validationsValue,
        [name + countNewMax]: {
          ...basicValueOfValidation,
          value: '',
          optionValue: name + countNewMax,
          typeValidations: [...basicValueOfValidation.typeValidations, ...extraValidations]
        }
      }
    })
    onChange({ newState })
  }
  const isManage = type === 'manage'
  const isTextTypeInput = typeInput === 'text'
  const isNumberTypeInput = typeInput === 'number'
  return (
    <>
      {
        valueState.map((data, index) => {
          const countLabel = index + 1
          const typesLabels = {
            manage: label + ' ' + countLabel,
            render: data.optionLabel
          }
          const labelToRender = typesLabels[type]
          return (
            <FieldContainer col={colInputContainer} md={mdInputContainer} key={'dynamicInput-' + data.optionValue}>
              {isNumberTypeInput && <InputNumberForm
                suffix={suffixInputNumber}
                minFractionDigits={1}
                maxFractionDigits={4}
                name={data.optionValue}
                label={labelToRender}
                placeholder={placeholder}
                onChange={handleChange}
                value={data.value}
                error={validationsValue[data.optionValue].errors}
              />}
              {isTextTypeInput && <InputTextForm
                name={data.optionValue}
                label={labelToRender}
                placeholder={placeholder}
                onChange={handleChange}
                value={data.value}
                error={validationsValue[data.optionValue].errors}
              />}
              {
                isManage && <DangerButton
                  label="Eliminar"
                  onClick={(e) => handleClickDeleteButtons(e, data.optionValue)}
                />
              }
            </FieldContainer>
          )
        })
      }
      {
        isManage && <SecondaryButton label={addInputLabel} onClick={handleAddNewInput}/>
      }
    </>
  )
}

DynamicInputsForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['manage', 'render']),
  typeInput: PropTypes.oneOf(['text', 'number']),
  addInputLabel: PropTypes.string,
  suffixInputNumber: PropTypes.string,
  colInputContainer: PropTypes.number,
  mdInputContainer: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.shape({
    optionLabel: PropTypes.string.isRequired,
    optionValue: PropTypes.string.isRequired,
    value: PropTypes.any
  })),
  validationsValue: PropTypes.object,
  extraValidations: PropTypes.arrayOf(PropTypes.func),
  onChange: PropTypes.func,
  onChangeValidations: PropTypes.func
}

export default DynamicInputsForm
