import { useSession } from '../hooks/auth/useSession.hook'

/**
 * Returns true if the user has any of the roles passed as a parameter
 *
 * @param {*} roles
 * @returns {boolean}
 */
export const useGetIfUserHasRole = (roles) => {
  const { user } = useSession()
  return roles.includes(user?.projectRole ?? null)
}
