/* eslint-disable */
import React, { useEffect } from 'react'
import DashboardControls from '../components/DashboardControls'
import DashboardSummary from './DashboardSummary.controller'
import { calculateDateRangeWithPeriodAndStartDate } from '../../../utils/report.utils'
import { DateTime } from 'luxon'
import DashboardCharts from './DashboardCharts.controller'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'

const DashboardContent = () => {
  const {
    dashboardPeriod: period,
    setDashboardPeriod: setPeriod,
    dashboardDateRange: dateRange,
    setDashboardRange: setDateRange
  } = useReportFiltersContext()

  useEffect(() => {
    const newDates = calculateDateRangeWithPeriodAndStartDate(period, dateRange.startDate.toJSDate(), 'eng')
    setDateRange({
      startDate: newDates.startDate,
      endDate: newDates.endDate
    })
  }, [period])

  return (
    <div>
      <DashboardControls
        period={period}
        setPeriod={setPeriod}
        date={dateRange.startDate}
        setDate={(date) => {
          const newDates = calculateDateRangeWithPeriodAndStartDate(period, date.toJSDate(), 'eng')
          setDateRange({
            startDate: newDates.startDate,
            endDate: newDates.endDate
          })
        }}
      />
      <DashboardSummary
        dateRange={dateRange}
      />
      <DashboardCharts
        dateRange={dateRange}
      />
    </div>
  )
}

export default DashboardContent
