import React, { useEffect, useState } from 'react'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import RegisteredTasks from './RegisteredTasks.controller'
import IndividualReportControls from './IndividualReportControls.controller'
import IndividualReportSummary from './IndividualReportSummary.controller'
import { getLastIndividualTeamMemberReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import { useSession } from '../../../hooks/auth/useSession.hook'
import { DateTime } from 'luxon'
import { useNavigate, useParams } from 'react-router-dom'
import { compareDates, thisWeekRange } from '../../../utils/date.utils'
import { RANGES } from '../../../consts/CompoundRangeDatePicker'
import { AppRoutes } from '../../../consts/appRoutes.consts'
import { areObjectsEqual, isObjectEmpty } from '../../../utils/object.utils'
import { Rol } from '../../../consts/roles.consts'
import { calculateDateRangeWithPeriodAndStartDate } from '../../../utils/report.utils'
import { getTeamMembers } from '../../../services/teamMember.service'
import useEffectAfterMount from '../../../hooks/useEffectAfterMount.hook'

const IndividualReportContent = ({
  currentTab,
  setCurrentTab,
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal,
  registeredTasksRef
}) => {
  const {
    individualTeamMember,
    individualPeriod,
    setIndividualPeriod,
    individualDateRange,
    setIndividualDateRange
  } = useReportFiltersContext()

  const [showControls, setShowControls] = useState(false)
  const { user } = useSession()
  const redirectParams = useParams()
  const [obtainedDataFromParams, setObtainedDataFromParams] = useState(false)
  const navigate = useNavigate()

  useEffectAfterMount(() => {
    updateURLParams()
  }, [individualTeamMember.value, individualDateRange, individualPeriod])

  useEffect(() => {
    getParamsFromURL()
  }, [redirectParams])

  const updateURLParams = () => {
    if (shouldUpdateUrl()) {
      const params = getCurrentParams()
      navigate(getRedirectUrl(params.teamMemberId, params.startDate, params.endDate, params.period))
    }
  }

  const getParamsFromURL = async () => {
    if (obtainedDataFromParams) return
    setObtainedDataFromParams(true)
    const isTeamMember = user.isTeamMember()

    if (!isTeamMember && !redirectParams?.teamMemberId) {
      console.log('admin sin url')
      gettingLastTeamMemberReport()
      return
    }

    if (!redirectParams || isObjectEmpty(redirectParams)) {
      console.log('if params vacio')
      individualTeamMember.resetWith({
        id: user.id
      })
      return
    }

    const currentParams = getCurrentParams()
    const isRedirectIdSameAsUser = user.id === Number(redirectParams.teamMemberId)
    const areParamsValid = await validateParams()
    if (!areParamsValid) {
      individualTeamMember.resetWith({
        id: user.projectRole === Rol.ADMIN_WITHOUT_REPORT ? 3 : user.id
      })
      return
    }

    if (
      !areObjectsEqual(currentParams, redirectParams) &&
      ((isTeamMember && isRedirectIdSameAsUser) || !isTeamMember)
    ) {
      individualTeamMember.resetWith({
        id: Number(redirectParams.teamMemberId)
      })
      setIndividualDateRange([
        DateTime.fromISO(redirectParams.startDate),
        DateTime.fromISO(redirectParams.endDate)
      ])
      setIndividualPeriod(redirectParams.period)
      setObtainedDataFromParams(true)
    } else if (
      !areObjectsEqual(currentParams, redirectParams) &&
      !isRedirectIdSameAsUser && isTeamMember
    ) {
      individualTeamMember.resetWith({
        id: user.id
      })
      setIndividualDateRange([
        DateTime.fromISO(redirectParams.startDate),
        DateTime.fromISO(redirectParams.endDate)
      ])
      setIndividualPeriod(redirectParams.period)
      setObtainedDataFromParams(true)
    }
  }

  const shouldUpdateUrl = () => {
    const currentParams = getCurrentParams()
    return (
      individualTeamMember &&
      individualDateRange &&
      individualPeriod &&
      obtainedDataFromParams &&
      !areObjectsEqual(currentParams, redirectParams)
    )
  }

  const getRedirectUrl = (teamMemberId, startDate, endDate, period) => {
    return `${AppRoutes.reporteIndividual.index}/${teamMemberId}/${startDate}/${endDate}/${period}`
  }

  const getCurrentParams = () => {
    const [startDate, endDate] = individualDateRange
    return {
      teamMemberId: individualTeamMember.value?.id || 3,
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      period: individualPeriod
    }
  }

  const validateParams = async () => {
    const paramTeamMember = Number(redirectParams.teamMemberId)
    const paramStartDate = DateTime.fromISO(redirectParams.startDate)
    const paramEndDate = DateTime.fromISO(redirectParams.endDate)
    const paramPeriod = redirectParams.period
    const isValidStartDate = paramStartDate.isValid
    const isValidEndDate = paramEndDate.isValid
    const isValidPeriod = Object.values(RANGES).includes(paramPeriod)
    const isValidTeamMember = await validateTeamMemberId(paramTeamMember)
    if (isValidStartDate && isValidEndDate && isValidPeriod && isValidTeamMember) {
      const paramPeriod = redirectParams.period || individualPeriod
      const {
        startDate: calculatedStartDate, endDate: calculatedEndDate
      } = calculateDateRangeWithPeriodAndStartDate(paramPeriod, paramStartDate, 'eng', paramEndDate)
      if (!compareDates(paramEndDate, calculatedEndDate)) {
        redirectParams.startDate = calculatedStartDate.toISODate()
        redirectParams.endDate = calculatedEndDate.toISODate()
      }
      return true
    } else {
      return false
    }
  }

  const validateTeamMemberId = async (id) => {
    try {
      const response = await getTeamMembers()
      if (!response.success) {
        throw new Error()
      }
      return response.result.some(tm => tm.id === id)
    } catch (error) {
      return false
    }
  }

  const gettingLastTeamMemberReport = async () => {
    const response = await getLastIndividualTeamMemberReport()
    if (response.success) {
      individualTeamMember.resetWith(response.result)
    }
  }
  return (
    <div id="individual-report">
      <IndividualReportControls
        key={individualTeamMember.value || showControls}
        teamMember={individualTeamMember.value}
        setTeamMember={individualTeamMember.setValue}
        period={individualPeriod}
        setPeriod={setIndividualPeriod}
        dateRange={individualDateRange}
        setDateRange={setIndividualDateRange}
        onReset={
          () => {
            const hasChangedTeamMember = individualTeamMember.defaultValue?.id !== individualTeamMember.value?.id
            if (hasChangedTeamMember) {
              individualTeamMember.revert()
            }
            setIndividualPeriod(RANGES.WEEK)
            setIndividualDateRange(thisWeekRange())
          }
        }
        setRefresh={setShowControls}
      />
      <TabView
        onBeforeTabChange={e => {
          setCurrentTab(e.index)
        }}
      >
        <TabPanel header='Tareas registradas'>
          <RegisteredTasks
            ref={registeredTasksRef}
            teamMember={individualTeamMember.value}
            dateRange={individualDateRange}
            setShowControls={setShowControls}
            period={individualPeriod}
            openCreateTaskForTeamMemberModal={openCreateTaskForTeamMemberModal}
            setOpenCreateTaskForTeamMemberModal={setOpenCreateTaskForTeamMemberModal}
          />
        </TabPanel>
        <TabPanel header='Resumen'>
          <IndividualReportSummary
            teamMember={individualTeamMember.value}
            dateRange={individualDateRange}
          />
        </TabPanel>
      </TabView>
    </div>
  )
}

export default IndividualReportContent
