import React from 'react'
import { InputCheckbox } from '../atoms/InputCheckbox.atom'

/**
 * @typedef {import("primereact/checkbox").CheckboxProps & { label: string }} InputCheckboxProps
 */

/**
 * Componente de checkbox para formularios
 * @param {InputCheckboxProps} props
 * @returns
 */
export function InputCheckboxForm ({ label, checked, onChange, className = '', ...restProps }) {
  return (
    <label className='flex align-items-center'>
      <InputCheckbox
        className={`mr-2 ${className}`.trim()}
        checked={checked}
        onChange={onChange}
        {...restProps}
      />
      {label}
    </label>
  )
}
