import React from 'react'
import PropTypes from 'prop-types'
import { currencyFormatMxn, currencyFormat, numberFormatter } from '../../utils/numbers.utils'
import UnitSuffix from './UnitSuffix.atom'

function ViewOutputForm (props) {
  let value = props.value
  const hasCurrency = props.currency
  if (hasCurrency) {
    if (props.currency === 'MXN') {
      value = currencyFormatMxn(value)
    } else {
      value = currencyFormat(value)
    }
  }
  if (!isNaN(value) && value !== null && value !== ' ' && value !== '') {
    value = numberFormatter(value)
  }
  if (value === null || value === ' ' || value === '') {
    value = '-'
  }
  return (
    <div>{value}{props.suffix ? <UnitSuffix suffix={props.suffix} /> : null}</div>
  )
}

export default ViewOutputForm

ViewOutputForm.propTypes = {
  value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  suffix: PropTypes.string
}
