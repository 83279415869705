/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Chip from '../atoms/misc/Chip.atom'
import ClientProjectChip from '../molecules/ClientProjectChip.molecule'
import DropdownProjectFilter from './DropdownProjectFilter.compound'
const DropdownProjectFilterInplace = ({
  value,
  onChange,
  label = 'Elige un proyecto',
  placeholder = 'Elige un proyecto',
  name = 'projectTagName',
  options,
  optionValue = 'projectID',
  optionLabel = 'projectName',
  error,
  isEditingProjectDropdown,
  setIsEditingProjectDropdown,
  dropdownRef,
  dropdownInputProps,
  ...props
}) => {
  if (!options) return
  const projectName = value ? options.find(option => option?.[optionValue] === value)?.[optionLabel] : ''
  
  return (
    <div className='dropdown-project-filter-inplace'>
      {
        isEditingProjectDropdown
          ? (
            <DropdownProjectFilter
              ref={dropdownRef}
              value={value}
              onChange={onChange}
              name={name}
              options={options}
              optionValue={optionValue}
              optionLabel={optionLabel}
              error={error}
              placeholder={placeholder}
              inputProps={dropdownInputProps}
            />
          )
          : projectName && value
            ? (
              <ClientProjectChip
                clientCode={options.find(option => option[optionValue] === value)?.clientTagName}
                projectCode={options.find(option => option[optionValue] === value)?.projectTagName}
                onClick={() => {
                  setIsEditingProjectDropdown(true)
                  setTimeout(() => {
                    dropdownRef.current?.show()
                  }, 100)
                }}
              />
            )
            : (
              <Chip
                className={`btn btn-primary w-full ${error ? 'error-chip' : ''}`}
                template={
                  <span className='p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem'>{value && projectName ? projectName : label} {error ? <i className='pi pi-exclamation-triangle' data-pr-tooltip="Este es un campo requerido" data-pr-position="bottom" /> : null}</span>
                }
                onClick={() => {
                  setIsEditingProjectDropdown(true)
                  setTimeout(() => {
                    dropdownRef.current?.show()
                  }, 100)
                }}
              />
            )
      }
    </div>
  )
}
export default DropdownProjectFilterInplace
