/* eslint-disable */
import React from 'react'
import SentimentSadCC from '../../../../../design/assests/images/icons/sentiment_sad_cc.svg'
import SentimentStressedCC from '../../../../../design/assests/images/icons/sentiment_stressed_cc.svg'
import { formatHours } from '../../../../../utils/time.utils'
import Tooltip from '../../../../../components/atoms/Tooltip.atom'
import Badge from '../../../../../components/atoms/Badge.atom'

const TimeTemplate = ({ icon, label, time = 0, min, max, showSentiments = true }) => {
  time = time || 0
  let sentimentStatus
  if (time < min) {
    sentimentStatus = {
      icon: SentimentSadCC,
      message: 'Trabajaste menos horas de las esperadas, ¿está todo bien?',
      color: "rgb(243, 208, 121)"
    }
  } else if (time > max) {
    sentimentStatus = {
      icon: SentimentStressedCC,
      message: 'Trabajaste más horas de las esperadas, ¡descansa!',
      color: "#D9342B"
    }
  }
  return (
    <div className='flex h-full flex-column justify-content-between'>
      <div className='flex align-items-center'>
        <i className={`pi ${icon} mr-2`} />
        <span>{label}</span>
      </div>
      <div className='flex gap-2 align-items-center'>
        <span className='text-2xl font-semibold'>{formatHours(time)}</span>
        {
          (sentimentStatus && showSentiments) &&
            <>
              <Badge
                className='p-badge p-component sentiment-badge report-badge min-w-0 flex align-items-center justify-content-center'
                data-pr-tooltip={sentimentStatus?.message} data-pr-position="bottom"
                style={{ background: sentimentStatus?.color, width: 21, height: 21, padding: 0 }}
                  value={<img style={{width: 15, height: 15}} src={sentimentStatus?.icon} alt='status' className='sentiment-icon text-white' />}
              />
              
              <Tooltip target=".sentiment-badge" />
            </>
        }
      </div>
    </div>
  )
}

export default TimeTemplate
