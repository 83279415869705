import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function GoBackIcon () {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousState = location.key !== 'default'
  return (
    <>
      <i className="pi pi-arrow-left mr-3 text-md text-white bg-primary border-circle"
        style={{
          cursor: 'pointer',
          padding: '8.5px'
        }}
        onClick={() => {
          if (hasPreviousState) {
            navigate(-1)
          } else {
            navigate('/', { replace: true })
          }
        }}>
      </i>
    </>
  )
}
