import { AppRoutes } from './appRoutes.consts'
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
// import { COMMON_ICONS } from './filtersMenuCommonsIcons.consts'

export const APP_MENU = [
  {
    name: 'Dashboard',
    icon: DashboardOutlinedIcon,
    path: AppRoutes.dashboard.index
  },
  {
    name: 'Actividades',
    icon: ChecklistRoundedIcon,
    modules: [
      {
        name: 'Registro diario',
        path: AppRoutes.registroTiempos.index
      },
      {
        name: 'Lista de pendientes',
        path: AppRoutes.listaPendientes.index
      }
    ]
  },
  {
    name: 'Reportes',
    iconClass: 'pi pi-chart-line',
    modules: [
      {
        name: 'General',
        path: AppRoutes.reporteGeneral.index
      },
      {
        name: 'Individual',
        path: AppRoutes.reporteIndividual.index
      },
      {
        name: 'Ocupación',
        path: AppRoutes.reporteOcupacion.index
      }
    ]
  },
  {
    name: 'Administración',
    iconClass: 'pi pi-briefcase',
    modules: [
      {
        name: 'Clientes',
        path: AppRoutes.clientes.index
      }
    ]
  },
  {
    name: 'Sandbox',
    iconClass: 'pi pi-code',
    path: AppRoutes.sandbox.index
  },
  {
    name: 'Configuración',
    iconClass: 'pi pi-cog',
    path: AppRoutes.configuracion.index
  }
]
