/* eslint-disable */
import React, { forwardRef } from 'react'
import { Dropdown } from 'primereact/dropdown'
import ClientProjectChip from '../molecules/ClientProjectChip.molecule'
import DropdownCustomFilterSearch from '../molecules/DropdownCustomFilterSearch.molecule'
import { formatDropdownProjects } from '../../utils/project.utils'
import Label from '../atoms/Label.atom'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import { hasAnyErrors } from '../../utils/validations.utils'

const DropdownProjectFilter = forwardRef(({
  value,
  onChange,
  label = null,
  placeholder = 'Elige un proyecto',
  name = 'projectTagName',
  options,
  optionValue = 'projectID',
  optionLabel = 'projectName',
  withoutErrorMessage = true,
  error,
  badgeTemplate = true,
  inputProps,
  ...props
}, ref) => {
  if (!options) return
  options = formatDropdownProjects(options)


  return (
    <div className='field m-0'>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Dropdown
        ref={ref}
        filter
        name={name}
        placeholder={placeholder}
        options={options}
        optionValue={optionValue}
        optionLabel={optionLabel}
        value={value}
        onChange={onChange}
        itemTemplate={(option) => {
          const { clientTagName, projectTagName } = options.find(o => o[optionValue] === option[optionValue])
          return (
            <div className='flex gap-2 align-items-center project-item'>
              <div className='.selected-highlight' style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 0,
                left: 0,
                top: 0,
              }}></div>
              <ClientProjectChip
                clientCode={clientTagName}
                projectCode={projectTagName}
              />
            </div>
          )
        }}
        valueTemplate={(option) => {
          const valueLabel = option?.projectTagName || placeholder
          return (
            badgeTemplate && valueLabel !== placeholder
              ? (
                <div className='flex gap-2 align-items-center overflow-hidden'>
                  <ClientProjectChip
                    clientCode={option?.clientTagName}
                    projectCode={option?.projectTagName}
                    labelClassname='my-1 text-sm'
                  />
                </div>
              )
              : valueLabel
          )
        }}
        emptyMessage={'No hay proyectos'}
        filterTemplate={(props) =>
          <DropdownCustomFilterSearch
            props={props}
            inputProps={inputProps ? inputProps(props) : null}
          />
        }
        filterBy='clientTagName,projectTagName'
      />
      { hasAnyErrors(error) && !withoutErrorMessage ? <ErrorMessageForm errorMessage={error[0]?.errorMessage }/> : null}
    </div>

  )
})

export default DropdownProjectFilter
