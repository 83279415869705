import React, { useEffect, useState } from 'react'
import { logout } from '../../services/auth.service'
import { useNavigate } from 'react-router-dom'
import ProgressSpinner from '../../components/atoms/misc/ProgressSpinner.atom'
export function Logout () {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const handleLogout = async () => {
      setLoading(true)
      await logout()
      setTimeout(() => {
        setLoading(false)
        navigate('/')
        console.log('Adios 👋')
      }, 500)
    }
    handleLogout()
  }, [])

  return (
    <div className='flex align-items-center justify-content-center h-screen w-full'>
      {loading && <ProgressSpinner className='m-auto h-full'/>}
    </div>
  )
}
