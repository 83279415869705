import React from 'react'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import OccupationReport from './OccupationReport.controller'
import OccupationReportCharts from './OccupationReportCharts.controller'

const OccupationReportContent = () => {
  return (
    <div id="occupation-report">
      <TabView>
        <TabPanel header='Reporte comparativo'>
          <OccupationReport />
        </TabPanel>
        <TabPanel header='Gráficas'>
          <OccupationReportCharts />
        </TabPanel>
      </TabView>
    </div>
  )
}

export default OccupationReportContent
