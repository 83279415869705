/* eslint-disable */
import { useEffect, useState } from 'react'

export default function useIsHoldingCtrlDown() {
    const [isHoldingCtrlDown, setIsHoldingCtrlDown] = useState(false)

    function isHoldingCtrl(e) {
        if (e.key === 'Control') {
            setIsHoldingCtrlDown(true)
        }
    };

    function isNotHoldingCtrl(e) {
        if (e.key === 'Control') {
            setIsHoldingCtrlDown(false)
        }
    };

    function browserHasLostFocus() {
        setIsHoldingCtrlDown(false)
    }

    useEffect(() => {
        window.addEventListener('keydown', isHoldingCtrl)
        window.addEventListener('keyup', isNotHoldingCtrl)
        window.addEventListener('blur', browserHasLostFocus)
        window.addEventListener('focus', browserHasLostFocus)
        return () => {
            window.removeEventListener('keydown', isHoldingCtrl)
            window.removeEventListener('keyup', isNotHoldingCtrl)
            window.removeEventListener('blur', browserHasLostFocus)
            window.removeEventListener('focus', browserHasLostFocus)
        }
    }, [])
    return isHoldingCtrlDown
}