import {
  isANumberGreaterThanZero,
  isDateNotGreaterThanToday,
  isNotEmpty
} from '../../utils/validations.utils'

export const CREATE_TASK_BY_ADMIN_DATA_TEMPLATE = {
  date: '',
  name: '',
  comment: '',
  categoryId: '',
  clientCode: '',
  projectId: '',
  moduleId: '',
  sisuTime: '',
  billedTime: '',
  adjustTimeReason: ''
}

export const CREATE_TASK_BY_ADMIN_INITIAL_STATE = {
  date: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty, isDateNotGreaterThanToday],
    validationsOptions: {}
  },
  name: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  comment: {
    value: '',
    errors: [],
    typeValidations: [],
    validationsOptions: {}
  },
  categoryId: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  projectId: {
    value: '',
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {}
  },
  moduleId: {
    value: '',
    errors: [],
    typeValidations: [],
    validationsOptions: {}
  },
  sisuTime: {
    value: '',
    errors: [],
    typeValidations: [isANumberGreaterThanZero],
    validationsOptions: {}
  },
  billedTime: {
    value: '',
    errors: [],
    typeValidations: [],
    validationsOptions: {}
  },
  adjustTimeReason: {
    value: '',
    errors: [],
    typeValidations: [],
    validationsOptions: {}
  }
}
