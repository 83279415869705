import { useContext } from 'react'
import { severities } from '../consts/common.const'
import ToastContext from '../contexts/ToastContext.context'

export default function useToast () {
  const { toast } = useContext(ToastContext)

  const setMessage = (
    severity = severities.error,
    headline = 'Ha ocurrido un error',
    message = '',
    lifeTime = 2500,
    sticky = null,
    content
  ) => {
    toast.current.show({
      severity,
      summary: headline,
      detail: message,
      life: lifeTime,
      sticky,
      content
    })
  }

  function setSuccessMessage ({ title = 'Éxito', message = '', lifeTime = undefined }) {
    setMessage(severities.success, title, message, lifeTime)
  }

  function setErrorMessage ({ title = 'Error', message = '', lifeTime = undefined }) {
    setMessage(severities.error, title, message, lifeTime)
  }

  function setInfoMessage ({ title = 'Información', message = '', lifeTime = undefined }) {
    setMessage(severities.info, title, message, lifeTime)
  }

  function setWarnMessage ({ title = 'Advertencia', message = '', lifeTime = undefined, sticky = null }) {
    setMessage(severities.warnig, title, message, lifeTime, sticky)
  }

  return {
    setMessage,
    setSuccessMessage,
    setErrorMessage,
    setInfoMessage,
    setWarnMessage
  }
}
