/* eslint-disable */
import React from 'react'
import { BREAKPOINTS } from '../../consts/responsiveBreakpoints.consts'
import { classNames } from 'primereact/utils'
import useSidebarContext from '../../hooks/contextConsumers/useSidebarContext.hook'
import useScreenDimensionsContext from '../../hooks/contextConsumers/useScreenDimensionsContext.hook'
// import AppHeader from '../../routers/controllers/AppHeader'

export default function MainLayout ({ children }) {
  const { keyPath, sidebarStatic } = useSidebarContext()
  const { screenWidth, showFiltersMenu, hideMobileMenu } = useScreenDimensionsContext()
  return (
    <div className={classNames('layout-wrapper layout-active layout-mobile-active layout-sidebar', { 'layout-sidebar-static': sidebarStatic })}>
      {/* <AppHeader/> */}
      <div className={`layout-main ${hideMobileMenu === true ? ' layout-content-active' : ''}`}>
        {children}
      </div>
    </div>
  )
}
