import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import SwitchableDatePicker from '../../../components/compounds/SwitchableDatePicker.compound'

const DashboardControls = ({
  period,
  setPeriod,
  date,
  setDate
}) => {
  return (
    <div className="dashboard-filter flex flex-1 align-items-center gap-3 mb-3 flex-column sm:flex-row">
      <SwitchableDatePicker
        period={period}
        setPeriod={setPeriod}
        selectProps={{ style: { minWidth: 125 } }}
        date={date}
        setDate={setDate}
        datePickerProps={{ allowClear: false, style: { width: '20ch' }, inputReadOnly: true }}
      />
    </div>
  )
}

export default DashboardControls

DashboardControls.propTypes = {
  period: PropTypes.oneOf(['day', 'week', 'month', 'year']),
  setPeriod: PropTypes.func,
  date: PropTypes.instanceOf(DateTime),
  setDate: PropTypes.func
}
