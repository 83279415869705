import React from 'react'
import { TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../../consts/modules/graphics.consts'
import BasicTooltipFact from './BasicTooltipFact'

const TooltipSingleDefault = ({
  data,
  tooltipUnit,
  yLabel,
  colorType = TYPE_OF_TOOLTIP_COLOR_VARIANTS.square,
  valueFormatter,
  nameFormatter,
  colorFigure = true
}) => {
  return (
    <div className="tooltip-container">
      {yLabel ? <h6 className='tooltip-label'>{yLabel || ''}</h6> : null}
      <BasicTooltipFact
        className="tooltip-data-container"
        data={data}
        color={data?.color}
        name={data?.name}
        value={data?.value}
        tooltipUnit={tooltipUnit}
        colorType={colorType}
        valueFormatter={valueFormatter || undefined}
        nameFormatter={nameFormatter || undefined}
        colorFigure={colorFigure}
      />
    </div>

  )
}

export default TooltipSingleDefault
