import React from 'react'
// import AppHeader from '../../routers/controllers/AppHeader'

export default function MainContentLayout ({ children }) {
  return (
    <div className="layout-main-content pl-5 pr-4 py-5">
      {/* <AppHeader/> */}
      {children}
    </div>
  )
}
