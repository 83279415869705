import React, { useEffect, useState } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import {
  roundReportData,
  getUserWithMostDays
} from '../../../../../utils/report.utils'
import HeaderGroup from './HeaderGroup.controller'
import FooterGroup from './FooterGroup.controller'
import ColumnContent from './ColumnContent.controller'
import listDetailsIcon from '../../../../../design/assests/images/icons/list_details.svg'
import Modal from '../../../../../components/atoms/Modal.atom'
import ColumnGroup from '../../../../../components/atoms/table/ColumnGroup.atom'
import { Row } from 'primereact/row'
import ClientProjectChip from '../../../../../components/molecules/ClientProjectChip.molecule'

function TimeDifferenceModal ({ modalData, onClose, isOpen }) {
  if (!modalData) return null
  const { timeDifferences, total } = modalData
  const groupedHeaderTimeDifferences = timeDifferences.map((el) => ({ ...el, key: el.client + el.project }))

  return (
    <Modal
      visible={isOpen}
      draggable={false}
      setVisible={onClose}
      onHide={onClose}
      scrollable
      scrollHeight="flex"
      header={
        <div className='flex gap-3'>
          <img style={{ width: 32 }} src={listDetailsIcon} alt='Detalles' />
          <div className='flex flex-column'>
            <div style={{ fontSize: 24 }}>
              Detalles de horas perdonadas
            </div>

            <p style={{ fontSize: 14, fontWeight: 400 }} className='mt-2'>
            Integrante: {modalData?.fullName}
            </p>
          </div>
        </div>
      }
      className='consumo-semanal-report-hours-details-modal w-full'
      contentClassName='flex'
      dismissableMask
    >
      <div className='w-full flex-1 overflow-auto'>
        <DataTable
          scrollable scrollHeight='flex'
          value={groupedHeaderTimeDifferences}
          rowGroupMode="subheader"
          groupRowsBy="key"
          sortField="key"
          sortOrder={1}
          rowGroupHeaderTemplate={(data) => {
            const total = groupedHeaderTimeDifferences.reduce((acc, el) => {
              if (el.key === data.key) {
                return acc + parseFloat(el.time)
              }
              return acc
            }, 0)
            return <div className='flex justify-content-between align-items-center w-full'>
              <ClientProjectChip clientCode={data.client} projectCode={data.project} />
              <div style={{ maxWidth: '135px', width: '100%', paddingLeft: 7 }}>
                <strong>{Number.isInteger(total) ? total : total.toFixed(2)}</strong>
              </div>
            </div>
          }}
        >
          <Column field="comment" footer="Totales" header="Motivo"/>
          <Column style={{ maxWidth: '135px' }} field="time" footer={total} header="Horas"/>
        </DataTable>
      </div>
    </Modal>
  )
}

const WeekHoursReportDataTable = React.forwardRef(({
  reportData: originalData,
  sortOrder,
  setSortOrder,
  resetSortOrderRef
}, ref) => {
  const reportData = roundReportData(originalData)
  const userWithMostDays = getUserWithMostDays(reportData.teamMemberReport)
  const WEEK_SIZE = Object.keys(userWithMostDays?.week)?.length
  const WEEK_DATES = Object.keys(userWithMostDays?.week)

  const [sortField, setSortField] = useState(null)
  const [recalculateFooter, setRecalculateFooter] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [modalData, setModalData] = useState(null)
  const [isTimeDiffDetailsModalOpen, setIsTimeDiffDetailsModalOpen] = useState(false)
  const teamMembersFilterOptions = reportData.teamMemberReport.map((tm) => {
    return {
      label: `${tm.teamMemberTagName} - ${tm.teamMemberName} ${tm.teamMemberLastName}`,
      value: tm.teamMemberTagName
    }
  })

  const sortReportData = (orderType) => {
    const orderToUse = orderType || sortOrder
    const sortedData = reportData.teamMemberReport.sort((a, b) => {
      if (a[sortField] < b[sortField]) return -1 * orderToUse
      if (a[sortField] > b[sortField]) return 1 * orderToUse
      return 0
    })
    reportData.teamMemberReport = sortedData
  }

  useEffect(() => {
    resetSortOrderRef.current = () => {
      setRecalculateFooter(true)
      setSortOrder(1)
      setSortField('teamMemberTagName')
      setFilteredUsers([])
      sortReportData('teamMemberTagName') // Por problema de render, resuelto mejor en SISUAIK-
    }
  }, [])

  return (
    <>
      <TimeDifferenceModal
        modalData={modalData} onClose={() => setIsTimeDiffDetailsModalOpen(false)} isOpen={isTimeDiffDetailsModalOpen} />
      <DataTable
        ref={ref}
        value={reportData.teamMemberReport}
        headerColumnGroup={
          HeaderGroup(
            WEEK_SIZE,
            WEEK_DATES,
            teamMembersFilterOptions,
            sortReportData,
            setSortField,
            setSortOrder,
            sortOrder,
            setFilteredUsers,
            setRecalculateFooter
          )
        }
        footerColumnGroup={
          FooterGroup(
            WEEK_SIZE,
            reportData,
            recalculateFooter,
            filteredUsers
          )
        }
        responsiveLayout='scroll'
        sortMode="multiple"
        sortOrder={sortOrder}
        removableSort
        className='week-hours-report-data-table'
      >
        {
          ColumnContent(
            WEEK_DATES,
            setModalData,
            () => setIsTimeDiffDetailsModalOpen(true)
          )?.map((column, index) => (
            <Column
              key={index}
              {...column}
            />
          ))
        }
      </DataTable>
    </>
  )
})

export default WeekHoursReportDataTable
