import React from 'react'
import AikaLogo from '../../design/assests/images/aika-logo.svg'
import AikaLogoDark from '../../design/assests/images/aika-logo-dark.svg'
import useThemeContext from '../../hooks/contextConsumers/useThemeContext.hook'
import { Link } from 'react-router-dom'
export function View404 () {
  const { theme } = useThemeContext()
  return (
    <div className='exception-page h-full w-full py-6 px-8 flex flex-column justify-content-between font-cairo'>
      <Link to='/'>
        <img
          src={theme === 'dark' ? AikaLogoDark : AikaLogo}
          alt="Aika"
          height={76}
          className="block mx-auto"
        />
      </Link>
      <div className="error-container error-404 flex flex-column md:flex-row">
        <h1 className="error-code">404</h1>
        <div className="divider" />
        <div className="w-fit my-auto">
          <h2 className="error-title mb-2">Esta página tomó vacaciones no autorizadas</h2>
          <p className="text-xl m-0">
            Estamos recordándole sus tareas pendientes.
          </p>
          <p className="text-xl m-0">
            ¡Volverá pronto!
          </p>
        </div>
      </div>
      <div className="footer" />
    </div>
  )
}
