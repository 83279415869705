import React, { useEffect, useState } from 'react'
import DataTable from '../../../components/atoms/table/DataTable.atom'
import ColumnGroup from '../../../components/atoms/table/ColumnGroup.atom'
import Row from '../../../components/atoms/table/RowColumnGroup.atom'
import Column from '../../../components/atoms/table/Column.atom'
import OccupationPercentageChip from '../../../components/molecules/OccupationPercentageChip.molecule'
import { roundReportData } from '../../../utils/report.utils'
import { getMonthLongNameByNumber } from '../../../utils/date.utils'
import { floatToPercentage, formatDecimals } from '../../../utils/numbers.utils'

const OccupationReportOneYearTable = React.forwardRef(({
  reportData: originalData
}, ref) => {
  const reportData = roundReportData(originalData)

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Fecha"
          rowSpan={2}
          colSpan={1}
          field='data.name'
        />
        <Column
          header="Horas totales"
          rowSpan={1}
          colSpan={4}
        />
        <Column
          header="Porcentaje de ocupación"
          rowSpan={2}
          colSpan={1}
        />
      </Row>
      <Row>
        <Column
          header="Trabajadas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Internas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Facturadas"
          colSpan={1}
          rowSpan={1}
        />
        <Column
          header="Perdonadas"
          colSpan={1}
          rowSpan={1}
        />
      </Row>
    </ColumnGroup>
  )

  const footerGroup = (
    <ColumnGroup key={reportData.totals}>
      <Row>
        <Column footer="Totales" colSpan={1} />
        <Column footer={formatDecimals(reportData?.totals?.totalSisuTime)} />
        <Column footer={formatDecimals(reportData?.totals?.totalSisuClientTime)} />
        <Column footer={formatDecimals(reportData?.totals?.totalBilledTime)} />
        <Column footer={formatDecimals(reportData?.totals?.totalUnbilledHours)} />
        <Column footer={<OccupationPercentageChip
          percentageValue={reportData?.totals?.ocupationPercentage}
        />} />
      </Row>
    </ColumnGroup>
  )

  return (
    <>
      <DataTable
        ref={ref}
        value={reportData.data}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup}
        responsiveLayout='scroll'
        className='week-hours-report-data-table'
        scrollable
        style={{ height: '100%' }}
        scrollHeight='100%'
      >
        <Column
          field="data.distributionSisuTime"
          body={(node) => <span>{getMonthLongNameByNumber(node?.month)}</span>}
        />
        <Column
          field="data.totalSisuTime"
          body={(node) => <span>{formatDecimals(node?.totalSisuTime)}</span>}
        />
        <Column
          field="data.totalSisuClientTime"
          body={(node) => <span>{formatDecimals(node?.totalSisuClientTime)}</span>}
        />
        <Column
          field="data.totalBilledTime"
          body={(node) => <span>{formatDecimals(node?.totalBilledTime)}</span>}
        />
        <Column
          field="data.totalUnbilledHours"
          body={(node) => <span>{formatDecimals(node?.totalUnbilledHours)}</span>}
        />
        <Column
          body={(node) => <span>
            <OccupationPercentageChip
              percentageValue={node?.ocupationPercentage}
            />
          </span>}
        />
      </DataTable>
    </>
  )
})

export default OccupationReportOneYearTable
