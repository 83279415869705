/* eslint-disable */
import React, { createContext, useState, useEffect } from 'react'
import { getMondayOfCurrentWeek, getSundayOfCurrentWeek, thisWeekRange } from '../utils/date.utils'
import { getActiveClients, getActiveClientsOfTeamLeader } from '../services/client.service'
import { getActiveProjects, getTeamMemberActiveProjects } from '../services/project.service'
import { useSession } from '../hooks/auth/useSession.hook'
import { Rol } from '../consts/roles.consts'
import { DateTime } from 'luxon'
import useDefaultableState from '../hooks/useDefaultableState'
import { RANGES } from '../consts/CompoundRangeDatePicker'

export const ReportFiltersContext = createContext()
export const ReportFiltersContextProvider = ({ children }) => {
  function todayDateRange() {
    return {
      startDate: DateTime.local().toISODate(),
      endDate: DateTime.local().toISODate()
    }
  }

  const INITIAL_PERIOD = 'week'
  const INITIAL_SELECTED_CLIENT = null

  const auth = useSession()

  const [weekHoursDateRange, setWeekHoursDateRange] = useState(DateTime.local().startOf('week'))

  //***** Rendimiento por proyectos

  const [projectsPerformanceDateRange, setProjectsPerformanceDateRange] = useState([DateTime.local(), DateTime.local()])
  const [projectsPerformancePeriod, setProjectsPerformancePeriod] = useState(RANGES.DAY)
  const [projectsPerformanceExpandedKeys, setProjectsPerformanceExpandedKeys] = useState({})

  //***** Tareas por cliente

  const [tasksByClientClients, setTasksByClientClients] = useState([])
  const [tasksByClientSelectedClient, setTasksByClientSelectedClient] = useState(INITIAL_SELECTED_CLIENT)
  const [tasksByClientPeriod, setTasksByClientPeriod] = useState(RANGES.WEEK)
  const [tasksByClientDateRange, setTasksByClientDateRange] = useState(thisWeekRange())

  const updateClientDropdown = async () => {
    let response
    let clientData = []
    if (auth.user?.projectRole === Rol.TEAM_LEADER) {
      response = await getActiveClientsOfTeamLeader()
    } else {
      response = await getActiveClients()
    }
    if (response.success) {
      response.result.forEach(client => {
        clientData.push(client.data)
      })
      if (clientData.length === 1) {
        setTasksByClientSelectedClient(clientData[0].code)
      }
    }
    setTasksByClientClients(clientData)
  }

  //***************


  const [teamHoursConsumptionProjects, setTeamHoursConsumptionProjects] = useState([])
  const [teamHoursConsumptionSelectedProject, setTeamHoursConsumptionSelectedProject] = useState(INITIAL_SELECTED_CLIENT)
  const [teamHoursConsumptionPeriod, setTeamHoursConsumptionPeriod] = useState(INITIAL_PERIOD)
  const [teamHoursConsumptionDateRange, setTeamHoursConsumptionDateRange] = useState([
    DateTime.local().startOf('week'),
    DateTime.local().endOf('week')
  ])
  const [teamHoursConsumptionExpandedKeys, setTeamHoursConsumptionExpandedKeys] = useState({})

  //***** Consumo por integrante

  const [memberConsumptionTeamMembers, setMemberConsumptionTeamMembers] = useState([])
  const [memberConsumptionSelectedTeamMembers, setMemberConsumptionSelectedTeamMembers] = useState([])
  const [memberConsumptionDateRange, setMemberConsumptionDateRange] = useState([DateTime.local(), DateTime.local()])
  const [memberConsumptionPeriod, setMemberConsumptionPeriod] = useState(RANGES.DAY)

  //***** Individual Report State

  const individualTeamMember = useDefaultableState(null)
  const [individualPeriod, setIndividualPeriod] = useState(RANGES.WEEK)
  const [individualDateRange, setIndividualDateRange] = useState(thisWeekRange())

  //*****

  const [dashboardPeriod, setDashboardPeriod] = useState(INITIAL_PERIOD)
  const [dashboardDateRange, setDashboardRange] = useState({
    startDate: getMondayOfCurrentWeek(),
    endDate: getSundayOfCurrentWeek()
  })

  useEffect(() => {
    updateClientDropdown()
    updateProjectDropdown()
  }, [])
  
  const updateProjectDropdown = async () => {
    let response
    let projectData = []
    if (auth.user?.projectRole === Rol.ADMIN || auth.user?.projectRole === Rol.ADMIN_WITHOUT_REPORT) {
      response = await getActiveProjects()
    } else {
      response = await getTeamMemberActiveProjects(auth.user?.teamMemberID)
    }
    if (response.success) {
      response.result.forEach(project => {
        if (project.projectID !== 1)
          projectData.push(project)
      })
      if (projectData.length === 1) {
        setTeamHoursConsumptionSelectedProject(projectData[0].projectTagName)
      }
    }
    setTeamHoursConsumptionProjects(projectData)
  }

  return (
    <ReportFiltersContext.Provider value={{
      weekHoursDateRange,
      setWeekHoursDateRange,
      //
      projectsPerformanceDateRange,
      setProjectsPerformanceDateRange,
      projectsPerformanceExpandedKeys,
      setProjectsPerformanceExpandedKeys,
      projectsPerformancePeriod,
      setProjectsPerformancePeriod,
      //
      tasksByClientClients,
      setTasksByClientClients,
      tasksByClientSelectedClient,
      setTasksByClientSelectedClient,
      tasksByClientPeriod,
      setTasksByClientPeriod,
      tasksByClientDateRange,
      setTasksByClientDateRange,
      // 
      teamHoursConsumptionProjects,
      setTeamHoursConsumptionProjects,
      teamHoursConsumptionSelectedProject,
      setTeamHoursConsumptionSelectedProject,
      teamHoursConsumptionPeriod,
      setTeamHoursConsumptionPeriod,
      teamHoursConsumptionDateRange,
      setTeamHoursConsumptionDateRange,
      teamHoursConsumptionExpandedKeys,
      setTeamHoursConsumptionExpandedKeys,
      //
      memberConsumptionTeamMembers,
      setMemberConsumptionTeamMembers,
      memberConsumptionSelectedTeamMembers,
      setMemberConsumptionSelectedTeamMembers,
      memberConsumptionDateRange,
      setMemberConsumptionDateRange,
      memberConsumptionPeriod,
      setMemberConsumptionPeriod,
      // 
      individualTeamMember,
      individualPeriod,
      setIndividualPeriod,
      individualDateRange,
      setIndividualDateRange,
      // 
      dashboardPeriod, setDashboardPeriod,
      dashboardDateRange, setDashboardRange
    }}>
      {children}
    </ReportFiltersContext.Provider>
  )
}
