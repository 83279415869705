/* eslint-disable */
import React, { useMemo } from 'react'
import LineChart from '../../../components/compounds/graphics/LineChart.compound'
import ComposedChart from '../../../components/compounds/graphics/ComposedChart.compound'
import { OCCUPATION_HOUR_TYPES, OCCUPATION_HOUR_TYPES_OPTIONS } from '../../../consts/reports.consts'
import CustomTooltipTemplate from './CustomTooltipTemplate'
import { ENG_SHORT_MONTHS_ARRAY } from '../../../consts/date.consts'
import CustomLegendBadge from './CustomLegendBadge'
import EmptyDataMessage from '../../../components/molecules/EmptyDataPieChartMessage.molecule'

const AbsoluteOccupationChart = ({
  data,
  hoursTypeFilter = [],
  emptyMessageTitle = 'Sin información',
  emptyMessageMessage = 'No hay horas registradas durante el periodo seleccionado, intenta cambiando los filtros.'
}) => {
  if (!data.length) {
    return (
      <EmptyDataMessage
        title={emptyMessageTitle}
        message={emptyMessageMessage}
      />
    )
  }
  const formattedData = useMemo(() => {
    return data.map((item) => {
      const formattedItem = {
        date: `${ENG_SHORT_MONTHS_ARRAY[item.month - 1]}/${item.year.toString().slice(-2)}`,
        extraInfo: {
          Trabajadas: {
            Trabajadas: item.totalSisuTime,
            Facturadas: item.totalBilledTime,
            Perdonadas: item.totalUnbilledHours,
            month: item.month,
            year: item.year
          },
          Facturadas: {
            Trabajadas: item.totalSisuTime,
            Facturadas: item.totalBilledTime,
            Perdonadas: item.totalUnbilledHours,
            month: item.month,
            year: item.year
          },
          Perdonadas: {
            Trabajadas: item.totalSisuTime,
            Facturadas: item.totalBilledTime,
            Perdonadas: item.totalUnbilledHours,
            month: item.month,
            year: item.year
          }
        }
      }
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.TRABAJADAS) || !hoursTypeFilter.length) {
        formattedItem['areaData'] = {
          ...formattedItem['areaData'],
          Trabajadas: item.totalSisuTime
        }
      }
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.FACTURADAS) || !hoursTypeFilter.length) {
        formattedItem['areaData'] = {
          ...formattedItem['areaData'],
          Facturadas: item.totalBilledTime
        }
      }
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.PERDONADAS) || !hoursTypeFilter.length) {
        formattedItem['areaData'] = {
          ...formattedItem['areaData'],
          Perdonadas: item.totalUnbilledHours
        }
      }
      return formattedItem
    })
  }, [data, hoursTypeFilter])

  const MAX_Y_VALUE = useMemo(() => {
    return data.map((item) => {
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.TRABAJADAS) || !hoursTypeFilter.length) {
        return item.totalSisuTime
      }
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.FACTURADAS) || !hoursTypeFilter.length) {
        return item.totalBilledTime
      }
      if (hoursTypeFilter.includes(OCCUPATION_HOUR_TYPES.PERDONADAS) || !hoursTypeFilter.length) {
        return item.totalUnbilledHours
      }
    }).reduce((a, b) => Math.max(a, b))
  }, [data, hoursTypeFilter])

  return (
    <>
      <ComposedChart
        key={Math.random()}
        className='custom-compound-chart absolute-ocupation-chart'
        responsiveContainerConfig={{
          minWidth: '200px',
          minHeight: '500px',
          maxHeight: '2000px',
          height: '100%'
        }}
        data={formattedData}
        dataKey='date'
        margin={{ top: 32, bottom: 32 }}
        yAxiesTicksStep={500}
        yAxisDomain={[
          0,
          MAX_Y_VALUE > 500 ? MAX_Y_VALUE + 500 : MAX_Y_VALUE
        ]}
        lineConfig={{ type: 'monotone' }}
        areaConfig={{
          type: 'monotone',
          colors: {
            Trabajadas: OCCUPATION_HOUR_TYPES_OPTIONS[0].color,
            Facturadas: OCCUPATION_HOUR_TYPES_OPTIONS[1].color,
            Perdonadas: OCCUPATION_HOUR_TYPES_OPTIONS[2].color
          },
          dotTemplates: {
            Trabajadas: (rechartsData) => {
              const { key, cx, cy } = rechartsData
              return (
                <circle
                  key={key}
                  cx={cx}
                  cy={cy}
                  r={3}
                  stroke='white'
                  strokeWidth={1}
                  fill={OCCUPATION_HOUR_TYPES_OPTIONS[0].color}
                />
              )
            },
            Facturadas: false,
            Perdonadas: false
          }
        }}
        barConfig={{ stack: true }}
        brushConfig={{
          initial: 0,
          className: 'custom-brush-hide-labels'
        }}
        brush

        tooltip
        tooltipTemplate={(data) => {
          const indexFacturada = data?.findIndex(item => item.name === 'Facturadas')
          if (indexFacturada >= 0) {
            data[indexFacturada]['textColor'] = 'light-dark(white, var(--primary-color-dark))'
          }
          return (
            <CustomTooltipTemplate
              data={[
                {
                  ...data[0],
                  xAxisValue: `${ENG_SHORT_MONTHS_ARRAY[data[0].extraInfo.month - 1]}/${data[0].extraInfo.year.toString()}`
                },
                ...data.slice(1)
              ]}
            />
          )
        }}
        legend
        legendTemplate={(data) => {
          const indexFacturada = data?.findIndex(item => item.name === 'Facturadas')
          if (indexFacturada >= 0) {
            data[indexFacturada]['textColor'] = 'light-dark(white, var(--primary-color-dark))'
          }
          return (
            <div className='custom-compound-legend-recharts w-full flex gap-5 justify-content-center flex-wrap'>
              {
                data?.map((item, index) => (
                  <CustomLegendBadge
                    key={index}
                    item={item}
                  />
                ))
              }
            </div>
          )
        }}

        cartesianGridProps={{
          strokeDasharray: '0'
        }}
      />
    </>
  )
}

export default AbsoluteOccupationChart
