/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import { Button } from 'primereact/button';
import './TreeSelectSandbox.css';
class NodeService {

  getTreeTableNodes() {
      return fetch('data/treetablenodes.json').then(res => res.json())
              .then(d => d.root);
  }

  getTreeNodes() {
      return Promise.resolve(
         [
            {
                "key": "0",
                "label": "Documents",
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-inbox",
                "children": [{
                    "key": "0-0",
                    "label": "Work",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-cog",
                    "children": [{ "key": "0-0-0", "label": "Expenses.doc", "icon": "pi pi-fw pi-file", "data": "Expenses Document" }, { "key": "0-0-1", "label": "Resume.doc", "icon": "pi pi-fw pi-file", "data": "Resume Document" }]
                },
                {
                    "key": "0-1",
                    "label": "Home",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": [{ "key": "0-1-0", "label": "Invoices.txt", "icon": "pi pi-fw pi-file", "data": "Invoices for this month" }]
                }]
            },
            {
                "key": "1",
                "label": "Events",
                "data": "Events Folder",
                "icon": "pi pi-fw pi-calendar",
                "children": [
                    { "key": "1-0", "label": "Meeting", "icon": "pi pi-fw pi-calendar-plus", "data": "Meeting" },
                    { "key": "1-1", "label": "Product Launch", "icon": "pi pi-fw pi-calendar-plus", "data": "Product Launch" },
                    { "key": "1-2", "label": "Report Review", "icon": "pi pi-fw pi-calendar-plus", "data": "Report Review" }]
            },
            {
                "key": "2",
                "label": "Movies",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star-fill",
                "children": [{
                    "key": "2-0",
                    "icon": "pi pi-fw pi-star-fill",
                    "label": "Al Pacino",
                    "data": "Pacino Movies",
                    "children": [{ "key": "2-0-0", "label": "Scarface", "icon": "pi pi-fw pi-video", "data": "Scarface Movie" }, { "key": "2-0-1", "label": "Serpico", "icon": "pi pi-fw pi-video", "data": "Serpico Movie" }]
                },
                {
                    "key": "2-1",
                    "label": "Robert De Niro",
                    "icon": "pi pi-fw pi-star-fill",
                    "data": "De Niro Movies",
                    "children": [{ "key": "2-1-0", "label": "Goodfellas", "icon": "pi pi-fw pi-video", "data": "Goodfellas Movie" }, { "key": "2-1-1", "label": "Untouchables", "icon": "pi pi-fw pi-video", "data": "Untouchables Movie" }]
                }]
            }
        ]
      )
  }
}
 
const TreeSelectDemo = () => {
    const [nodes, setNodes] = useState(null);
    const [selectedNodeKey1, setSelectedNodeKey1] = useState(null);
    const [selectedNodeKey2, setSelectedNodeKey2] = useState(null);
    const [selectedNodeKey3, setSelectedNodeKey3] = useState('0-1');
    const [selectedNodeKeys1, setSelectedNodeKeys1] = useState(null);
    const [selectedNodeKeys2, setSelectedNodeKeys2] = useState(null);
    const [selectedNodeKeys3, setSelectedNodeKeys3] = useState(null);
    const nodeService = new NodeService();
    const [expandedKeys, setExpandedKeys] = useState({});

    const expandAll = () => {
        let _expandedKeys = {};
        for (let node of nodes) {
            expandNode(node, _expandedKeys);
        }

        setExpandedKeys(_expandedKeys);
    };

    const collapseAll = () => {
        setExpandedKeys({});
    };

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    };

    useEffect(() => {
        nodeService.getTreeNodes().then(data => {
          console.log(data)
          setNodes(data)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="treeselect-demo">
        <div className="card">
        <h5>Single</h5>
        <TreeSelect value={selectedNodeKey1} options={nodes} onChange={(e) => setSelectedNodeKey1(e.value)} placeholder="Select Item"></TreeSelect>

        <h5>Multiple</h5>
        <TreeSelect value={selectedNodeKeys1} options={nodes} onChange={(e) => setSelectedNodeKeys1(e.value)} selectionMode="multiple" metaKeySelection={false} placeholder="Select Items"></TreeSelect>

        <h5>Checkbox</h5>
        <TreeSelect value={selectedNodeKeys2} options={nodes} onChange={(e) => setSelectedNodeKeys2(e.value)} display="chip" selectionMode="checkbox" placeholder="Select Items"></TreeSelect>

        <h5>Filter</h5>
        <TreeSelect value={selectedNodeKey2} options={nodes} onChange={(e) => setSelectedNodeKey2(e.value)} filter placeholder="Select Items"></TreeSelect>

        <h5>Initial Value</h5>
        <TreeSelect value={selectedNodeKey3} options={nodes} onChange={(e) => setSelectedNodeKey3(e.value)} placeholder="Select Item"></TreeSelect>

                    <h5>Programmatic Control</h5>
                    <TreeSelect
                        value={selectedNodeKeys3}
                        options={nodes}
                        expandedKeys={expandedKeys}
                        onToggle={(e) => setExpandedKeys(e.value)}
                        onChange={(e) => setSelectedNodeKeys3(e.value)}
                        display="chip"
                        selectionMode="checkbox"
                        placeholder="Select Items"
                          ></TreeSelect>
                       <div className="mb-4 mt-2" >
                        <Button
                            type="button"
                            icon="pi pi-plus"
                            label="Expand All"
                            onClick={expandAll}
                            className="mr-2"
                        />
                        <Button
                            type="button"
                            icon="pi pi-minus"
                            label="Collapse All"
                            onClick={collapseAll}
                        />
                    </div>
                </div>
        </div>
    );
}

export default TreeSelectDemo 