import { useMemo } from 'react'
import { getMinAndMaxValueOfData } from '../../utils/graphics.utils'
import { DOMAIN_DEFAULT } from '../../consts/modules/graphics.consts'
import { generateIntermediateNumbersOfRange } from '../../utils/arrays.utils'

const useYTicksCustom = ({
  data,
  yAxiesTicksStep,
  yAxisDomain
}) => {
  const xAxiesTicks = useMemo(() => {
    if (!yAxiesTicksStep) return undefined
    const theDomainIsDefault = yAxisDomain[0] === DOMAIN_DEFAULT[0] && yAxisDomain[1] === DOMAIN_DEFAULT[1]
    const {
      min,
      max
    } = getMinAndMaxValueOfData(data || [], 'lineData')

    let minToUse = 0
    if (theDomainIsDefault) {
      minToUse = min
    } else {
      minToUse = min < yAxisDomain[0] ? min : yAxisDomain[0]
    }

    let maxToUse = 0
    if (theDomainIsDefault) {
      maxToUse = max
    } else {
      maxToUse = max > yAxisDomain[1] ? max : yAxisDomain[1]
    }
    const result = generateIntermediateNumbersOfRange(minToUse, maxToUse, yAxiesTicksStep)
    return result
  }, [data, yAxisDomain])

  return xAxiesTicks
}

export default useYTicksCustom
