import React from 'react'
import InputText from '../atoms/InputText.atom'
import PropTypes from 'prop-types'
import ErrorMessageForm from '../atoms/ErrorMessageForm.atom'
import { hasAnyErrors } from '../../utils/validations.utils'
import Label from '../atoms/Label.atom'
import ViewOutputForm from '../atoms/ViewOutputForm.atom'

export default function InputTextForm (props) {
  const forId = `input-text-${props.label?.replace(/ /g, '-')}`
  return (
    <>
      <Label htmlFor={forId}>
        {props.label}{props.require ? ' *' : ''}{props.optional ? ' (Opcional)' : ''}
      </Label> {
        props.viewOnly
          ? <ViewOutputForm value={props.value} />
          : <InputText
            id={forId}
            name={props.name}
            keyfilter={props.keyfilter}
            type={props.type || 'text'}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            className={hasAnyErrors(props.error) ? 'p-invalid' : ''}
            disabled={props.disabled}
            maxLength={props.maxLength}
          />
      }
      { hasAnyErrors(props.error) ? <ErrorMessageForm errorMessage={props.error[0]?.errorMessage }/> : null}
    </>
  )
}

InputTextForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  type: PropTypes.oneOfType([PropTypes.oneOf(['text', 'email', undefined])]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  keyfilter: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.array]),
  require: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  optional: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool])
}
