import React from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'

const SettingsHeader = () => {
  return (
    <ContentLayoutHeader
      title='Configuración'
    />
  )
}

export default SettingsHeader
