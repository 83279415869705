import React, { useEffect, useRef, useState } from 'react'
import OrderSwitch from '../../components/formModuleSection/OrderSwitch'
import EditModuleConfirmModal from '../../components/formModuleSection/EditModuleConfirmModal'
import FormSectionTitle from './FormSectionTitle'
import CustomPickList from '../../components/formModuleSection/CustomPickList'

const Picklist = ({
  modules,
  setModules,
  setError
}) => {
  const [activeModules, setActiveModules] = useState([])
  const [newModules, setNewModules] = useState([])
  const [inactiveModules, setInactiveModules] = useState([])
  const [selectedModules, setSelectedModules] = useState([])
  const [visibleModalWarn, setVisibleModalWarn] = useState(false)
  const [editValue, setEditValue] = useState()
  const [orderMode, setOrderMode] = useState(0)
  const containerRef = useRef()

  useEffect(() => {
    setupModules()
  }, [modules])
  useEffect(() => {
    handleSortModules()
  }, [orderMode])

  const setupModules = () => {
    let activeModules = modules?.filter((module) => Object.prototype.hasOwnProperty.call(module, 'active') && module.active)
    let newModules = modules?.filter((module) => !Object.prototype.hasOwnProperty.call(module, 'active'))
    let inactiveModules = modules?.filter((module) => Object.prototype.hasOwnProperty.call(module, 'active') && !module.active)

    const activeModulesLength = activeModules.length
    const newModulesLength = newModules.length

    activeModules = activeModules.map((module, index) => ({ ...module, order: index, selected: 0 }))
    newModules = newModules.map((module, index) => ({ ...module, order: activeModulesLength + index, selected: 0 }))
    // eslint-disable-next-line
    inactiveModules = inactiveModules.map((module, index) => ({ ...module, order: activeModulesLength + newModulesLength + index, selected: 0 })).sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())).map((module, index) => ({ ...module, order: index }))
    setActiveModules(activeModules)
    setNewModules(newModules)
    setInactiveModules(inactiveModules)
  }

  const handleSortModules = () => {
    // if (activeModules.length === 0) return
    // if (inactiveModules.length === 0) return
    const auxInactiveModules = inactiveModules.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())).map((module, index) => ({ ...module, order: index }))
    if (orderMode === 1) {
      setModules([
        ...activeModules.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())).map((module, index) => ({ ...module, order: index })),
        ...auxInactiveModules
      ])
    } else {
      setModules([...activeModules, ...auxInactiveModules].map((module, index) => ({ ...module, order: index })))
    }
  }
  const moveItem = (draggedId, targetId, targetColumn) => {
    const itemToMove = modules.find((item) => item.name === draggedId)
    console.log(draggedId, targetId, targetColumn)
    if (!itemToMove) return
    if (targetColumn === 'Inactivos' && itemToMove.active) {
      itemToMove.active = 0
      const newActiveModules = modules.filter((module) => !module.selected && module.active)
      const newInactiveModules = [
        ...modules.filter((module) => !module.selected && !module.active),
        ...newActiveModules.filter((module) => module.selected)
      ]
      setModules([
        ...newActiveModules.map((module) => ({ ...module, active: 1 })),
        ...newInactiveModules.map((module) => ({ ...module, active: 0 }))
      ])
      setSelectedModules([])
    } else if (targetColumn === 'Activos' && !itemToMove.active) {
      itemToMove.active = 1
      const newInactiveModules = modules.filter((module) => !module.selected && !module.active)
      const newActiveModules = [
        ...modules.filter((module) => !module.selected && module.active),
        ...newInactiveModules.filter((module) => module.selected)
      ]
      setModules([
        ...newActiveModules.map((module) => ({ ...module, active: 1 })),
        ...newInactiveModules.map((module) => ({ ...module, active: 0 }))
      ])
      setSelectedModules([])
    } else {
      if (orderMode === 1) return
      const draggedIndex = activeModules.findIndex((item) => item.name === draggedId)
      const targetIndex = activeModules.findIndex((item) => item.name === targetId)
      if (draggedIndex === -1 || targetIndex === -1) return
      const updatedModules = [...activeModules]
      const [draggedItem] = updatedModules.splice(draggedIndex, 1)
      updatedModules.splice(targetIndex, 0, draggedItem)
      const orderedActiveModules = updatedModules
        .map((module, index) => ({ ...module, order: index }))
        .sort((a, b) => a.order - b.order)
      setActiveModules(orderedActiveModules)
      setModules([...orderedActiveModules, ...inactiveModules])
    }
  }

  const handleCreate = (module) => {
    if (!module?.name || module.name === '') return
    const totalModules = [...activeModules, ...inactiveModules]
    const isDuplicated = totalModules.some((item) => item.name === module.name)
    if (isDuplicated) {
      setError('El nombre del módulo ya existe')
      return
    }
    module = {
      ...module,
      isDeletable: 1,
      active: 1,
      order: activeModules.length
    }
    setModules([
      ...activeModules, module, ...inactiveModules
    ])
  }

  const onDelete = (module) => {
    const isActive = module?.active
    if (isActive) {
      const newActiveModules = activeModules.filter((activeModule) => activeModule.name !== module.name)
      setActiveModules(newActiveModules)
    } else {
      const newInactiveModules = inactiveModules.filter((inactiveModule) => inactiveModule.name !== module.name)
      setInactiveModules(newInactiveModules)
    }
    setModules([...activeModules, ...inactiveModules].filter((item) => item.name !== module.name))
    setSelectedModules([])
  }

  const onEdit = (editValue, callback) => {
    const totalModules = [...activeModules, ...inactiveModules]
    const isDuplicated = totalModules.some((module) => module.name === editValue.name)
    if (isDuplicated) {
      setError('El nombre del módulo ya existe')
      return
    }
    const newActiveModules = activeModules.map((module) => {
      if (module.name === editValue.name) {
        return editValue
      }
      return module
    })
    setActiveModules(newActiveModules)
    const newInactiveModules = inactiveModules.map((module) => {
      if (module.name === editValue.name) {
        return editValue
      }
      return module
    })
    setInactiveModules(newInactiveModules)
    setEditValue(null)
    setModules([...newActiveModules, ...newInactiveModules])
    if (callback) {
      callback()
    }
  }

  return (
    <div className='module-section flex flex-column gap-4 mt-3'>
      <div className="flex justify-content-between">
        <FormSectionTitle title='Módulos' />
        <OrderSwitch
          orderMode={orderMode}
          setOrderMode={setOrderMode}
        />
      </div>
      <CustomPickList
        leftItems={[
          ...activeModules,
          ...newModules
        ]}
        rightItems={inactiveModules}
        selectedItems={selectedModules}
        setSelectedItems={setSelectedModules}
        setItems={setModules}
        moveItem={moveItem}
        handleCreate={handleCreate}
        onEdit={(module) => {
          setVisibleModalWarn(true)
          setEditValue(module)
        }}
        onDelete={onDelete}
        orderMode={orderMode}
        containerRef={containerRef}
        visibleModalWarn={visibleModalWarn}
        setVisibleModalWarn={setVisibleModalWarn}
        setEditValue={setEditValue}
      />
      <EditModuleConfirmModal
        visible={visibleModalWarn}
        setVisible={setVisibleModalWarn}
        onAccept={() => {
          onEdit(editValue)
        }}
      />
    </div>
  )
}

export default Picklist
