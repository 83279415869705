import React from 'react'
import GoBackIcon from '../../atoms/buttons/GoBackIcon.atom'
// import SquaredIcon from '../SquaredIcon.molecule'
import PropTypes from 'prop-types'

export default function MainTitle (props) {
  return (
    <>
      <div className={`${props.className} flex align-items-center`}>
        {props.goBack ? <GoBackIcon /> : null}
        {/* <SquaredIcon contentTemplate={props.icon} /> */}
        <div className='flex flex-column'>
          <h1 className="mb-0" style={{ fontSize: '32px' }}>{props.title}</h1>
          { props.customSubtitle
            ? <>{props.subtitle}</>
            : <h2 className=''>{props.subtitle}</h2>
          }
        </div>
      </div>
    </>
  )
}

MainTitle.propTypes = {
  goBack: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  className: PropTypes.string
}
