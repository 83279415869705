import React, { useRef } from 'react'
import ContentLayoutHeader from '../../../components/layouts/ContentLayoutHeader.layout'
import MyProjects from './myProjects/MyProjects.controller'
import PrimaryButton from '../../../components/molecules/buttons/PrimaryButton.molecule'
import { ReactComponent as QuestionMarkIcon } from '../../../design/assests/images/icons/question_mark.svg'

const TimeRegisterHeader = ({
  setRefreshProjects,
  initTutorial
}) => {
  const myProjectsOverlayRef = useRef(null)

  return (
    <>
      <ContentLayoutHeader
        title='Registro diario'
        buttonProps={{
          label: 'Mis proyectos',
          icon: 'pi pi-briefcase',
          className: 'h-fit my-projects-button',
          onClick: (e) => myProjectsOverlayRef.current.show(e)
        }}
        extraContent={(
          <PrimaryButton
            onClick={() => initTutorial()}
            icon={<QuestionMarkIcon/>}
            className='bg-secondary border-none tutorial-button w-3rem h-3rem text-white'
          />
        )}
      />
      <MyProjects
        overlayRef={myProjectsOverlayRef}
        onAccept={() => setRefreshProjects(prev => !prev)}
      />
    </>
  )
}

export default TimeRegisterHeader
