/* eslint-disable */
import React, { useState } from 'react'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import { createClient, editClient } from '../../../../../services/client.service'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../../../consts/appRoutes.consts'

const FormClientFooter = ({
  client,
  projects,
  isCreate,
  error: formError
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const handleSubmitClient = async () => {
    setLoading(true)
    try {
      const hexColor = getComputedStyle(document.documentElement).getPropertyValue(client?.color.match(/--[a-zA-Z0-9-]+/)?.[0]).trim() || client?.color
      client.color = hexColor
      console.log('Submit edit', client, projects)
      await isCreate ? createClient(client, projects) : editClient(client, projects)
      setTimeout(() => {
        navigate(AppRoutes.clientes.index)
      }, 500)
    } catch (error) {
      // console.log(error)
      setError(error)
    }
    setLoading(false)
  }
  // console.log(formError)
  return (
    <>
      <div className='flex w-full justify-content-end mt-3'>
        <PrimaryButton
          label = {`${isCreate ? 'Crear' : 'Guardar'}`}
          icon = {`pi pi-${loading ? 'spinner pi-spin' : `${isCreate ? 'plus' : 'check'}`}`}
          onClick={handleSubmitClient}
          disabled={!client?.name || !client?.code || !client?.color || !projects?.length || formError}
        />
      </div>
      {error && <div className='text-red-500'>Hubo un error al editar al cliente</div>}
    </>
  )
}

export default FormClientFooter
