import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

const SquareRoundedColorListLegend = ({
  data,
  onMouseEnter,
  onMouseLeave
}) => {
  const uniqueElements = data.filter((item, index, self) =>
    index === self.findIndex((obj) => obj.name === item.name) && item.value !== 0
  )
  return (
    <div
      className='custom-recharts-legend'
    >
      {
        uniqueElements?.map(item => (
          <div
            key={item.name}
            className='flex gap-2 align-items-center'
            onMouseOut={onMouseLeave}
            data-name={item.name}
            onMouseEnter={onMouseEnter}
          >
            <div className='border-round-md w-1rem h-1rem' style={{ background: item.color }}></div>
            {item.extraInfo.label}
          </div>
        ))
      }
    </div>
  )
}

export default SquareRoundedColorListLegend

SquareRoundedColorListLegend.propTypes = {
  data: PropTypes.array,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}
