import React from 'react'

export default function SectionHeading ({ children, className = '', ...props }) {
  return (
    <div
      className={`flex md:align-items-center md:justify-content-between flex-column md:flex-row ${props.secundary ? 'mt-2' : ''} ${className || ''}`}
    >
      {children}
    </div>
  )
}
