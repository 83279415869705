export const ROLE_NAMES = {
  ADMINISTRADOR: 'Administrador',
  AGENTE: 'Agente',
  AGENTE_ADUANAL: 'Agente Aduanal',
  GROWER: 'Grower',
  TRANSPORTISTA: 'Transportista'
}

export const USER_PERMISSIONS = {
  NOT_APPLY: 'No aplica',
  DEFAULT: 'Por defecto',
  UNLIMITED: 'Ilimitado'
}
export const STATUSES = {
  ENABLED: 'Habilitado',
  DISABLED: 'Deshabilitado'
}
export const TYPES_IMAGES_ACCEPTED = ['image/png', 'image/jpeg', 'image/jpg']

export const STATUS_FILTER_OPTIONS = [STATUSES.ENABLED, STATUSES.DISABLED]
