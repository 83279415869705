import React from 'react'
import SectionHeading from '../../layouts/containers/SectionHeading.container'
import MainTitle from '../../molecules/titles/MainTitle.molecule'

export default function SimpleHeaderTitle (props) {
  return (
    <SectionHeading>
      <MainTitle title={props.title} goBack={props.goBack} icon={props.icon} subtitle={props.subtitle} customSubtitle={props.customSubtitle}/>
    </SectionHeading>
  )
}
