import React from 'react'
import { ConfigProvider, Select } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import MyRangePicker from '../atoms/RangePicker.atoms'
import { REPORTS_PERIODS, REPORTS_PERIODS_ENG } from '../../consts/date.consts'
import { getShortMonthName } from '../../utils/date.utils'

const { Option } = Select

/**
 * @type {import('./SwitchableDatePicker.compound')['default']}
 */
const SwitchableRangePicker = (props) => {
  let format

  switch (props.period) {
  case REPORTS_PERIODS_ENG.SEMANAL:
    // eslint-disable-next-line
    format = `wo'/'YY`
    break
  case REPORTS_PERIODS_ENG.MENSUAL:
    format = 'MMMM'
    break
  case REPORTS_PERIODS_ENG.ANUAL:
    format = 'YYYY'
    break
  }

  return (
    <ConfigProvider locale={esES}>
      <Select value={props.period} onChange={props.setPeriod} {...props.selectProps}>
        <Option value='week'>Rango semanal</Option>
        <Option value='month'>Rango mensual</Option>
      </Select>
      <MyRangePicker
        className="range-date-calendar__date-picker"
        picker={props.period}
        value={props.date}
        disabledDate={props.disabledDate}
        onChange={props.setDate}
        format={format}

        {...props.datePickerProps}
      />
    </ConfigProvider>
  )
}
export default SwitchableRangePicker
