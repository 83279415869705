import React from 'react'
import ModalForm from '../../../../../components/compounds/modalForm/ModalForm.compunds'
import useToast from '../../../../../hooks/useToast.hook'
import useTimeRegisterContext from '../../../hooks/useTimeRegisterContext.hook'

const SaveAfterFinished = ({
  visible,
  setVisible,
  onSubmit
}) => {
  const { setSuccessMessage } = useToast()
  const {
    setRefresh
  } = useTimeRegisterContext()
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={
        <div className="flex gap-3 align-items-center">
          <i className="pi pi-check text-4xl" style={{ color: '#2896D8' }}/>
          <span className="text-3xl font-semibold font-cairo">Guardar cambios</span>
        </div>
      }
      saveLabel='Aceptar'
      service={() => {
        onSubmit('')
          .then(() => {
            setSuccessMessage({
              title: 'Cambios guardados',
              message: 'Tus cambios se han guardado correctamente.'
            })
            setRefresh()
          })
        setVisible(false)
      }}
    >
      <span>Cuando editas un día anterior, se avisa al administrador, ¿seguro que quieres guardar tus cambios?</span>
    </ModalForm>
  )
}

export default SaveAfterFinished
